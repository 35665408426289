<template>
  <v-expansion-panel expand-icon="keyboard_arrow_down" collapse-icon="keyboard_arrow_up">
    <template #title>
      <div class="w-100 d-flex align-center ga-3 me-3">
        <v-icon> dashboard </v-icon>
        <span class="text-overline font-weight-bold">Internal Partitions</span>
        <v-spacer />
        <div class="text-caption lh-sm d-flex flex-column ga-0 text-end">
          <span v-if="wallFloorRatio"> Wall to floor ratio: {{ wallFloorRatio }} </span>
          <span v-if="intWallSystem"> {{ intWallSystem }} </span>
        </div>
      </div>
    </template>

    <template #text>
      <v-row class="mt-2">
        <v-col>
          <div class="text-subtitle-2 text-grey-darken-2 d-flex align-center ga-2">
            <v-icon
              v-if="user.isReadonly('blocks.int_wall_to_floor_ratio')"
              icon="lock"
              size="small"
            />
            Wall to floor ratio (m wall/m² floor)
          </div>
          <v-slider
            v-model.number="selectedBuildingBlock.int_wall_to_floor_ratio"
            :min="0.02"
            :max="0.8"
            :step="0.01"
            :disabled="locked || user.isReadonly('blocks.int_wall_to_floor_ratio')"
            color="primary"
            hide-details
          >
            <template #append>
              {{ selectedBuildingBlock.int_wall_to_floor_ratio.toFixed(2) }}
            </template>
          </v-slider>
          <div class="text-subtitle-2 text-grey d-flex align-center justify-space-between mb-8">
            <span>Open plan</span>
            <span>Cellular</span>
          </div>
        </v-col>

        <v-col>
          <v-select
            id="intwall_system"
            v-model="selectedBuildingBlock.intwall_system"
            label="Internal wall system"
            :items="partitionAssemblies"
            :prepend-inner-icon="user.isReadonly('blocks.intwall_system') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('blocks.intwall_system')"
            hide-details
            clearable
          />

          <v-select
            id="intwall_system"
            v-model="selectedBuildingBlock.intdoor_system"
            label="Internal door type"
            :items="doorAssemblies"
            :prepend-inner-icon="user.isReadonly('blocks.intdoor_system') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('blocks.intdoor_system')"
            hide-details
            clearable
          />
        </v-col>
      </v-row>

      <v-row v-if="!locked && selectedBuilding.isMultiblock" class="mb-0">
        <v-col class="text-right pt-0">
          <v-btn text="Apply to all blocks" color="secondary" @click="applyToAll" />
        </v-col>
      </v-row>
    </template>
  </v-expansion-panel>
</template>

<script>
import { useLiveediting } from '@/composables/liveediting'
import { useAssemblyStore } from '@/stores/assembly'
import { storeToRefs } from 'pinia'

export default {
  props: {
    locked: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      required: true
    }
  },
  setup() {
    const { assemblies } = storeToRefs(useAssemblyStore())
    const { filteredAssemblies } = useAssemblyStore()
    const { selectedBuilding, selectedBuildingBlock } = useLiveediting()

    return {
      selectedBuilding,
      selectedBuildingBlock,
      assemblies,
      filteredAssemblies
    }
  },

  computed: {
    wallFloorRatio() {
      return this.selectedBuildingBlock.int_wall_to_floor_ratio
    },

    intWallSystem() {
      return this.selectedBuildingBlock.intwall_system
        ? this.partitionAssemblies.find(
            ({ value }) => value === this.selectedBuildingBlock.intwall_system
          ).title
        : ''
    },

    formattedAssemblies() {
      const assemblies = this.filteredAssemblies(this.selectedBuildingBlock.is_retrofit)
      return assemblies.map(({ shortcode, name, type }) => {
        return {
          value: shortcode,
          title: name,
          type
        }
      })
    },

    partitionAssemblies() {
      const assemblies = this.formattedAssemblies.filter(({ type }) => type === 'INTWALL')
      const options = this.user.getOptions('blocks.intwall_system')
      return options.length ? this.user.limitItems(options, assemblies) : assemblies
    },

    doorAssemblies() {
      const assemblies = this.formattedAssemblies.filter(({ type }) => type === 'INTDOOR')
      const options = this.user.getOptions('blocks.intdoor_system')
      return options.length ? this.user.limitItems(options, assemblies) : assemblies
    }
  },

  methods: {
    applyToAll() {
      let selBlock = this.selectedBuildingBlock

      this.selectedBuilding.blocks.forEach((block) => {
        if (block.id != selBlock.id) {
          block.int_wall_to_floor_ratio = selBlock.int_wall_to_floor_ratio
          block.intwall_system = selBlock.intwall_system
        }
      })
    }
  }
}
</script>
