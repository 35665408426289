<template>
  <div class="my-5 py-5 d-flex justify-center">
    <div
      v-if="loading"
      class="spinner-border my-5 text-secondary"
      style="width: 3rem; height: 3rem"
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>

    <div v-else-if="error" class="text-center">
      <span class="material-symbols-outlined icon text-danger"> sentiment_worried </span>
      <h1 class="h2 mb-3 font-weight-medium text-danger">Invalid signup link</h1>
      <p class="text-secondary">
        Your account activation link is invalid or has already been used. Please contact the support
        team for more information.
      </p>
    </div>

    <div v-else class="text-center">
      <span class="material-symbols-outlined icon text-danger"> error </span>
      <h1 class="h2 mb-3 font-weight-medium text-danger">An error occurred</h1>
      <p class="text-secondary">
        Please follow the link in your email and try again, or return to the
        <router-link to="/login" class="font-weight-medium">login page</router-link>.
      </p>
    </div>
  </div>
</template>

<script>
import { pApiClient } from '@/library/api'
import { router } from '@/router'

export default {
  name: 'ConfirmAccount',

  data() {
    return {
      loading: true,
      error: false
    }
  },

  async created() {
    const token = this.$route.params.token

    if (token) {
      try {
        await pApiClient.get('/confirm/' + token, { suffix: 's/' })
        router.push({ name: 'login', query: { success: true } })
      } catch (error) {
        this.loading = false
        this.error = true
      }
    } else {
      this.loading = false
    }
  }
}
</script>

<style scoped>
.icon {
  font-size: 4rem;
  font-variation-settings: 'wght' 100;
}
</style>
