export default class Material {
  /**
   * @type {number|null}
   */
  id = null
  /**
   * @type {number|null}
   */
  materialid = null
  /**
   * @type {string}
   * @maxLength 100
   */
  name = ''
  /**
   * @type {string|null}
   * @maxLength 100
   */
  shortcode = null
  /**
   * @type {number}
   */
  density = 0

  scenario

  /**
   * @param {object} [data]
   * @param {number} data.id
   * @param {string} data.name
   * @param {string} data.shortcode
   * @param {number} data.density
   */
  constructor(data) {
    if (data) {
      this.id = data.id
      this.materialid = data.id
      this.name = data.name
      this.shortcode = data.shortcode
      this.density = data.density
      this.scenario = data.scenario
    }
  }
}
