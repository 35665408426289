import * as turf from '@turf/turf'
import { FootprintTypes } from '@/library/enums'

export class BldgGeomDetails {
  footprintType
  LngLatVertexes
  footprint_lnglat_coords
  //siteVertexList;

  length = 0
  width = 0
  length_B = 0
  FSR = 0

  GFA = 0

  num_storey = 3

  interstorey_height = 2

  height

  constructor() {}

  static validateCustomFootprint(LonLatVertexes) {
    let poly = turf.polygon([LonLatVertexes])

    if (!poly) return false

    let self_intersections = turf.kinks(poly)
    if (self_intersections.length > 0) {
      return false
    }

    return true
  }

  /**
   *
   * @param {Array} LonLatVertexes
   * @returns {BldgGeomDetails}
   */
  static fromCustomFootprint(LonLatVertexes) {
    let geom = new BldgGeomDetails()
    geom.footprintType = FootprintTypes.CUSTOM
    geom.LngLatVertexes = LonLatVertexes
    geom.height = geom.num_storey * geom.interstorey_height

    return geom
  }
}
