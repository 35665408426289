<template>
  <v-dialog v-model="show" max-width="800">
    <v-card>
      <v-card-title> The uploaded file has the following anomalies </v-card-title>

      <v-card-text>
        <v-list>
          <v-list-item v-for="anomaly in anomalies" :key="anomaly">
            {{ anomaly }}
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text="Cancel" color="secondary" @click="show = false" />
        <v-btn text="Continue" color="primary" @click="emit('continue')" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  anomalies: {
    type: Array,
    required: true
  }
})

const emit = defineEmits(['update:modelValue', 'continue'])

const show = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})
</script>
