<template>
  <div class="d-flex h-100">
    <v-card
      class="mx-auto flex-grow-1 d-flex flex-column overflow-auto min-width-600"
      color="surface-v-5"
      :elevation="4"
      style="z-index: 3"
    >
      <v-card-title class="text-button pb-0 font-weight-bold"> Design parameters </v-card-title>

      <v-card-text v-if="selectedBuilding" class="d-flex flex-column overflow-auto">
        <v-card class="my-4 pa-3 pb-2 flex-0-0" color="surface-v-4">
          <v-sheet
            class="d-flex ga-3 mb-6"
            color="transparent"
            :class="{
              'justify-space-between': locked && !repositioning,
              'justify-start': !locked,
              'justify-end': repositioning
            }"
          >
            <v-btn
              v-if="locked && !repositioning"
              text="Edit"
              size="small"
              color="blue-grey"
              @click="unlockUI"
            />
            <v-btn
              v-if="locked && !repositioning"
              text="Reposition design"
              size="small"
              color="blue-grey"
              @click="reposition"
            />

            <v-btn
              v-if="repositioning"
              text="Cancel"
              size="small"
              variant="outlined"
              color="red-darken-1"
              @click="cancelReposition"
            />
            <v-btn
              v-if="repositioning"
              text="Confirm"
              size="small"
              color="success"
              @click="confirmReposition"
            />

            <v-btn
              v-if="!locked"
              text="LCA"
              size="small"
              color="secondary"
              @click.exact="refreshLCA(true)"
              @click.alt="refreshLCA(false)"
            />
            <v-btn
              v-if="showSaveButton"
              text="Save"
              size="small"
              color="primary"
              @click="saveDesign"
            />

            <v-btn
              v-if="!locked"
              text="Save as New"
              size="small"
              color="primary"
              @click="designLimitCheck"
            />

            <v-btn
              v-if="!locked"
              text="Cancel"
              size="small"
              color="red"
              variant="outlined"
              @click="cancelEdit"
            />
          </v-sheet>

          <v-row>
            <v-col>
              <v-text-field
                v-model="selectedBuilding.label"
                :disabled="locked"
                label="Design name"
                :maxlength="30"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="selectedBuildingBlock.label"
                :disabled="locked"
                label="Block name"
              />
            </v-col>
          </v-row>

          <v-sheet
            v-if="selectedBuilding.blocks.length > 1"
            class="mx-auto"
            max-width="600"
            color="transparent"
          >
            <v-slide-group prev-icon="chevron_left" next-icon="chevron_right">
              <v-slide-group-item v-for="block in selectedBuilding.sortedBlocks" :key="block.id">
                <v-btn
                  class="ma-2 mt-0 ms-0"
                  size="small"
                  :color="selectedBuildingBlockID == block.id ? 'blue-grey' : ''"
                  @click="blockID_changed(block.id)"
                >
                  {{ block.label != '' ? block.label : 'Block ' + block.id }}
                </v-btn>
              </v-slide-group-item>
            </v-slide-group>
          </v-sheet>
        </v-card>

        <v-card
          class="overflow-y-auto flex-1-1 overflow-x-visible pa-1 my-4"
          color="transparent"
          variant="flat"
          style="transform: scale(1.01, 1)"
          min-height="240"
        >
          <v-expansion-panels>
            <RetrofitItem :locked="locked" />
            <CarbonDatasetItem :locked="locked" :error="datasetError" :user="user" />
            <PreviousWorks :locked="locked" :app-configs="appConfigs" :user="user" />
            <GeometryItem :locked="locked" :app-configs="appConfigs" :user="user" />
            <UseTypeItem :locked="locked" :user="user" />
            <SubstructureItem :locked="locked" :app-configs="appConfigs" :user="user" />
            <StructureItem :locked="locked" :app-configs="appConfigs" :user="user" />
            <FacadeItem :locked="locked" :user="user" :has-full-coverage="facadeFullCoverage" />
            <RoofItem :locked="locked" :user="user" />
            <InternalPartitionsItem :locked="locked" :user="user" />
            <FinishesItem :locked="locked" :user="user" />
            <MepItem :locked="locked" :user="user" />
            <OperationalEnergyItem :locked="locked" :app-configs="appConfigs" :user="user" />
            <OtherItem :locked="locked" :user="user" />
          </v-expansion-panels>
        </v-card>

        <BuildingSelector
          v-if="locked"
          ref="selector"
          :auto-select-first="false"
          class="mt-4 flex-shrink-0"
          @new-design="onNewDesign"
        />
      </v-card-text>
    </v-card>

    <div id="map-liveediting" class="map flex-grow-1" />
  </div>

  <v-card
    v-show="stagesData.length"
    id="live-box"
    class="pa-2 d-none"
    :class="!repositioning && showLiveBox ? 'd-md-block' : 'd-md-none'"
    elevation="3"
    color="surface-5"
    width="330"
  >
    <v-card flat color="transparent">
      <v-tabs v-model="tab" density="compact" fixed-tabs color="primary">
        <v-tab value="one">Stages</v-tab>
        <v-tab value="two">Elements</v-tab>
      </v-tabs>

      <v-card-text class="px-0">
        <v-window v-model="tab">
          <v-window-item value="one" eager>
            <div class="d-flex justify-space-around">
              <div id="stagesWrapper" />
              <div class="d-flex flex-column">
                <div
                  v-for="item in stagesLegend"
                  :key="item.name"
                  class="d-flex align-center ga-1 me-2"
                >
                  <div
                    :style="{ backgroundColor: item.color, width: '0.9rem', height: '0.9rem' }"
                    class="rounded"
                  />
                  <span class="text-caption text-surface-variant">{{ item.name }}</span>
                </div>
              </div>
            </div>
          </v-window-item>

          <v-window-item value="two" eager>
            <div class="d-flex justify-center mb-4">
              <div id="structWrapper" />
            </div>
            <div class="d-flex justify-space-between px-2">
              <div class="d-flex flex-column">
                <div
                  v-for="item in structLegend.slice(0, Math.round(structLegend.length / 2))"
                  :key="item.name"
                  class="d-flex align-center ga-1 me-2"
                >
                  <div
                    :style="{ backgroundColor: item.color, width: '0.9rem', height: '0.9rem' }"
                    class="rounded"
                  />
                  <span class="text-caption text-surface-variant">{{ item.name }}</span>
                </div>
              </div>
              <div class="d-flex flex-column">
                <div
                  v-for="item in structLegend.slice(Math.round(structLegend.length / 2))"
                  :key="item.name"
                  class="d-flex align-center ga-1 me-2"
                >
                  <div
                    :style="{ backgroundColor: item.color, width: '0.9rem', height: '0.9rem' }"
                    class="rounded"
                  />
                  <span class="text-caption text-surface-variant">{{ item.name }}</span>
                </div>
              </div>
            </div>
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>

    <template v-if="selectedBuilding">
      <v-table density="compact" class="bg-transparent">
        <tbody>
          <tr>
            <td class="font-weight-bold">GFA</td>
            <td class="text-right">
              {{ floorareaConfig.displayValue(selectedBuilding.GFA) }} {{ floorareaConfig.um }}
            </td>
          </tr>
          <tr>
            <td class="font-weight-bold">Carbon Offset Cost</td>
            <td class="text-right">{{ displayCarbonOffsetCost }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">EC</td>
            <td class="text-right">{{ live.ec_m2 }} kg CO<sub>2e</sub>/m<sup>2</sup></td>
          </tr>
          <tr>
            <td class="font-weight-bold">OC</td>
            <td class="text-right">{{ live.oc_m2 }} kg CO<sub>2e</sub>/m<sup>2</sup></td>
          </tr>
          <tr>
            <td class="font-weight-bold">WLC</td>
            <td class="text-right">{{ live.wlc_m2 }} kg CO<sub>2e</sub>/m<sup>2</sup></td>
          </tr>
        </tbody>
      </v-table>

      <div class="text-right mt-2">
        <MethodologyDialog :is-rics-methodology="isRicsMethodology" />
      </div>
    </template>
  </v-card>

  <CarbonDataErrors
    v-model="showCarbonDataErrors"
    :carbon-data-errors="carbonDataErrors"
    @enter-carbon-data="enterCarbonData"
  />

  <CarbonDataModal
    v-model="showCarbonDataDialog"
    :dataset="selectedBuilding?.lci_dataset"
    :materials="[]"
  />
  <LCAProgress />

  <v-dialog v-model="freemiumDialog" max-width="600px">
    <v-card>
      <v-card-title> You are using a limited version of Preoptima CONCEPT. </v-card-title>

      <v-card-text>
        The carbon assessment provided only represents a “shoe-box” building and not your actual
        building design. Upgrade to a paid license to import your massing model for project-specific
        whole-life carbon assessments and additional design parameters.
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="secondary" text="Upgrade Licence" to="/account" />
        <v-btn color="primary" text="Continue" @click="freemiumContinue" />
      </v-card-actions>
    </v-card>
  </v-dialog>

  <new-design-dialog v-model="showNewDesignDialog" @new-design="init" />

  <v-dialog v-model="freemiumUploadDialog" max-width="600px">
    <v-card>
      <v-card-text>
        Oops! It seems you've reached the limit of {{ licence.design_count_limit }} building designs
        with your Lite license. Upgrade to Preoptima CONCEPT for unlimited building designs and
        exclusive features.
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text="Close" color="secondary" @click="freemiumUploadDialog = false" />
        <v-btn text="Upgrade" color="primary" to="/account" />
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="showSaveAsDialog" max-width="400px">
    <template #default="{ isActive }">
      <v-card>
        <v-card-title>Save new design</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="selectedBuilding.label"
            type="text"
            label="Design name"
            :maxlength="30"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn text="Save" color="primary" @click="saveNewDesign(isActive)" />
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import $ from 'jquery'
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { ConfigsManager } from '@/library/settings'
import { BuildingStatus } from '@/library/enums'
import { UOMConv } from '@/library/utils'
import { useAssemblyStore } from '@/stores/assembly'
import { useMessenger, MESSAGES } from '@/composables/messenger'
import { pApiClient } from '@/library/api'
import { getCarbonDataErrors, getBuildingErrors } from '@/library/displayErrors'
import { useLiveediting } from '@/composables/liveediting'
import { useDataViz } from '@/composables/dataViz'
import { pMapClass } from '@/library/map'
import { pBldg, pMapAdapter } from '@/library/liveediting'
import { router } from '@/router'
import { useLCA } from '@/composables/lca'
import { useMaterialStore } from '@/stores/material'
import { useDatasetStore } from '@/stores/dataset'
import BuildingSelector from '@/components/controls/BuildingSelector.vue'
import LCAProgress from '@/components/LiveEditing/LCAProgress.vue'
import CarbonDatasetItem from '@/components/LiveEditing/CarbonDatasetItem.vue'
import GeometryItem from '@/components/LiveEditing/GeometryItem.vue'
import UseTypeItem from '@/components/LiveEditing/UseTypeItem.vue'
import SubstructureItem from '@/components/LiveEditing/SubstructureItem.vue'
import StructureItem from '@/components/LiveEditing/StructureItem.vue'
import FacadeItem from '@/components/LiveEditing/FacadeItem.vue'
import RoofItem from '@/components/LiveEditing/RoofItem.vue'
import InternalPartitionsItem from '@/components/LiveEditing/InternalPartitionsItem.vue'
import OperationalEnergyItem from '@/components/LiveEditing/OperationalEnergyItem.vue'
import MepItem from '@/components/LiveEditing/MepItem.vue'
import CarbonDataErrors from '@/components/LiveEditing/CarbonDataErrors.vue'
import OtherItem from '@/components/LiveEditing/OtherItem.vue'
import FinishesItem from '@/components/LiveEditing/FinishesItem.vue'
import MethodologyDialog from '@/components/LiveEditing/MethodologyDialog.vue'
import PreviousWorks from '@/components/LiveEditing/PreviousWorks.vue'
import { building as config, getConfig } from '@/library/appConfigs'
import CarbonDataModal from '@/views/materials/CarbonDataModal.vue'
import { useProgress } from '@/composables/progress'
import { useLicenceStore } from '@/stores/licence'
import { useUser } from '@/composables/user'
import DonutChart from '@/library/dataViz/DonutChart'
import { useProjectStore } from '@/stores/project'
import { useBuildingStore } from '@/stores/building'
import NewDesignDialog from '@/components/NewDesignDialog.vue'
import RetrofitItem from '@/components/LiveEditing/RetrofitItem.vue'

let lastGeomLateralParams = null

export default {
  name: 'LiveeditingPane',

  components: {
    BuildingSelector,
    CarbonDatasetItem,
    GeometryItem,
    UseTypeItem,
    SubstructureItem,
    StructureItem,
    FacadeItem,
    RoofItem,
    InternalPartitionsItem,
    OperationalEnergyItem,
    MepItem,
    CarbonDataErrors,
    OtherItem,
    FinishesItem,
    LCAProgress,
    MethodologyDialog,
    PreviousWorks,
    CarbonDataModal,
    NewDesignDialog,
    RetrofitItem
  },

  setup() {
    const { user, getUser } = useUser()
    const { buildings } = storeToRefs(useBuildingStore())
    const { saveBuilding, saveNewBuilding, removeDraftBuildings } = useBuildingStore()
    const { assemblies } = storeToRefs(useAssemblyStore())
    const { addMessage } = useMessenger()
    const { showLiveBox, live, refreshLiveBox, stagesData, structData } = useDataViz()
    const { currentProject } = storeToRefs(useProjectStore())
    const { setActiveTabs, loadProject } = useProjectStore()
    const { setProgress, resetProgress } = useProgress()
    const { fetchLicence, checkDesignLimit } = useLicenceStore()
    const { licence } = storeToRefs(useLicenceStore())
    const {
      isDraft,
      selectedBuilding,
      selectBuilding,
      selectFirst,
      selectDraft,
      selectBuildingById,
      locked,
      selectedBlock,
      selectedBuildingBlock,
      selectedBuildingBlockID,
      getConfig,
      deselectBlockByID,
      selectBlockByID,
      bldgLE,
      init
    } = useLiveediting()

    const currency = ConfigsManager.CURRENCY[currentProject.value.currency].symbol
    const uom_area = UOMConv.UMs.area(currentProject.value.som).label
    if (!selectedBuilding.value) selectFirst()
    const CFG = getConfig()

    useAssemblyStore().fetchAssemblies()
    useMaterialStore().fetchMaterials()
    useDatasetStore().fetchDatasets()
    fetchLicence()

    const carbonDataErrors = ref([])
    const datasetError = ref(false)
    const repositioning = ref(false)
    const tab = ref('one')
    const showCarbonDataErrors = ref(false)
    const showCarbonDataDialog = ref(false)
    const freemiumDialog = ref(false)
    const freemiumUploadDialog = ref(false)
    const freemiumDialogCallback = ref(null)
    const stagesLegend = ref([])
    const structLegend = ref([])
    const showNewDesignDialog = ref(false)
    const showSaveAsDialog = ref(false)

    const { startLCAProgress, resetLCAProgress, QTOComplete, LCAComplete } = useLCA()

    return {
      assemblies,
      addMessage,
      currentProject,
      currency,
      uom_area,
      selectedBuilding,
      isDraft,
      selectBuilding,
      selectFirst,
      selectDraft,
      selectBuildingById,
      selectedBlock,
      selectedBuildingBlock,
      selectedBuildingBlockID,
      locked,
      CFG,
      deselectBlockByID,
      selectBlockByID,
      bldgLE,
      live,
      showLiveBox,
      refreshLiveBox,
      carbonDataErrors,
      datasetError,
      init,
      buildings,
      saveBuilding,
      saveNewBuilding,
      removeDraftBuildings,
      setActiveTabs,
      startLCAProgress,
      resetLCAProgress,
      QTOComplete,
      LCAComplete,
      repositioning,
      tab,
      config,
      showCarbonDataErrors,
      showCarbonDataDialog,
      setProgress,
      resetProgress,
      freemiumDialog,
      freemiumDialogCallback,
      licence,
      user,
      getUser,
      stagesData,
      structData,
      stagesLegend,
      structLegend,
      loadProject,
      showNewDesignDialog,
      freemiumUploadDialog,
      checkDesignLimit,
      showSaveAsDialog
    }
  },

  computed: {
    appConfigs() {
      return getConfig(this.currentProject.som)
    },

    floorareaConfig() {
      return this.appConfigs.building.floorarea
    },

    showSaveButton() {
      return !this.locked && this.selectedBuilding.status != BuildingStatus.DRAFT
    },

    tabsToShow() {
      if (!this.locked) return ['liveediting']
      if (this.locked && this.buildings.length) {
        return ['project', 'liveediting', 'compare', 'data']
      }
      return ['project', 'compare']
    },

    isRicsMethodology() {
      return this.selectedBuilding.lci_dataset === 'RICSv2'
    },

    carbonOffsetCost() {
      const carbonPrice = this.currentProject.carbon_price
      const { EC_A1A3_CO2KG, EC_A4_CO2KG, EC_A5_1_CO2KG, EC_A5_2_CO2KG, EC_A5_3_CO2KG } =
        this.selectedBuilding
      return (
        (carbonPrice / 1000) *
        (EC_A1A3_CO2KG + EC_A4_CO2KG + EC_A5_1_CO2KG + EC_A5_2_CO2KG + EC_A5_3_CO2KG)
      )
    },

    displayCarbonOffsetCost() {
      return (
        this.carbonOffsetCost.toLocaleString('en-GB', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }) +
        ' ' +
        this.currency
      )
    },

    facadeFullCoverage() {
      const blockCoverage = (block) => {
        if (!block.facade_system) return 0
        const assemblies = [...block.facade_system, ...block.glazing_system]
        return assemblies.reduce((sum, { coverage_perc }) => sum + coverage_perc, 0)
      }

      const coverageError = this.selectedBuilding.blocks.find(
        (block) => blockCoverage(block) !== 100
      )
      return coverageError ? false : true
    }
  },

  watch: {
    tabsToShow: {
      handler: function () {
        this.setActiveTabs(this.tabsToShow)
      },
      immediate: true
    },

    'selectedBuildingBlock.facade_system': function () {
      this.selectedBlock.color = ConfigsManager.getWallSettings(
        this.selectedBuildingBlock.facade_system
      ).color
    },

    selectedBuilding() {
      if (!this.bldgLE || !this.selectedBuilding) return
      this.onSelectBuilding()
    }
  },

  async mounted() {
    this.setProgress(10)
    if (!this.currentProject.id && this.$route.params.projectid) {
      await this.loadProject(this.$route.params.projectid)
    }

    if (!this.buildings.length) {
      router.push({ name: 'compare', params: { projectid: this.currentProject.id } })
    }
    this.setLiveEditingEnv()
    this.drawStagesChart()
    this.drawStructChart()
  },

  beforeUnmount() {
    if (!this.locked) this.lockUI()
  },

  methods: {
    onNewDesign() {
      if (!this.licence?.is_freemium) {
        this.showNewDesignDialog = true
        return
      }
      if (this.buildings.length >= this.licence.design_count_limit) {
        this.freemiumUploadDialog = true
        return
      }
      this.showNewDesignDialog = true
    },

    async runLCAonDraft() {
      if (
        this.selectedBuilding.status === BuildingStatus.DRAFT &&
        !this.selectedBuilding.QTOLateralSys
      ) {
        lastGeomLateralParams = this.selectedBuilding.serializeToGeomLateralSys(this.currentProject)
        await this.checkQTOLateral(this.selectedBuilding)
        await this.refreshLCA()
      }
    },

    drawStagesChart() {
      const chart = new DonutChart({ data: this.stagesData, scale: 0.4 })
      chart.drawChart('#stagesWrapper')

      this.stagesLegend = chart.legend
    },

    drawStructChart() {
      const chart = new DonutChart({ data: this.structData, scale: 0.5 })
      chart.drawChart('#structWrapper')
      this.structLegend = chart.legend
    },

    setLiveEditingEnv() {
      this.setProgress(30, MESSAGES.UI_SWITCH2LIVEEDITING)

      let mapCfg = ConfigsManager.getConfigs('MAP')
      const map = pMapClass.getInstance()
      map.setStartCoordsFromArrayCoords(this.currentProject.location_coords)
      map.init('map-liveediting', Object.assign({ HIDE_SEARCHBOX: true }, mapCfg.PROJECT_EDIT))

      this.setProgress(50)
      $(map).one('map.loaded', () => {
        this.bldgLE = new pBldg(pMapAdapter)
        this.setProgress(70)
        map.drawSitePolygonFromCoords(this.currentProject.location_coords)
        this.init()

        if (this.$route.query.buildingid) {
          this.selectBuildingById(this.$route.query.buildingid)
        }

        this.resetProgress()
      })
    },

    blockID_changed(blockId) {
      this.deselectBlockByID(this.selectedBlock.id)
      this.selectBlockByID(Number(blockId))
    },

    drawBuilding(bldg) {
      let bldgLE = this.bldgLE
      bldgLE.deleteBuilding()

      bldg.initBlocks()

      let res = bldgLE.addWholeTree(bldg.root)
      return res
    },

    async onSelectBuilding() {
      this.runLCAonDraft()
      this.$nextTick(() => {
        this.refreshLiveBox(this.selectedBuilding)
        this.drawStagesChart()
        this.drawStructChart()
        this.selectedBlock = this.drawBuilding(this.selectedBuilding)
        this.selectBlockByID(this.selectedBlock.id)
        lastGeomLateralParams = this.selectedBuilding.serializeToGeomLateralSys(this.currentProject)
      })
    },

    checkFacadeCoverage() {
      if (!this.facadeFullCoverage) {
        this.addMessage({
          title: 'Facade Error',
          text: 'One or more blocks does not have 100% coverage',
          color: 'error'
        })
        return false
      }
      return true
    },

    async saveDesign() {
      if (!this.selectedBuilding.lci_dataset) return (this.datasetError = true)
      if (!this.checkFacadeCoverage()) return

      this.setProgress(5, MESSAGES.UI_QTO_LATERAL_RUNNING)
      await this.checkQTOLateral(false)
      this.setProgress(38, MESSAGES.UI_SAVING_LCA_RUNNING)

      try {
        const data = await this.saveBuilding(
          this.selectedBuilding.serializeToLCA(this.currentProject)
        )
        this.lockUI()
        this.selectBuildingById(data.id)
        this.addMessage({
          text: 'Building saved',
          color: 'success'
        })
      } catch (error) {
        if (error.response?.data?.detail?.startsWith('[limit_design_count]')) {
          return this.addMessage({
            title: 'Error saving design',
            text: error.response.data.detail.split('] ')[1],
            color: 'error',
            action: {
              text: 'View your licence',
              to: '/account'
            }
          })
        }
        const message = this.parseValidationErrors(error.response.data)

        const buildingErrors = getBuildingErrors(error.response.data)
        if (buildingErrors.length) return this.displayBuildingErrors(buildingErrors)

        this.addMessage({
          text: MESSAGES.BUILDING_LCA_ERROR + message,
          color: 'warning'
        })
      } finally {
        this.resetProgress()
      }
    },

    async saveNewDesign(isActive) {
      isActive.value = false

      if (!this.checkFacadeCoverage()) return
      if (!this.selectedBuilding.lci_dataset) return (this.datasetError = true)
      this.setProgress(38, MESSAGES.UI_SAVING_LCA_RUNNING, 250)
      try {
        const buildingData = this.selectedBuilding.serializeToLCA(this.currentProject)
        const data = await this.saveNewBuilding(buildingData)
        this.selectBuildingById(data.id)
        this.lockUI()
        this.addMessage({
          text: 'Building saved',
          color: 'success'
        })
      } catch (error) {
        if (error.response?.data?.detail?.startsWith('[limit_design_count]')) {
          return this.addMessage({
            title: 'Error saving design',
            text: error.response.data.detail.split('] ')[1],
            color: 'error',
            action: {
              text: 'View your licence',
              to: '/account'
            }
          })
        }

        const msg_details = this.parseValidationErrors(error)
        this.addMessage({
          text: MESSAGES.BUILDING_SAVE_ERROR + msg_details,
          color: 'error'
        })
      } finally {
        this.resetProgress()
      }
    },

    cancelEdit() {
      if (this.selectedBuilding.status == BuildingStatus.DRAFT) {
        let bldgLE = this.bldgLE
        bldgLE.deleteBuilding()
        this.removeDraftBuildings()

        this.selectFirst()
        if (!this.selectedBuilding) {
          router.push({ name: 'compare', params: { projectid: this.currentProject.id } })
          return
        }
      } else {
        this.selectBuildingById(this.selectedBuilding.id)
        this.onSelectBuilding()
      }
      this.lockUI()
    },

    lockUI() {
      this.locked = true
    },

    unlockUI() {
      this.locked = false
    },

    parseValidationErrors(response) {
      let msg_details = ''
      if (
        response.responseJSON != undefined &&
        response.responseJSON.non_field_errors != undefined
      ) {
        msg_details = ':<br/>' + response.responseJSON.non_field_errors.join('<br/>')
      }

      return msg_details
    },

    async refreshLCA(showSteps = false) {
      if (!this.selectedBuilding.lci_dataset) return (this.datasetError = true)

      this.displayProgress(showSteps)

      this.LCAComplete = false
      this.datasetError = false
      this.carbonDataErrors = []

      await this.checkQTOLateral(!showSteps)

      const isDraft = this.selectedBuilding.status == BuildingStatus.DRAFT

      try {
        const lcaData = await this.runLCA(this.selectedBuilding)
        this.updateBuildingLCA(lcaData, isDraft)

        if (!showSteps) this.refreshLiveBox(this.selectedBuilding)
        this.onSelectBuilding()
      } catch (error) {
        if (showSteps) this.resetLCAProgress()

        if (error.response != undefined && error.response.data != undefined) {
          if (
            error.response.data.detail?.startsWith('[limit_design_sqm]') ||
            error.response.data.detail?.startsWith('[limit_sqm_usage]')
          ) {
            return this.addMessage({
              title: 'Error refreshing LCA figures',
              text: error.response.data.detail.split('] ')[1],
              color: 'error',
              action: {
                text: 'View your licence',
                to: '/account'
              }
            })
          }

          this.carbonDataErrors = getCarbonDataErrors(error.response.data)
          if (this.carbonDataErrors.length) this.showCarbonDataErrors = true

          const buildingErrors = getBuildingErrors(error.response.data)
          if (buildingErrors.length) this.displayBuildingErrors(buildingErrors)
        } else {
          this.addMessage({
            text: MESSAGES.BUILDING_LCA_ERROR,
            color: 'error'
          })
        }
      } finally {
        if (!showSteps) this.resetProgress()
      }
    },

    async checkQTOLateral(updateProgress) {
      this.QTOComplete = false
      if (
        !this.selectedBuilding.compareLateralSysParams(lastGeomLateralParams) ||
        this.selectedBuilding.QTOLateralSys == null
      ) {
        this.selectedBuilding.QTOLateralSys = await this.runQTOLateral(this.selectedBuilding)
      }
      if (updateProgress) this.setProgress(90, MESSAGES.UI_LCA_RUNNING, 300)
      this.QTOComplete = true
    },

    displayProgress(showSteps, callback = () => {}) {
      if (showSteps) {
        this.startLCAProgress(() => {
          this.addMessage({
            text: MESSAGES.BUILDING_LCA_SUCCESS,
            color: 'success'
          })
        })
        callback()
      } else {
        this.setProgress(5, MESSAGES.UI_QTO_LATERAL_RUNNING, 300)
      }
    },

    updateBuildingLCA(data, isDraft) {
      this.selectedBuilding.updateFromLCA(data)
      if (isDraft) this.selectedBuilding.status = BuildingStatus.DRAFT
      this.selectedBuilding.refreshQTOLateral()
      this.selectBlockByID(this.selectedBuildingBlockID)
      lastGeomLateralParams = this.selectedBuilding.serializeToGeomLateralSys(this.currentProject)
      this.LCAComplete = true
      this.refreshLiveBox(this.selectedBuilding)
    },

    displayBuildingErrors(buildingErrors) {
      let formatted = ''
      for (const { block, label, value } of buildingErrors) {
        if (label.includes('blocks'))
          formatted += '<br/>Block ' + block + ': ' + label[1] + ' ' + label[2] + ' ' + value + '\n'
        if (label.includes('non_field_errors')) formatted += '<br/>' + value + '\n'
      }
      this.addMessage({
        title: 'Error refreshing LCA figures',
        text: formatted,
        color: 'error'
      })
    },

    async runLCA(building) {
      const payload = building.serializeToLCA(this.currentProject)
      const { data } = await pApiClient.post('lca', payload)
      return data
    },

    async runQTOLateral(building) {
      try {
        const { data } = await pApiClient.post(
          'qto/lateral',
          building.serializeToGeomLateralSys(this.currentProject)
        )
        this.addMessage({
          text: MESSAGES.BUILDING_QTO_SUCCESS,
          color: 'success'
        })
        return data
      } catch (e) {
        this.addMessage({
          text: MESSAGES.BUILDING_QTO_ERROR,
          color: 'error'
        })
        this.resetProgress()
        this.resetLCAProgress()
        throw e
      }
    },

    reposition() {
      this.repositioning = true
      const coords = this.bldgLE.blocks[0].data.footprint_lnglat_coords
      this.bldgLE.deleteBuilding()
      let map = pMapClass.getInstance()
      map.addRepositionControls(coords)
      map.setDrawPolyFromCoords(coords)
    },

    async confirmReposition() {
      let map = pMapClass.getInstance()
      const { newCoords, rotateAngle } = map._mapDraw

      if (!newCoords && !rotateAngle) {
        this.cancelReposition()
        return
      }

      const { selectedBuildingBlockID } = this
      this.selectedBuilding.transformGeometry(newCoords, rotateAngle)
      await this.saveDesign()
      this.selectBlockByID(selectedBuildingBlockID)

      this.repositioning = false
      this.$nextTick(() => {
        map.removeRepositionControls(newCoords)
      })
    },

    cancelReposition() {
      let map = pMapClass.getInstance()

      this.drawBuilding(this.selectedBuilding)
      this.selectBlockByID(this.selectedBuildingBlockID)

      this.repositioning = false
      this.$nextTick(() => {
        map.removeRepositionControls()
      })
    },

    enterCarbonData() {
      this.showCarbonDataErrors = false
      this.showCarbonDataDialog = true
    },

    checkFreemium(callback) {
      if (this.licence?.is_freemium) {
        this.freemiumDialog = true
        this.freemiumDialogCallback = callback
      } else {
        callback()
      }
    },

    freemiumContinue() {
      this.freemiumDialogCallback()
      this.freemiumDialog = false
    },

    async designLimitCheck() {
      const savedBuildings = this.buildings.filter((b) => b.status !== 'D')
      const designLimitReached = await this.checkDesignLimit(savedBuildings.length)

      if (designLimitReached) {
        return this.addMessage({
          text: `Oops! It seems you've reached the limit of ${this.licence.design_count_limit} building designs with your license.`,
          color: 'error',
          action: {
            text: 'View your license',
            to: '/account'
          }
        })
      }

      this.showSaveAsDialog = true
    }
  }
}
</script>

<style scoped>
.tab-m {
  max-width: 600px;
  min-width: 600px;
}

#live-box {
  position: absolute;
  top: 65px;
  right: 60px;
  min-width: 260px;
  z-index: 100;
}

#blocks-list {
  margin-bottom: 100px;
}
</style>

<style>
.min-width-600 {
  max-width: 600px;
  min-width: 600px;
}

@media (max-width: 600px) {
  .min-width-600 {
    min-width: 100%;
    max-width: 100%;
  }
}

.v-expansion-panel-title--active {
  background-color: #e0f7fa;
}
</style>
