<template>
  <v-sheet class="pa-8" color="surface-v-5 min-h-100">
    <v-card color="surface">
      <v-tabs
        v-model="tab"
        color="primary"
        bg-color="blue-grey-lighten-5"
        center-active
        show-arrows
      >
        <v-tab value="NONDECARB"> NON DECARB </v-tab>
        <v-tab v-if="isRICS" value="DECARB"> DECARB </v-tab>
      </v-tabs>

      <v-card-text v-if="selectedBuilding">
        <div class="d-flex align-center">
          <h1 class="text-body-1 font-weight-bold">Data - {{ selectedBuilding.label }}</h1>
          <v-spacer />
          <v-text-field
            v-model="search"
            label="Search"
            prepend-inner-icon="search"
            single-line
            variant="outlined"
            hide-details
          />
        </div>
        <v-data-table
          v-if="selectedBuilding"
          id="data-pane"
          :headers="headers"
          :items="items"
          :search="search"
          items-per-page="15"
          density="comfortable"
          hover
        />
      </v-card-text>
    </v-card>

    <BuildingSelector class="mt-6" />
  </v-sheet>
</template>

<script>
import { ref } from 'vue'
import BuildingSelector from '../controls/BuildingSelector.vue'
import { roundTo } from '@/library/utils'
import { useLiveediting } from '@/composables/liveediting'
import { pMapClass } from '@/library/map'
import { router } from '@/router'
import { useDatasetStore } from '@/stores/dataset'
import { storeToRefs } from 'pinia'
import { useProjectStore } from '@/stores/project'
import { useBuildingStore } from '@/stores/building'

function stringCompare(a, b) {
  if (a > b) return 1
  if (a < b) return -1
  return 0
}

const headerProps = {
  class: 'font-weight-bold'
}

const headers = [
  { title: 'Element', value: 'building_element', headerProps },
  { title: 'Assembly', value: 'assembly', headerProps },
  { title: 'Material', value: 'material', headerProps },
  { title: 'Qty (Kg)', value: 'mass_qty', headerProps },
  {
    title: 'kgCO2e/m²',
    align: 'center',
    headerProps,
    children: [
      { title: 'A1A3', value: 'co2KG_A1A3', headerProps },
      { title: 'A1A3 biogenic', value: 'co2KG_A1A3_biogenic', headerProps },
      { title: 'A4', value: 'co2KG_A4', headerProps },
      { title: 'A5.1', value: 'co2KG_A5_1', headerProps },
      { title: 'A5.2', value: 'co2KG_A5_2', headerProps },
      { title: 'A5.3', value: 'co2KG_A5_3', headerProps },
      { title: 'B1.1', value: 'co2KG_B1_1', headerProps },
      { title: 'B1.2', value: 'co2KG_B1_2', headerProps },
      { title: 'B2', value: 'co2KG_B2', headerProps },
      { title: 'B3', value: 'co2KG_B3', headerProps },
      { title: 'B4', value: 'co2KG_B4', headerProps },
      { title: 'C1', value: 'co2KG_C1', headerProps },
      { title: 'C2', value: 'co2KG_C2', headerProps },
      { title: 'C3', value: 'co2KG_C3', headerProps },
      { title: 'C4', value: 'co2KG_C4', headerProps },
      { title: 'D1', value: 'co2KG_D1', headerProps }
    ]
  }
]

export default {
  name: 'DataPane',

  components: {
    BuildingSelector
  },

  setup() {
    const { buildings } = storeToRefs(useBuildingStore())
    const { selectedBuilding, selectFirst } = useLiveediting()
    const { currentProject } = storeToRefs(useProjectStore())
    const { setActiveTabs, loadProject } = useProjectStore()
    const materials = ref([])
    const search = ref('')
    const tab = ref('NONDECARB')

    const { fetchDatasets } = useDatasetStore()
    const { datasets } = storeToRefs(useDatasetStore())

    fetchDatasets()

    return {
      selectedBuilding,
      selectFirst,
      materials,
      currentProject,
      setActiveTabs,
      buildings,
      headers,
      search,
      tab,
      datasets,
      loadProject
    }
  },

  computed: {
    isRICS() {
      if (!this.selectedBuilding) return false
      const shortcode = this.selectedBuilding.lci_dataset
      const dataset = this.datasets.find((d) => d.shortcode == shortcode)
      return dataset?.lca_methodology === 'RICSv2'
    },

    items() {
      return this.materials
        .map((mat) => ({
          building_element: mat.building_element,
          assembly: mat.assembly,
          material: this.displayMaterial(mat.material),
          mass_qty: this.displayMassQty(mat.material, mat.mass_qty),
          co2KG_A1A3: this.lessThan01Label(mat.co2KG_A1A3),
          co2KG_A1A3_biogenic: this.lessThan01Label(mat.co2KG_A1A3_biogenic),
          co2KG_A4: this.lessThan01Label(mat.co2KG_A4),
          co2KG_A5_1: this.lessThan01Label(mat.co2KG_A5_1),
          co2KG_A5_2: this.lessThan01Label(mat.co2KG_A5_2),
          co2KG_A5_3: this.lessThan01Label(mat.co2KG_A5_3),
          co2KG_B1_1: this.lessThan01Label(mat.co2KG_B1_1),
          co2KG_B1_2: this.lessThan01Label(mat.co2KG_B1_2),
          co2KG_B2: this.lessThan01Label(mat.co2KG_B2),
          co2KG_B3: this.lessThan01Label(mat.co2KG_B3),
          co2KG_B4: this.lessThan01Label(mat.co2KG_B4),
          co2KG_C1: this.lessThan01Label(mat.co2KG_C1),
          co2KG_C2: this.lessThan01Label(mat.co2KG_C2),
          co2KG_C3: this.lessThan01Label(mat.co2KG_C3),
          co2KG_C4: this.lessThan01Label(mat.co2KG_C4),
          co2KG_D1: this.lessThan01Label(mat.co2KG_D1),
          scenario: mat.scenario
        }))
        .filter((item) => item.scenario === this.tab)
    }
  },

  watch: {
    selectedBuilding() {
      this.selectBuilding()
    }
  },

  async created() {
    if (this.$route.params.projectid) await this.loadProject(this.$route.params.projectid)

    if (!this.buildings.length) {
      router.push({ name: 'compare', params: { projectid: this.currentProject.id } })
    }
    if (!this.selectedBuilding) {
      this.selectFirst()
    }
    this.setActiveTabs(['project', 'compare', 'liveediting', 'data'])
    pMapClass.getInstance().destroy()
    this.selectBuilding()
  },

  methods: {
    displayMaterial(material) {
      if (['COOLINGSYSTEM', 'HEATINGSYSTEM'].includes(material)) {
        return '-'
      }
      return material
    },

    displayMassQty(material, massQty) {
      if (['COOLINGSYSTEM', 'HEATINGSYSTEM'].includes(material)) {
        return '-'
      }
      return Math.floor(massQty).toLocaleString()
    },

    lessThan01Label(x) {
      let val = x / this.selectedBuilding.GIA
      return val < 0.1 ? (val == 0 ? 0 : '<0.1') : roundTo(val, 1)
    },

    selectBuilding() {
      if (!this.selectedBuilding) return

      this.materials = this.selectedBuilding.materials.sort((x, y) => {
        if (x.building_element != y.building_element) {
          return stringCompare(x.building_element, y.building_element)
        } else if (x.assembly != y.assembly) {
          return stringCompare(x.assembly, y.assembly)
        }
        return stringCompare(x.material, y.material)
      })

      if (!this.isRICS) this.tab = 'NONDECARB'
    }
  }
}
</script>

<style>
#data-pane .v-data-table__td {
  text-wrap: nowrap;
}
</style>
