import { ref } from 'vue'

const flags = ref({
  registration: false,
  mixpanelToken: null
})

export function useFlagsmith() {
  function setFlag(key, value) {
    flags.value[key] = value
  }

  function getFlag(key) {
    return flags.value[key]
  }

  return {
    setFlag,
    getFlag,
    flags
  }
}

const environmentIDs = {
  production: '8rWo4bnpFPxphMYLHpoPxe',
  development: 'M5NJwqnf2AKBTLuL2iWP8J',
  qa: 'NNKXVNJ4XY6g22DJyHStnx'
}

export function getEnvironmentID(environment) {
  return environmentIDs[environment]
}
