export class LCABuildingElements {
  Roof = 0
  Wall = 0
  ExtDoor = 0
  IntWall = 0
  IntDoor = 0
  Window = 0
  Skylight = 0
  Floor = 0
  Beam = 0
  Column = 0
  SecondarySystem = 0
  HeatingSystem = 0
  CoolingSystem = 0
  Site = 0
  LateralSystem = 0
  Foundation = 0
  SubStructure = 0
  Structure = 0
  UndergroundStoreys = 0
  FLOORFINISH = 0
  CEILINGFINISH = 0
  FFE = 0
  EXTERNALWORKS = 0
  DEMOWORKS = 0
  STAIR = 0
  ELECTRICAL = 0
  VENTILATION = 0
  PLUMBING = 0
  SYSTEMS = 0
  ONSITE_RENEWABLES = 0
}

export const LCABuildingElementLabels = {
  Beam: 'Beam',
  Column: 'Column',
  Floor: 'Floor',
  Foundation: 'Foundation',
  LateralSystem: 'Lateral System',
  STAIR: 'Stairs',
  UndergroundStoreys: 'Underground Storeys',
  Wall: 'Walls',
  Roof: 'Roof',
  HeatingSystem: 'Heating System',
  Skylight: 'Skylight',
  Window: 'Window',
  IntWall: 'Internal Walls',
  FFE: 'FFE',
  EXTERNALWORKS: 'External Works',
  DEMOWORKS: 'Demo Works',
  ELECTRICAL: 'Electrical',
  PLUMBING: 'Plumbing',
  VENTILATION: 'Ventilation',
  SYSTEMS: 'Systems',
  ONSITE_RENEWABLES: 'Onsite Renewables',
  ExtDoor: 'External Doors',
  IntDoor: 'Internal Doors',
  SecondarySystem: 'Secondary System',
  CoolingSystem: 'Cooling System',
  Site: 'Site',
  SubStructure: 'Sub Structure',
  Structure: 'Structure',
  FLOORFINISH: 'Floor Finish',
  CEILINGFINISH: 'Ceiling Finish'
}
