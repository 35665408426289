import { LCAStagesGroups } from '@/library/enums'

export class LCAStages {
  A1A3 = 0
  A1A3_biogenic = 0
  A4 = 0
  A5_1 = 0
  A5_2 = 0
  A5_3 = 0
  B1_1 = 0
  B1_2 = 0
  B2 = 0
  B3 = 0
  B4 = 0
  C1 = 0
  C2 = 0
  C3 = 0
  C4 = 0
  D1 = 0

  total(stages = LCAStagesGroups.C4) {
    if (isNaN(this.A1A3)) return 0
    let res = 0
    let cStages = [
      this.A1A3,
      this.A1A3_biogenic,
      this.A4,
      this.A5_1,
      this.A5_2,
      this.A5_3,
      this.B1_1,
      this.B1_2,
      this.B2,
      this.B3,
      this.B4,
      this.C1,
      this.C2,
      this.C3,
      this.C4,
      this.D1
    ]
    for (let i = 0; i <= Object.values(LCAStagesGroups).indexOf(stages); i++) {
      res += cStages[i]
    }
    return res
  }

  divide(factor) {
    let res = new LCAStages()
    res.A1A3 = this.A1A3 / factor
    res.A1A3_biogenic = this.A1A3_biogenic / factor
    res.A4 = this.A4 / factor
    res.A5_1 = this.A5_1 / factor
    res.A5_2 = this.A5_2 / factor
    res.A5_3 = this.A5_3 / factor
    res.B1_1 = this.B1_1 / factor
    res.B1_2 = this.B1_2 / factor
    res.B2 = this.B2 / factor
    res.B3 = this.B3 / factor
    res.B4 = this.B4 / factor
    res.C1 = this.C1 / factor
    res.C2 = this.C2 / factor
    res.C3 = this.C3 / factor
    res.C4 = this.C4 / factor
    res.D1 = this.D1 / factor

    return res
  }
}
