<template>
  <div class="d-flex h-100">
    <v-card
      min-width="450"
      color="surface-v-5"
      class="mx-auto overflow-auto min-width-450 d-flex flex-column"
      :elevation="4"
      style="z-index: 3"
    >
      <v-card-title class="d-flex justify-space-between">
        <h2 class="text-button font-weight-bold">Project Details</h2>
        <v-btn-toggle
          v-model="currentProject.som"
          density="compact"
          variant="outlined"
          color="primary"
          class="mt-2 mb-4"
          :disabled="locked || projectHasDesigns"
          @click="setMapSOM"
        >
          <v-btn density="compact" size="small" value="M"> Metric </v-btn>
          <v-btn density="compact" size="small" value="I"> Imperial </v-btn>
        </v-btn-toggle>
      </v-card-title>

      <v-card-text class="flex-grow-1">
        <div>
          <v-text-field
            id="foo"
            v-model="currentProject.name"
            label="Project Name"
            :error-messages="v.currentProject.name.$errors.map((e) => e.$message)"
            :disabled="locked"
          />
        </div>
        <v-text-field
          id="projectLocationAddress"
          v-model="currentProject.location_address"
          label="Location Address"
          :error-messages="v.currentProject.location_address.$errors.map((e) => e.$message)"
          :disabled="locked"
        />
        <div id="projectOtherFields">
          <v-text-field v-model="currentProject.architect" label="Architect" :disabled="locked" />
          <v-text-field v-model="currentProject.client" label="Client" :disabled="locked" />
          <v-select
            v-model="currentProject.currency"
            label="Currency"
            :items="Object.values(currencies)"
            item-title="label"
            item-value="code"
            :disabled="locked"
          />
          <v-text-field
            v-model="currentProject.carbon_price"
            label="Carbon Price"
            :prefix="curLabel"
            type="number"
            hide-spin-buttons
            :disabled="locked"
          />
          <v-text-field
            v-model="currentProject.budget"
            label="Project Budget"
            :prefix="curLabel"
            type="number"
            hide-spin-buttons
            :disabled="locked"
          />
          <v-slider
            v-model="currentProject.windspeed"
            :label="`Basic Wind Velocity [${windspeedConfig.um}]`"
            :thumb-color="locked ? 'grey' : 'primary'"
            color="blue-grey-lighten-4"
            :min="windspeedConfig.min"
            :max="windspeedConfig.max"
            :step="windspeedConfig.step"
            :disabled="locked"
          >
            <template #append>
              <div class="ps-2">
                {{ windspeedConfig.displayValue(currentProject.windspeed) }}
              </div>
            </template>
          </v-slider>
          <v-textarea
            v-model="currentProject.info"
            label="Additional project info"
            rows="2"
            :disabled="locked"
          />
        </div>
        <div class="d-flex flex-row-reverse ga-3">
          <v-btn v-if="locked" color="primary" @click="unlockUI">Edit</v-btn>
          <v-btn v-if="!locked && !isNewProject" color="primary" @click="cancel">Cancel</v-btn>
          <v-btn v-if="!locked" id="projectSave" color="primary" @click="save"> Save </v-btn>
        </div>
      </v-card-text>

      <v-card-actions class="mt-16">
        <v-spacer />
        <v-btn v-if="!polygonVisible" color="warning" @click="takeMeBack"> Lost your way? </v-btn>
        <v-spacer />
      </v-card-actions>

      <v-btn
        v-if="!locked"
        color="info"
        variant="text"
        @click="startCreateProjectTour(true, !currentProject.location_coords?.length)"
        >Need some help?</v-btn
      >
    </v-card>

    <v-btn id="set-test-coordinates" class="d-sr-only" @click="setTestCoordinates">
      Button for testing only
    </v-btn>
    <div id="map" class="map flex-grow-1" />
  </div>
</template>

<script>
import $ from 'jquery'
import { pMapClass } from '@/library/map'
import { pApiClient } from '@/library/api'
import { ConfigsManager } from '@/library/settings'
import { MESSAGES, useMessenger } from '@/composables/messenger'
import { router } from '@/router'
import useVuelidate from '@vuelidate/core'
import { minLength, required } from '@vuelidate/validators'
import { ref } from 'vue'
import { getConfig } from '@/library/appConfigs'
import { useProgress } from '@/composables/progress'
import { useProjectStore } from '@/stores/project'
import { storeToRefs } from 'pinia'
import { useBuildingStore } from '@/stores/building'
import { useOnboarding } from '@/composables/onboarding'

const drawingConfig = {
  enabled: true,
  only1poly: true,
  poly5vertexes: true
}

export default {
  name: 'ProjectPane',

  setup() {
    const { addMessage } = useMessenger()
    const { isNewProject, currentProject, currentSoM } = storeToRefs(useProjectStore())
    const { loadProject, saveProject, resetProject, setActiveTabs } = useProjectStore()
    const { setProgress, resetProgress } = useProgress()
    const { loadBuildings, resetBuildings } = useBuildingStore()
    const { buildings } = storeToRefs(useBuildingStore())
    const { startCreateProjectTour, startSiteBoundaryTour } = useOnboarding()

    const mapPolygonGeoJSON = ref(null)
    const locked = ref(true)
    const polygonVisible = ref(true)
    const geoInfo = ref(null)
    const currencies = ConfigsManager.CURRENCY
    const map = ref(pMapClass.getInstance())

    return {
      currentSoM,
      isNewProject,
      mapPolygonGeoJSON,
      locked,
      polygonVisible,
      geoInfo,
      map,
      currencies,
      currentProject,
      saveProject,
      loadProject,
      resetProject,
      setActiveTabs,
      setProgress,
      resetProgress,
      addMessage,
      loadBuildings,
      resetBuildings,
      buildings,
      v: useVuelidate(),
      startCreateProjectTour,
      startSiteBoundaryTour
    }
  },

  validations() {
    return {
      currentProject: {
        name: { required },
        location_coords: { required, minLegth: minLength(4) },
        location_address: { required }
      }
    }
  },

  computed: {
    curLabel() {
      return this.currencies[this.currentProject.currency].symbol
    },

    windspeedConfig() {
      return getConfig(this.currentProject.som).project.windspeed
    },

    projectHasDesigns() {
      return this.buildings.length > 0
    }
  },

  async mounted() {
    this.setProgress(10)
    this.initEventsHandlers()

    if (this.$route.params.projectid) {
      const { projectid } = this.$route.params
      if (projectid == 'new') {
        this.isNewProject = true
        this.resetBuildings()
        $(this.map).one('map.loaded', this.unlockUI)
      } else {
        await this.loadProject(projectid)
        await this.loadBuildings()
      }

      this.setTabs()
      this.setProjectEnv()
      setTimeout(this.startCreateProjectTour, 1000)
    }
  },

  methods: {
    setMapSOM() {
      this.map.switchSOM(this.currentProject.som)
    },

    setTabs() {
      if (this.projectHasDesigns) {
        this.setActiveTabs(['project', 'compare', 'liveediting', 'data'])
      } else {
        this.setActiveTabs(['project', 'compare'])
      }
    },

    setProjectEnv() {
      this.setProgress(20, MESSAGES.UI_SWITCH2PROJECT)
      if (!this.isNewProject)
        this.map.setStartCoordsFromArrayCoords(this.currentProject.location_coords)

      this.map.init(
        'map',
        this.isNewProject
          ? { DRAWING: drawingConfig }
          : { DRAWING: { ...drawingConfig, enabled: false } }
      )
      this.map.som = this.currentProject.som
      this.setProgress(50)

      $(this.map).one('map.loaded', () => {
        this.setProgress(70)
        if (!this.isNewProject)
          this.map.drawSitePolygonFromCoords(this.currentProject.location_coords)
        this.resetProgress()
      })
    },

    async save() {
      this.v.$touch()
      if (this.v.$error && this.v.currentProject.location_coords.$invalid) {
        this.addMessage({
          text: MESSAGES.UI_VALIDATION_MISSING_PLOT,
          color: 'error',
          action: {
            text: 'Show me how',
            callback: this.startSiteBoundaryTour
          }
        })
        return
      }

      try {
        await this.saveProject()
        this.lockUI()
        router.push({ name: 'project', params: { projectid: this.currentProject.id } })
      } catch (err) {
        const errorDetails = err.response?.data?.detail.split('] ')[1] || null
        this.addMessage({
          text: errorDetails ? errorDetails : 'Error saving project',
          color: 'error',
          action: {
            text: 'View your licence',
            to: '/account'
          }
        })
      }
    },

    cancel() {
      this.resetProject()
      this.setMapSOM()
      this.lockUI()
    },

    lockUI() {
      this.locked = true
      this.map.drawSitePolygonFromCoords(this.currentProject.location_coords)
      this.map.removeDrawControls()
      this.setTabs()
    },

    unlockUI() {
      if (!this.projectHasDesigns) {
        this.map.addDrawControls().removeSitePolygon()
        if (this.currentProject.location_coords)
          this.map.setDrawPolyFromCoords(this.currentProject.location_coords)
      }
      this.locked = false
      this.setActiveTabs(['project'])
    },

    initEventsHandlers() {
      $(this.map).on('map.selectedAreaChanged', async () => {
        let lastCoords = null
        let currCoords = null

        if (this.currentProject.location_coords != null)
          lastCoords = this.currentProject.location_coords[0].join(';')

        this.currentProject.location_coords = this.map.getUserPolygon()
        this.mapPolygonGeoJSON = this.map.getUserPolygon(true)

        if (this.currentProject.location_coords != null)
          currCoords = this.currentProject.location_coords[0].join(';')

        if (currCoords != null && currCoords != lastCoords) {
          const { data } = await pApiClient.get('map/clocate/' + currCoords, { suffix: 's/bldg/' })
          this.currentProject.geo_info = data
          this.geoInfo = data.geo
          if (
            this.currentProject.location_address == null ||
            this.currentProject.location_address.trim() == ''
          ) {
            this.currentProject.location_address = data.address
          }
        }
      })

      $(this.map).on(
        'map.polygonVisibilityChanged',
        (_, isVisible) => (this.polygonVisible = isVisible)
      )
    },

    takeMeBack() {
      pMapClass.getInstance().goBackToPolygon()
    },

    async setTestCoordinates() {
      this.currentProject.location_coords = [
        [-104.98722327005865, 39.73984190149477],
        [-104.98722381463101, 39.737593558049724],
        [-104.98452768176742, 39.73757548591482],
        [-104.984665840811, 39.74023265408698],
        [-104.98722327005865, 39.73984190149477]
      ]
      const coords = this.currentProject.location_coords[0].join(';')
      const { data } = await pApiClient.get(`map/clocate/${coords}/`, { suffix: 's/bldg/' })
      this.currentProject.geo_info = data
      this.geoInfo = data.geo
      if (
        this.currentProject.location_address == null ||
        this.currentProject.location_address.trim() == ''
      ) {
        this.currentProject.location_address = data.address
      }
    }
  }
}
</script>
