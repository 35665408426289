<template>
  <v-overlay v-model="show" class="d-flex flex-column align-center justify-center" persistent>
    <div class="mb-2 text-body-1 font-weight-bold text-white text-center" v-html="message"></div>
    <v-progress-linear
      :model-value="progress"
      color="indigo-accent-2"
      bg-color="white"
      bg-opacity="0.5"
      height="20"
      style="width: 400px"
      class="shadow"
      striped
      rounded="3"
    >
      <template #default="{ value }">
        <strong>{{ Math.ceil(value) }}%</strong>
      </template>
    </v-progress-linear>
  </v-overlay>
</template>

<script setup>
import { useProgress } from '@/composables/progress'

const { show, progress, message } = useProgress()
</script>
