import { UOMConv } from '@/library/utils'
import * as turf from '@turf/turf'

export class Project {
  id = null
  name = ''
  architect = ''
  client = ''
  info = ''
  location_address = ''
  location_coords
  geo_info
  som = 'M'
  currency = 'GBP'
  carbon_price = 53.65
  budget = 0
  cost_of_land = 0
  construction_cost = 0
  selling_price = 0
  windspeed = 22
  status = ''
  building_type = 'OFFICE'

  constructor(data = {}) {
    Object.assign(this, data)
  }

  get site_area_usrUoM() {
    const poly = turf.polygon([this.location_coords])
    const area = turf.area(poly)

    return UOMConv.convAreaFromBase(area, this.som)
  }
}
