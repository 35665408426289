<template>
  <v-expansion-panel expand-icon="keyboard_arrow_down" collapse-icon="keyboard_arrow_up">
    <template #title>
      <div class="w-100 d-flex align-center ga-3 me-3">
        <v-icon> more_horiz </v-icon>
        <span class="text-overline font-weight-bold"> Other </span>
        <v-spacer />
        <div class="text-caption lh-sm d-flex flex-column ga-0 text-end"></div>
      </div>
    </template>

    <template #text>
      <v-row class="mt-2">
        <v-col>
          <div class="text-subtitle-2 text-grey-darken-2 d-flex align-center ga-2">
            <v-icon v-if="user.isReadonly('ffe_allowance_perc')" icon="lock" size="small" />
            FFE (Furnishings, Fixtures, and Equipment)
          </div>
          <v-slider
            v-model.number="selectedBuilding.ffe_allowance_perc"
            :min="0"
            :max="10"
            :step="1"
            :disabled="locked || user.isReadonly('ffe_allowance_perc')"
            color="primary"
          >
            <template #append>
              {{ selectedBuilding.ffe_allowance_perc }}
            </template>
          </v-slider>
        </v-col>
        <v-col>
          <br />
          <div class="text-subtitle-2 text-grey-darken-2 d-flex align-center ga-2">
            <v-icon
              v-if="user.isReadonly('external_works_allowance_perc')"
              icon="lock"
              size="small"
            />
            External Works
          </div>
          <v-slider
            v-model.number="selectedBuilding.external_works_allowance_perc"
            :min="0"
            :max="10"
            :step="1"
            :disabled="locked || user.isReadonly('external_works_allowance_perc')"
            color="primary"
          >
            <template #append>
              {{ selectedBuilding.external_works_allowance_perc }}
            </template>
          </v-slider>
        </v-col>
      </v-row>
      <p class="text-center text-caption">Allowance as percentage of A1-A3 (%)</p>

      <h3 class="text-body-2 text-grey-darken-1">
        Onsite Renewables Energy Generation Installations (kgCO2e/m2)
      </h3>
      <v-row class="mt-1">
        <v-col>
          <v-text-field
            v-model.number="selectedBuilding.onsite_A1A3_kgCO2m2"
            label="A1A3"
            type="number"
            :prepend-inner-icon="user.isReadonly('onsite_A1A3_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('onsite_A1A3_kgCO2m2')"
          />
          <v-text-field
            v-model.number="selectedBuilding.onsite_C3_kgCO2m2"
            label="C3"
            type="number"
            :prepend-inner-icon="user.isReadonly('onsite_C3_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('onsite_C3_kgCO2m2')"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="selectedBuilding.onsite_A4_kgCO2m2"
            label="A4"
            type="number"
            :prepend-inner-icon="user.isReadonly('onsite_A4_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('onsite_A4_kgCO2m2')"
          />
          <v-text-field
            v-model.number="selectedBuilding.onsite_C4_kgCO2m2"
            label="C4"
            type="number"
            :prepend-inner-icon="user.isReadonly('onsite_C4_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('onsite_C4_kgCO2m2')"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="selectedBuilding.onsite_A5_3_kgCO2m2"
            label="A5.3"
            type="number"
            :prepend-inner-icon="user.isReadonly('onsite_A5_3_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('onsite_A5_3_kgCO2m2')"
          />
          <v-text-field
            v-model.number="selectedBuilding.onsite_D1_kgCO2m2"
            label="D1"
            type="number"
            :prepend-inner-icon="user.isReadonly('onsite_D1_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('onsite_D1_kgCO2m2')"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="selectedBuilding.onsite_C2_kgCO2m2"
            label="C2"
            type="number"
            :prepend-inner-icon="user.isReadonly('onsite_C2_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('onsite_C2_kgCO2m2')"
          />
        </v-col>
      </v-row>

      <h3 class="text-body-2 text-grey-darken-1">Special Systems (kgCO2e/m2)</h3>
      <v-row class="mt-1">
        <v-col>
          <v-text-field
            v-model.number="selectedBuilding.systems_A1A3_kgCO2m2"
            label="A1A3"
            type="number"
            :prepend-inner-icon="user.isReadonly('systems_A1A3_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('systems_A1A3_kgCO2m2')"
          />
          <v-text-field
            v-model.number="selectedBuilding.systems_C3_kgCO2m2"
            label="C3"
            type="number"
            :prepend-inner-icon="user.isReadonly('systems_C3_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('systems_C3_kgCO2m2')"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="selectedBuilding.systems_A4_kgCO2m2"
            label="A4"
            type="number"
            :prepend-inner-icon="user.isReadonly('systems_A4_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('systems_A4_kgCO2m2')"
          />
          <v-text-field
            v-model.number="selectedBuilding.systems_C4_kgCO2m2"
            label="C4"
            type="number"
            :prepend-inner-icon="user.isReadonly('systems_C4_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('systems_C4_kgCO2m2')"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="selectedBuilding.systems_A5_3_kgCO2m2"
            label="A5.3"
            type="number"
            :prepend-inner-icon="user.isReadonly('systems_A5_3_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('systems_A5_3_kgCO2m2')"
          />
          <v-text-field
            v-model.number="selectedBuilding.systems_D1_kgCO2m2"
            label="D1"
            type="number"
            :prepend-inner-icon="user.isReadonly('systems_D1_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('systems_D1_kgCO2m2')"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="selectedBuilding.systems_C2_kgCO2m2"
            label="C2"
            type="number"
            :prepend-inner-icon="user.isReadonly('systems_C2_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('systems_C2_kgCO2m2')"
          />
        </v-col>
      </v-row>
    </template>
  </v-expansion-panel>
</template>

<script>
import { useLiveediting } from '@/composables/liveediting'

export default {
  props: {
    locked: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      required: true
    }
  },
  setup() {
    const { selectedBuilding } = useLiveediting()

    return {
      selectedBuilding
    }
  }
}
</script>
