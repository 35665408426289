<template>
  <v-dialog v-model="show" scrollable>
    <v-card flat>
      <v-card-title class="d-flex ga-3 mt-2 flex-column">
        <div class="d-flex align-center justify-space-between mb-2">
          <h2 class="text-h5 font-weight-bold text-surface-variant">Manage Materials</h2>

          <v-tooltip>
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                icon="close"
                density="comfortable"
                color="error"
                variant="tonal"
                @click="show = false"
              />
            </template>
            <span>Close</span>
          </v-tooltip>
        </div>

        <div class="d-flex ga-4 w-100">
          <v-spacer />
          <v-text-field
            v-model="searchTerm"
            prepend-inner-icon="search"
            density="compact"
            label="Find materials"
            single-line
            flat
            hide-details
            variant="solo-filled"
            type="search"
            clearable
          />
          <v-btn
            color="primary"
            :prepend-icon="user.canCreate('material') ? 'add' : 'lock'"
            :disabled="!user.canCreate('material')"
            @click="addMaterial"
          >
            Add Material
          </v-btn>
        </div>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :items="materials"
          :headers="materialsHeaders"
          :search="searchTerm"
          :items-per-page="15"
          no-data-text="No materials found"
          hover
          sort-asc-icon="arrow_drop_up"
          sort-desc-icon="arrow_drop_down"
          :loading="loading && 'secondary'"
          loading-text="Loading materials..."
        >
          <template v-if="showActions" #[`item.actions`]="{ item }">
            <v-container class="ma-0 pa-0 d-flex flex-no-wrap">
              <v-btn
                v-if="user.canUpdate('material')"
                icon="edit"
                size="small"
                density="comfortable"
                color="primary"
                variant="text"
                @click="updateMaterial(item)"
              />

              <v-dialog v-if="user.canDelete('material')" width="500">
                <template #activator="{ props }">
                  <v-btn
                    v-bind="props"
                    icon="delete"
                    size="small"
                    density="comfortable"
                    color="error"
                    variant="text"
                  />
                </template>

                <template #default="{ isActive }">
                  <v-card>
                    <v-card-text color="grey-darken-3">
                      Are you sure you want to delete this material?
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer />
                      <v-btn text="Cancel" color="secondary" @click="isActive.value = false" />
                      <v-btn
                        text="Delete"
                        color="error"
                        @click="onDeleteMaterial(item.id, () => (isActive.value = false))"
                      />
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </v-container>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showNewMaterialDialog" max-width="650" retain-focus>
      <v-card>
        <v-card-title>
          {{ currentMaterial.id ? 'Update material' : 'Add new material' }}
        </v-card-title>

        <v-card-text>
          <v-form>
            <v-text-field
              v-model="currentMaterial.name"
              label="Name"
              :error-messages="v.currentMaterial.name.$errors.map((e) => e.$message)"
            />
            <v-text-field
              v-model="currentMaterial.shortcode"
              label="Shortcode"
              :error-messages="
                v.currentMaterial.shortcode.$error ? ['Shortcode must be unique'] : []
              "
            />
            <v-text-field
              v-model="currentMaterial.density"
              label="Density"
              type="number"
              suffix="kg/m³"
              :error-messages="v.currentMaterial.name.$errors.map((e) => e.$message)"
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn text="Close" color="secondary" @click="showNewMaterialDialog = false" />
          <v-btn text="Save" color="primary" @click="handleSaveMaterial" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { useMaterialsManager } from '@/composables/materialsManager'
import { useMaterialStore } from '@/stores/material'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { isRequired, isNumeric } from '@/library/validators'
import { useUser } from '@/composables/user'

const headerProps = {
  class: 'font-weight-bold'
}
const materialsHeaders = [
  { key: 'actions', headerProps, sortable: false },
  { title: 'Name', key: 'name', headerProps },
  { title: 'Shortcode', key: 'shortcode', headerProps },
  { title: 'Density (kg/m³)', key: 'density', headerProps }
]

export default {
  name: 'MaterialsList',

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  emits: ['update:modelValue'],

  setup() {
    const { setCurrentMaterial, resetCurrentMaterial, currentMaterial } = useMaterialsManager()
    const { fetchMaterials, deleteMaterial, saveMaterial } = useMaterialStore()
    const { materials, loading } = storeToRefs(useMaterialStore())
    const { user, getUser } = useUser()

    const searchTerm = ref('')
    const showNewMaterialDialog = ref(false)

    fetchMaterials()
    getUser()

    return {
      materials,
      setCurrentMaterial,
      resetCurrentMaterial,
      deleteMaterial,
      saveMaterial,
      currentMaterial,
      searchTerm,
      materialsHeaders,
      loading,
      showNewMaterialDialog,
      user,
      v: useVuelidate()
    }
  },

  validations() {
    return {
      currentMaterial: {
        name: { required: isRequired },
        density: { numeric: isNumeric },
        shortcode: { unique: this.isUniqueShortcode }
      }
    }
  },

  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },

    showActions() {
      return this.user.canUpdate('material') || this.user.canDelete('material')
    }
  },

  methods: {
    onDeleteMaterial(id, closeDialog) {
      closeDialog()
      this.deleteMaterial(id)
    },

    addMaterial() {
      this.resetCurrentMaterial()
      this.showNewMaterialDialog = true
    },

    updateMaterial(material) {
      this.setCurrentMaterial(material)
      this.showNewMaterialDialog = true
    },

    async handleSaveMaterial() {
      const valid = await this.v.$validate()
      if (!valid) return

      this.saveMaterial(this.currentMaterial)
      this.resetCurrentMaterial()
      this.showNewMaterialDialog = false
    },
    isUniqueShortcode() {
      const shortcodes = this.materials
        .filter(({ id }) => id !== this.currentMaterial.id)
        .map(({ shortcode }) => shortcode)

      return !shortcodes.includes(this.currentMaterial.shortcode)
    }
  }
}
</script>
