const errorMessages = []

function iterateErrors(value, label = [], block = null) {
  if (isArray(value)) {
    return value.forEach((val, index) => iterateErrors(val, label, block ?? index))
  }

  if (isObject(value)) {
    return Object.keys(value).forEach((key) => iterateErrors(value[key], [...label, key], block))
  }

  return errorMessages.push({
    block,
    label,
    value
  })
}

function isObject(value) {
  return typeof value == 'object' && !Array.isArray(value) && value !== null
}

function isArray(value) {
  return Array.isArray(value)
}

function getErrorMessages(errors) {
  errorMessages.length = 0
  iterateErrors(errors)
  return errorMessages
}

export function getCarbonDataErrors(errors) {
  return getErrorMessages(errors).filter((error) =>
    error.label.includes('ASSEMBLY_CARBONDATA_MISSING')
  )
}

export function getBuildingErrors(errors) {
  return getErrorMessages(errors).filter(
    (error) => !error.label.includes('ASSEMBLY_CARBONDATA_MISSING')
  )
}
