import { ref } from 'vue'
import { useVOnboarding } from 'v-onboarding'

const onboardingWrapper = ref()
const steps = ref([])

const options = {
  popper: {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10]
        }
      }
    ]
  },
  overlay: {
    enabled: true,
    padding: 5,
    borderRadius: 5,
    preventOverlayInteraction: true
  }
}

export function useOnboarding() {
  const { start, finish } = useVOnboarding(onboardingWrapper)

  function finishOnEscape({ key }) {
    if (key === 'Escape') finish()
  }

  function beforeStep() {
    document.addEventListener('keydown', finishOnEscape)
  }

  function afterStep() {
    document.removeEventListener('keydown', finishOnEscape)
  }

  function onFinish() {
    localStorage.setItem('onboarding', 'true')
    afterStep()
  }

  function closeOnboarding() {
    finish()
    onFinish()
  }

  const siteBoundaryStep = {
    attachTo: { element: '.mapbox-gl-draw_ctrl-draw-btn' },
    content: {
      title: 'Draw your site boundary',
      description:
        'Click this button to start drawing your site boundary on the map. <br /><img src="/img/draw-polygon.gif" width="310" style="margin-top: 1rem"/>',
      html: true
    },
    options: {
      popper: {
        placement: 'left-start'
      }
    },
    on: {
      beforeStep,
      afterStep
    }
  }

  const newProjectSteps = [
    {
      attachTo: { element: '#foo' },
      content: {
        title: 'Create a new project',
        description: 'Start by giving your project a name'
      },
      options: {
        popper: {
          placement: 'right'
        }
      },
      on: {
        beforeStep,
        afterStep
      }
    },
    {
      attachTo: { element: '#projectLocationAddress' },
      content: {
        title: 'Location Address',
        description:
          'This will be automatically filled out from your site boundary. You can override this if you need to.'
      },
      options: {
        popper: {
          placement: 'right'
        }
      },
      on: {
        beforeStep,
        afterStep
      }
    },
    {
      attachTo: { element: '#projectOtherFields' },
      content: {
        title: 'Other fields',
        description:
          'Fill out the other fields of your project, or leave them as they are. These will only be used for reporting purposes.'
      },
      options: {
        popper: {
          placement: 'right'
        }
      },
      on: {
        beforeStep,
        afterStep
      }
    },
    {
      attachTo: { element: '#projectSave' },
      content: {
        title: 'Save your project',
        description: 'Once you are done, save your project'
      },
      options: {
        popper: {
          placement: 'right'
        }
      },
      on: {
        beforeStep,
        afterStep
      }
    },
    {
      attachTo: { element: '#compareTab' },
      content: {
        title: 'Create a design',
        description: 'Continue to the compare tab to upload or create a new design'
      },
      options: {
        popper: {
          placement: 'right'
        }
      },
      on: {
        beforeStep,
        afterStep: onFinish
      }
    }
  ]

  function startCreateProjectTour(force = false, showSiteBoundary = true) {
    const onboardingComplete = localStorage.getItem('onboarding') === 'true'
    if (onboardingComplete && !force) return
    steps.value = [...newProjectSteps]
    if (showSiteBoundary) {
      steps.value.splice(1, 0, siteBoundaryStep)
    }
    start()
  }

  function startSiteBoundaryTour() {
    steps.value = [siteBoundaryStep]
    start()
  }

  return {
    onboardingWrapper,
    steps,
    options,
    startCreateProjectTour,
    startSiteBoundaryTour,
    closeOnboarding
  }
}
