<template>
  <v-expansion-panel expand-icon="keyboard_arrow_down" collapse-icon="keyboard_arrow_up">
    <template #title>
      <div class="w-100 d-flex align-center ga-3 me-3">
        <v-icon>wifi_home</v-icon>
        <span class="text-overline font-weight-bold">Retrofit</span>
        <v-spacer />
        <div class="text-caption lh-sm d-flex flex-column ga-0 text-end">
          {{ selectedBuildingBlock?.is_retrofit ? 'Retained' : 'New' }}
        </div>
      </div>
    </template>

    <template #text>
      <v-row v-if="selectedBuildingBlock">
        <v-col class="pt-8">
          <div v-if="rootIsRetrofit">
            <h2 class="text-body-1 font-weight-bold mb-4">Building Level Parameters</h2>
            <h2 class="text-overline font-weight-bold mb-2">Material Quantity for Strengthening</h2>
            <v-row>
              <v-col cols="6">
                <div class="text-subtitle-2 text-grey-darken-2">Substructure / Foundation (%)</div>

                <v-slider
                  v-model="selectedBuilding.foundation_strengthening_perc"
                  :min="100"
                  :max="200"
                  :step="1"
                  color="primary"
                  hide-details
                  :disabled="locked"
                >
                  <template #append>
                    {{ selectedBuilding.foundation_strengthening_perc }}
                  </template>
                </v-slider>
              </v-col>

              <v-col cols="6">
                <div class="text-subtitle-2 text-grey-darken-2">Lateral System (%)</div>

                <v-slider
                  v-model="selectedBuilding.lateral_strengthening_perc"
                  :min="100"
                  :max="200"
                  :step="1"
                  color="primary"
                  hide-details
                  :disabled="locked"
                >
                  <template #append>
                    {{ selectedBuilding.lateral_strengthening_perc }}
                  </template>
                </v-slider>
              </v-col>
            </v-row>
          </div>

          <v-divider v-if="rootIsRetrofit" class="my-8" />

          <div class="d-flex ga-1">
            <h2 class="text-body-1 font-weight-bold mb-4">Block Level Parameters</h2>
            <v-tooltip>
              <template #default>
                Indicate if any building elements within a block are retained.<br />
                If retained, add additional block-level inputs to specify the details of the
                retention. <br />
                If a block type is "New", no retention of existing elements is considered.
              </template>
              <template #activator="{ props }">
                <v-btn
                  icon="info"
                  v-bind="props"
                  variant="text"
                  size="small"
                  color="secondary"
                  density="comfortable"
                />
              </template>
            </v-tooltip>
          </div>

          <v-select
            v-model="selectedBuildingBlock.is_retrofit"
            :items="[
              { title: 'New', value: false },
              { title: 'Retained', value: true }
            ]"
            density="compact"
            label="Block type"
            :disabled="locked || (!selectedBuildingBlock.isRoot && !rootIsRetrofit)"
          />

          <div v-if="selectedBuildingBlock.is_retrofit" class="mb-4">
            <div class="d-flex ga-1 mt-8 mb-2">
              <h2 class="text-overline font-weight-bold">Material Quantity Retention</h2>
              <v-tooltip>
                <template #default>
                  Specify the percent of material quantities that are retained for a block. <br />
                  100% retention means that all materials are retained, with no new materials.
                  <br />
                  0% retention means that all materials are new.
                </template>
                <template #activator="{ props }">
                  <v-btn
                    icon="info"
                    v-bind="props"
                    variant="text"
                    size="small"
                    color="secondary"
                    density="comfortable"
                  />
                </template>
              </v-tooltip>
            </div>

            <v-row>
              <v-col cols="4">
                <div class="text-subtitle-2 text-grey-darken-2">Columns (%)</div>

                <v-slider
                  v-model="selectedBuildingBlock.retrofit_parameters.columns_retention_perc"
                  :min="0"
                  :max="100"
                  :step="1"
                  color="primary"
                  hide-details
                  :disabled="locked"
                >
                  <template #append>
                    {{ selectedBuildingBlock.retrofit_parameters.columns_retention_perc }}
                  </template>
                </v-slider>
              </v-col>

              <v-col cols="4">
                <div class="text-subtitle-2 text-grey-darken-2">Beams (%)</div>

                <v-slider
                  v-model="selectedBuildingBlock.retrofit_parameters.beams_retention_perc"
                  :min="0"
                  :max="100"
                  :step="1"
                  color="primary"
                  hide-details
                  :disabled="locked"
                >
                  <template #append>
                    {{ selectedBuildingBlock.retrofit_parameters.beams_retention_perc }}
                  </template>
                </v-slider>
              </v-col>

              <v-col cols="4">
                <div class="text-subtitle-2 text-grey-darken-2">Floors (%)</div>

                <v-slider
                  v-model="selectedBuildingBlock.retrofit_parameters.floors_retention_perc"
                  :min="0"
                  :max="100"
                  :step="1"
                  color="primary"
                  hide-details
                  :disabled="locked"
                >
                  <template #append>
                    {{ selectedBuildingBlock.retrofit_parameters.floors_retention_perc }}
                  </template>
                </v-slider>
              </v-col>
            </v-row>

            <div class="d-flex ga-1 mt-8 mb-2">
              <h2 class="text-overline font-weight-bold">Material Quantity for Strengthening</h2>
              <v-tooltip>
                <template #default>
                  Specify the percent of materials that are strengthened. <br />
                  100% strengthening corresponds to just the existing materials, no strengthening
                  added. <br />
                  Inputs larger than 100% mean that the full existing materials are strengthened by
                  the given percentage.
                </template>
                <template #activator="{ props }">
                  <v-btn
                    icon="info"
                    v-bind="props"
                    variant="text"
                    size="small"
                    color="secondary"
                    density="comfortable"
                  />
                </template>
              </v-tooltip>
            </div>

            <v-row>
              <v-col cols="6" class="pb-0">
                <div class="text-subtitle-2 text-grey-darken-2">Columns (%)</div>

                <v-slider
                  v-model="selectedBuildingBlock.retrofit_parameters.columns_strengthening_perc"
                  :min="100"
                  :max="200"
                  :step="1"
                  color="primary"
                  hide-details
                  :disabled="locked"
                >
                  <template #append>
                    {{ selectedBuildingBlock.retrofit_parameters.columns_strengthening_perc }}
                  </template>
                </v-slider>
              </v-col>

              <v-col cols="6" class="pb-0">
                <div class="text-subtitle-2 text-grey-darken-2">Beams (%)</div>

                <v-slider
                  v-model="selectedBuildingBlock.retrofit_parameters.beams_strengthening_perc"
                  :min="100"
                  :max="200"
                  :step="1"
                  color="primary"
                  hide-details
                  :disabled="locked"
                >
                  <template #append>
                    {{ selectedBuildingBlock.retrofit_parameters.beams_strengthening_perc }}
                  </template>
                </v-slider>
              </v-col>

              <v-col cols="6" class="pb-0">
                <div class="text-subtitle-2 text-grey-darken-2">Floors (%)</div>

                <v-slider
                  v-model="selectedBuildingBlock.retrofit_parameters.floors_strengthening_perc"
                  :min="100"
                  :max="200"
                  :step="1"
                  color="primary"
                  hide-details
                  :disabled="locked"
                >
                  <template #append>
                    {{ selectedBuildingBlock.retrofit_parameters.floors_strengthening_perc }}
                  </template>
                </v-slider>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </template>
  </v-expansion-panel>
</template>

<script setup>
import { useLiveediting } from '@/composables/liveediting'
import { computed, watch } from 'vue'

defineProps({
  locked: {
    type: Boolean,
    default: false
  }
})

const { selectedBuildingBlock, selectedBuilding } = useLiveediting()

const rootIsRetrofit = computed(() => {
  return selectedBuilding.value.getRootBlock().is_retrofit
})

const blockIsRetrofit = computed(() => {
  return selectedBuildingBlock.value.is_retrofit
})

watch(blockIsRetrofit, (value) => {
  if (!value) selectedBuildingBlock.value.resetRetrofitParameters()
})

watch(rootIsRetrofit, (value) => {
  if (!value) {
    selectedBuilding.value.resetFDNStrengthening()
    selectedBuilding.value.resetLateralStrengthening()
    for (const block of selectedBuilding.value.blocks) {
      block.resetRetrofitParameters()
    }
  }
})
</script>
