const MESSAGES = {
  MAP_DRAW_ONLY_ONE_POLYGON_ALLOWED:
    "Oh snap! There's a site drawn already. You can either edit that or draw one from scratch :)",
  MAP_LOCATE_ERR_EMPTYSTRING: 'You need to type in a search string..',
  MAP_LOCATE_NO_RESULTS: 'No results for given location.',
  MAP_LOCATE_GENERAL_FAILURE: 'Error: Geo search failed',

  UI_VALIDATION_MISSING_PLOT: "You haven't drawn your building area on the map!",
  UI_VALIDATION_LOW_FLOORAREA: 'Your floor ares is too low!',
  UI_VALIDATION_REQUIRED_FIELD: 'This field is required',
  UI_VALIDATION_NUMBER_REQUIRED: 'This field requires a valid number',
  UI_VALIDATION_MISSING_MATERIALS: "You haven't added any materials",
  UI_VALIDATION_MISSING_SHAPE: "You haven't selected any shape!",
  UI_VALIDATION_MISSING_STRUCTURE: "You haven't selected any structure!",
  UI_VALIDATION_MISSING_ENVELOPEWALL: "You haven't selected any wall envelope!",
  UI_VALIDATION_MISSING_ENVELOPEROOF: "You haven't selected any roof envelope!",
  UI_VALIDATION_SHORTCODE_MUST_BE_UNIQUE: 'This shortcode is already in use',

  UI_SWITCH2PROJECT: 'Switching to project mode ...',
  UI_SWITCH2DESIGNMODE: 'Switching to generative design mode ...',
  UI_SWITCH2RESULTS: 'Switching to results view ...',
  UI_SWITCH2LIVEEDITING: 'Switching to Live Editing mode ...',
  UI_SWITCH2COMPARE: 'Switching to compare mode ...',

  UI_GENDESIGN_STARTING: 'Running preoptima ... ',
  UI_CONSOLIDATE_SOLS: 'Consolidating solutions ...',
  UI_NUM_OF_RUNS: 'Analysing {0} design setups for you.',
  UI_NUM_OF_RUNS_COMPLETED: 'Analysed {0} design setups<br/> out of {1}',
  UI_ALL_RUNS_COMPLETED: 'We got solutions for you!',
  UI_STARTUP: 'Starting up preoptima building...',

  UI_IMPORT_PROCESSING: 'Processing OBJ file ...',
  UI_IMPORT_RUNNING_LCA: 'Running LCA on imported building ...',
  UI_IMPORT_EDIT_SWITCHING: 'Almost ready... Switching to Edit mode',

  UI_LCA_RUNNING: 'Running LCA ...',
  UI_QTO_LATERAL_RUNNING: 'Running QTO LateralSys',
  UI_SAVING_LCA_RUNNING: 'Saving...',

  BUILDING_QTO_SUCCESS: 'QTO figures refreshed',
  BUILDING_QTO_ERROR: 'Error in refreshing QTO figures',

  UI_REPORT_NOSOL_SELECTED: 'You need to select a design solution ... ',

  PROJECT_SAVE_ERROR: 'Error in saving your project!',
  PROJECT_SAVE_SUCCESS: 'Project saved succesfully',
  PROJECT_DELETE_SUCCESS: 'Project deleted succesfully',
  PROJECT_LOAD_ERROR: 'Error in loading your project!',
  PROJECT_LIST_ERROR: 'Error in listing your project!',

  BUILDING_SAVE_ERROR: 'Error in saving your building!',
  BUILDING_SAVE_SUCCESS: 'Building saved succesfully',
  BUILDING_LOAD_ERROR: 'Error in loading your building!',
  BUILDING_LIST_ERROR: 'Error in listing your buildings!',

  BUILDING_CONFIRM_ERROR: 'Error in adding this building to your project!',
  BUILDING_CONFIRM_SAVE: 'Building added to your project',
  BUILDING_UNCONFIRM_ERROR: 'Error in removing this building from your project!',
  BUILDING_UNCONFIRM_CONFIRM: 'Building removed from your project',

  MATERIAL_SAVE_SUCCESS: 'Material saved succesfully',
  MATERIAL_SAVE_ERROR: 'Error in saving your material',
  MATERIAL_DELETE_ERROR: 'Error in deleting your material',
  MATERIAL_DELETE_SUCCESS: 'Material deleted succesfully',
  MATERIAL_USED_DELETE_ERROR: 'Material being used in an assembly and cannot be deleted',
  MATERIAL_LIST_ERROR: 'Error in loading your materials',
  MATERIAL_LOAD_ERROR: 'Error in loading your material',

  ASSEMBLY_SAVE_SUCCESS: 'Assembly saved succesfully',
  ASSEMBLY_SAVE_ERROR: 'Error in saving your assembly',
  ASSEMBLY_DELETE_ERROR: 'Error in deleting your assembly',
  ASSEMBLY_DELETE_SUCCESS: 'Assembly deleted succesfully',
  ASSEMBLY_LIST_ERROR: 'Error in loading your assemblies',
  ASSEMBLY_LOAD_ERROR: 'Error in loading your assembly',

  BUILDING_LCA_SUCCESS: 'LCA figures refreshed',
  BUILDING_LCA_ERROR: 'Error in refreshing LCA figures',

  BUILDINGMATERIAL_LIST_ERROR: 'Error in loading your building materials!',
  BUILDING_WINDOWS_ERROR: 'Error in generating building windows.',

  GENDESIGN_LOAD_ERROR: 'Error in loading your last generative design run!',

  ERROR_GENERAL: 'Oops! Something went wrong...',

  UPLOAD_ERROR: 'There was a problem uploading your file'
}

export { MESSAGES }
