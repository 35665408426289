import { ref } from 'vue'
import { LCAStagesGroups } from '@/library/enums'
import { LCABuildingElementLabels } from '@/library/models/LCABuildingElements'
import { storeToRefs } from 'pinia'
import { useBuildingStore } from '@/stores/building'

const stagesData = ref([])
const structData = ref([])
const parallelCoordsData = ref([])
const showLiveBox = ref(false)
const live = ref({
  ec_m2: null,
  oc_m2: null,
  wlc_m2: null,
  ec: null,
  oc: null,
  wlc: null
})

export function useDataViz() {
  const { buildings } = storeToRefs(useBuildingStore())

  function refreshLiveBox(bldg) {
    showLiveBox.value = false
    if (!bldg?.EC_A1A3_CO2KG) return

    live.value.ec_m2 = Math.floor(bldg.EC_CO2KGm2.total(LCAStagesGroups.C4))
    live.value.ec = Math.floor(bldg.EC_CO2KG.total())
    live.value.oc_m2 = Math.floor(bldg.OC_B6_CO2KGm2 + bldg.OC_B7_CO2KGm2) || '-'
    live.value.oc = Math.floor(bldg.OC_B6_CO2KG + bldg.OC_B7_CO2KG) || '-'
    live.value.wlc_m2 = bldg.WLCm2 ? Math.floor(bldg.WLCm2) : '-'
    live.value.wlc = bldg.WLC ? Math.floor(bldg.WLC) : '-'

    parallelCoordsData.value = formatParallelCoordsData(buildings.value)
    stagesData.value = formatStagesData(bldg)
    structData.value = formatStructData(bldg)
    showLiveBox.value = true
  }

  function formatStagesData(bldg, normalize = true) {
    const LCA = bldg.carbonByStage
    return [
      { name: 'A1A3', value: normalize ? LCA.A1A3 / bldg.GIA : LCA.A1A3 },
      { name: 'A4', value: normalize ? LCA.A4 / bldg.GIA : LCA.A4 },
      { name: 'A5_1', value: normalize ? LCA.A5_1 / bldg.GIA : LCA.A5_1 },
      { name: 'A5_2', value: normalize ? LCA.A5_2 / bldg.GIA : LCA.A5_2 },
      { name: 'A5_3', value: normalize ? LCA.A5_3 / bldg.GIA : LCA.A5_3 },
      { name: 'B1_2', value: normalize ? LCA.B1_2 / bldg.GIA : LCA.B1_2 },
      { name: 'B2', value: normalize ? LCA.B2 / bldg.GIA : LCA.B2 },
      { name: 'B3', value: normalize ? LCA.B3 / bldg.GIA : LCA.B3 },
      { name: 'B4', value: normalize ? LCA.B4 / bldg.GIA : LCA.B4 },
      { name: 'C1', value: normalize ? LCA.C1 / bldg.GIA : LCA.C1 },
      { name: 'C2', value: normalize ? LCA.C2 / bldg.GIA : LCA.C2 },
      { name: 'C3', value: normalize ? LCA.C3 / bldg.GIA : LCA.C3 },
      { name: 'C4', value: normalize ? LCA.C4 / bldg.GIA : LCA.C4 }
    ]
  }

  function formatStructData(bldg) {
    const LCA = bldg.getCarbonByElement()
    const exclusions = ['Structure', 'Sub Structure', 'Site', 'Secondary System']
    return Object.keys(LCA)
      .map((key) => ({
        name: LCABuildingElementLabels[key],
        value: LCA[key] / bldg.GIA,
        total: LCA[key]
      }))
      .filter(({ name }) => !exclusions.includes(name))
      .sort((a, b) => b.total - a.total)
  }

  function checkUniqueLabel(label, id, bldgList, count = 0) {
    const checkLabel = count > 0 ? `${label} (${count})` : label
    const res = bldgList.find((b) => b.label == checkLabel && b.id != id)
    if (res) return checkUniqueLabel(label, id, bldgList, count + 1)
    else return checkLabel
  }

  function formatParallelCoordsData(bldgList) {
    const sortedList = bldgList
      .map((building) => ({
        id: building.id,
        gfa: building.GFA,
        stages: building.getCarbonByElement(LCAStagesGroups.A5_3),
        compactness: building.compactness,
        height: building.height,
        carbon: building.EC_CO2KGm2.total(LCAStagesGroups.C4),
        label: building.label
      }))
      .sort((a, b) => a.carbon - b.carbon)

    sortedList.forEach((b) => {
      b.label = checkUniqueLabel(b.label, b.id, sortedList)
    })

    return sortedList.map((b) => ({
      building: b.label,
      'Total: kgCO2e/m²': b.carbon,
      'Foundation: kgCO2e/m²': b.stages.Foundation / b.gfa,
      'UG Storeys: kgCO2e/m²': b.stages.UndergroundStoreys / b.gfa,
      'Lateral Sys: kgCO2e/m²': b.stages.LateralSystem / b.gfa,
      'Walls: kgCO2e/m²': b.stages.Wall / b.gfa,
      'Columns: kgCO2e/m²': b.stages.Column / b.gfa,
      'Beams: kgCO2e/m²': b.stages.Beam / b.gfa,
      'Floors: kgCO2e/m²': b.stages.Floor / b.gfa,
      'Roof: kgCO2e/m²': b.stages.Roof / b.gfa,
      Compactness: b.compactness,
      'GFA: m²': b.gfa,
      'Height: m': b.height
    }))
  }

  return {
    refreshLiveBox,
    showLiveBox,
    live,
    stagesData,
    structData,
    parallelCoordsData,
    formatStagesData
  }
}
