<template>
  <div
    id="waiting-bg-report"
    class="waiting-bg-startup d-flex align-center justify-center"
    @click="emit('close')"
  >
    <div>
      <iframe
        id="report-pdf"
        ref="report-iframe"
        name="report-pdf"
        type="application/pdf"
        :src="url"
      ></iframe>
    </div>
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted } from 'vue'

defineProps({
  url: {
    type: String,
    required: true
  }
})

const emit = defineEmits(['close'])

onMounted(() => {
  document.addEventListener('keydown', escEvent)
})

onBeforeUnmount(() => {
  document.removeEventListener('keydown', escEvent)
})

function escEvent({ key }) {
  if (key === 'Escape') emit('close')
}
</script>
