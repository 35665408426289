import { pApiClient } from '@/library/api'
import User from '@/library/models/User'
import { ref } from 'vue'

const user = ref(new User())

export function useUser() {
  async function getUser(reload = false) {
    if (user.value.id && !reload) return

    const localUser = JSON.parse(localStorage.getItem('user'))
    if (!localUser) return

    const { data } = await pApiClient.get('/user/' + localUser.id, {
      suffix: 's/'
    })

    setUser(data)
  }

  function setUser(data) {
    user.value = new User(data)
  }

  async function updateUser() {
    const { data } = await pApiClient.put('/user/' + user.value.id, user.value, {
      suffix: 's/'
    })
    setUser(data)
  }

  async function acceptTcAndDp() {
    const { data } = await pApiClient.put(
      '/user/' + user.value.id,
      {
        tc_acceptance: true,
        dp_acceptance: true
      },
      {
        suffix: 's/'
      }
    )
    setUser(data)
  }

  async function changePassword(oldPassword, newPassword, confirmPassword) {
    await pApiClient.put(
      '/change-password/' + user.value.id,
      {
        old_password: oldPassword,
        password: newPassword,
        password_confirm: confirmPassword
      },
      { suffix: 's/' }
    )
  }

  return {
    getUser,
    setUser,
    updateUser,
    changePassword,
    acceptTcAndDp,
    user
  }
}
