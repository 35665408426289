import { ref } from 'vue'
import Material from '@/library/models/Material'

const currentMaterial = ref(new Material())

export function useMaterialsManager() {
  function resetCurrentMaterial() {
    currentMaterial.value = new Material()
  }

  /**
   * @param {Material} material
   * @returns {void}
   */
  function setCurrentMaterial(material) {
    currentMaterial.value = material
  }

  return {
    currentMaterial,
    resetCurrentMaterial,
    setCurrentMaterial
  }
}
