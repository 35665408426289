<template>
  <v-expansion-panel expand-icon="keyboard_arrow_down" collapse-icon="keyboard_arrow_up">
    <template #title>
      <div class="w-100 d-flex align-center ga-3 me-3">
        <v-icon> foundation</v-icon>
        <span class="text-overline font-weight-bold">Substructure</span>
        <v-spacer />
        <div class="text-caption lh-sm d-flex flex-column ga-0 text-end">
          <span>UG Storeys: {{ selectedBuilding.UG_storeys }}</span>
          <span v-if="selectedFoundationType"> {{ selectedFoundationType.label }} </span>
          <span v-if="selectedSoilType"> Soil: {{ selectedSoilType.label }} </span>
        </div>
      </div>
    </template>

    <template #text>
      <v-row class="my-2">
        <v-col>
          <v-select
            v-model="selectedBuilding.foundation_type"
            label="UG structure type"
            :items="foundationTypes"
            :disabled="locked"
            class="mb-3"
          />

          <div class="text-subtitle-2 text-grey-darken-2">Number of UG storeys</div>

          <v-slider
            v-model.number="selectedBuilding.UG_storeys"
            :min="subStructure.storeys.min"
            :max="subStructure.storeys.max"
            :step="subStructure.storeys.step"
            :disabled="locked || user.isReadonly('UG_storeys')"
            color="primary"
            hide-details
          >
            <template #append>
              {{ selectedBuilding.UG_storeys }}
            </template>
          </v-slider>
        </v-col>

        <v-col>
          <v-select
            v-model="selectedBuilding.soil_type"
            label="Soil type"
            :items="soilTypes"
            :disabled="locked"
          />

          <div class="text-subtitle-2 text-grey-darken-2">UG storey height ({{ config.um }})</div>
          <v-slider
            v-show="selectedBuilding.UG_storeys > 0"
            v-model.number="selectedBuilding.UG_ish"
            :min="3"
            :max="5"
            :step="config.step"
            :disabled="locked || user.isReadonly('UG_ish')"
            color="primary"
            hide-details
          >
            <template #append>
              {{ config.displayValue(selectedBuilding.UG_ish) }}
            </template>
          </v-slider>
        </v-col>
      </v-row>
    </template>
  </v-expansion-panel>
</template>

<script>
import { useLiveediting } from '@/composables/liveediting'
import { subStructure } from '@/library/appConfigs'
import User from '@/library/models/User'

export default {
  props: {
    locked: {
      type: Boolean,
      default: false
    },
    appConfigs: {
      type: Object,
      required: true
    },
    user: {
      type: User,
      required: true
    }
  },
  setup() {
    const { selectedBuilding } = useLiveediting()

    return {
      selectedBuilding,
      subStructure
    }
  },

  computed: {
    config() {
      return this.appConfigs.building.UGStoreyHeight
    },

    selectedFoundationType() {
      return this.selectedBuilding.foundation_type
        ? this.subStructure.types.find(
            ({ value }) => value == this.selectedBuilding.foundation_type
          ).title
        : ''
    },

    foundationTypes() {
      const options = this.user.getOptions('foundation_type')
      return options.length
        ? this.user.limitItems(options, this.subStructure.types)
        : this.subStructure.types
    },

    soilTypes() {
      const options = this.user.getOptions('soil_type')
      return options.length
        ? this.user.limitItems(options, this.subStructure.soilTypes)
        : this.subStructure.soilTypes
    },

    selectedSoilType() {
      return this.selectedBuilding.soil_type
        ? this.subStructure.soilTypes.find(({ value }) => value == this.selectedBuilding.soil_type)
            .title
        : ''
    }
  }
}
</script>
