<template>
  <VOnboardingWrapper ref="onboardingWrapper" :steps="steps" :options="options">
    <template #default="{ previous, next, step, isFirst, isLast }">
      <VOnboardingStep>
        <v-card max-width="340">
          <v-card-title v-if="step">
            <h3 v-if="step.content.title">
              {{ step.content.title }}
            </h3>
          </v-card-title>
          <v-card-text v-if="step.content.description">
            <div v-if="step.content.html" v-html="step.content.description" />
            <p v-else>{{ step.content.description }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn v-if="!isLast" color="primary" @click="closeOnboarding"> Close </v-btn>
            <v-spacer />
            <template v-if="!isFirst">
              <v-btn color="secondary" class="me-2" @click="previous"> Previous </v-btn>
            </template>
            <v-btn color="primary" @click="next">
              {{ isLast ? 'Finish' : 'Next' }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </VOnboardingStep>
    </template>
  </VOnboardingWrapper>
</template>

<script setup>
import { useOnboarding } from '@/composables/onboarding'
import { VOnboardingWrapper, VOnboardingStep } from 'v-onboarding'

const { onboardingWrapper, steps, options, closeOnboarding } = useOnboarding()
</script>

<style>
:root {
  --v-onboarding-overlay-z: 1007;
  --v-onboarding-step-z: 1008;
  --v-onboarding-step-arrow-background: transparent;
}
</style>
