<template>
  <v-dialog v-model="show" scrollable>
    <v-card color="surface">
      <v-card-title class="d-flex ga-3 mt-2 flex-column">
        <div class="d-flex align-center justify-space-between mb-2">
          <h2 class="text-h5 font-weight-bold text-surface-variant">Manage Datasets</h2>

          <v-tooltip>
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                icon="close"
                density="comfortable"
                color="error"
                variant="tonal"
                @click="show = false"
              />
            </template>
            <span>Close</span>
          </v-tooltip>
        </div>

        <v-tabs v-model="tab" color="primary" bg-color="surface-5" center-active show-arrows>
          <v-tab
            v-for="dataset of tabs"
            :key="dataset.id"
            :value="dataset.id"
            @click="fetchDataset(dataset.id)"
          >
            {{ dataset.name }}
          </v-tab>

          <v-menu v-if="moreTabs.length">
            <template #activator="{ props }">
              <v-btn
                variant="plain"
                rounded="0"
                class="align-self-center me-4"
                height="100%"
                v-bind="props"
              >
                more
                <v-icon end> arrow_drop_down </v-icon>
              </v-btn>
            </template>

            <v-list class="bg-surface-3">
              <v-list-item
                v-for="dataset in moreTabs"
                :key="dataset.id"
                :value="dataset.id"
                @click="fetchDataset(dataset.id)"
              >
                {{ dataset.name }}
              </v-list-item>
            </v-list>
          </v-menu>

          <v-spacer />
          <v-btn
            variant="text"
            rounded="0"
            class="align-self-center me-2"
            height="100%"
            color="primary"
            :prepend-icon="user.canCreate('dataset') ? 'add' : 'lock'"
            :disabled="!user.canCreate('dataset')"
            @click="clickCreateDataset"
          >
            Add Dataset
          </v-btn>
        </v-tabs>

        <v-card flat class="mb-4">
          <v-row>
            <v-col>
              <v-card v-if="currentDataset" variant="tonal" color="blue-grey-lighten-3">
                <v-card-text>
                  <v-table>
                    <tr>
                      <th align="left">Dataset</th>
                      <td>{{ currentDataset?.name }}</td>
                    </tr>
                    <tr>
                      <th align="left">Region</th>
                      <td>{{ currentDataset?.region }}</td>
                    </tr>
                    <tr>
                      <th align="left">LCA Methodology</th>
                      <td>{{ currentDataset?.lca_methodology }}</td>
                    </tr>
                  </v-table>

                  <v-sheet class="d-flex ga-2 mt-4">
                    <v-btn
                      v-if="user.canUpdate('dataset')"
                      density="comfortable"
                      color="primary"
                      variant="tonal"
                      prepend-icon="edit"
                      text="Edit"
                      @click="editDataset"
                    />
                    <v-dialog v-if="user.canDelete('dataset')" max-width="450px">
                      <template #activator="{ props }">
                        <v-btn
                          v-bind="props"
                          density="comfortable"
                          color="error"
                          variant="tonal"
                          text="Delete"
                          prepend-icon="delete"
                        />
                      </template>

                      <template #default="{ isActive }">
                        <v-card title="Dialog">
                          <v-card-text> Are you sure you want to delete this dataset? </v-card-text>

                          <v-card-actions>
                            <v-spacer />
                            <v-btn color="primary" variant="text" @click="isActive.value = false">
                              Cancel
                            </v-btn>
                            <v-btn
                              color="error"
                              variant="text"
                              @click="handleDeleteDataset(() => (isActive.value = false))"
                            >
                              Delete
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </template>
                    </v-dialog>
                    <v-btn
                      v-if="user.canCreate('dataset')"
                      density="comfortable"
                      color="success"
                      variant="tonal"
                      text="Duplicate"
                      prepend-icon="content_copy"
                      @click="createDatasetCopy"
                    />
                  </v-sheet>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col>
              <v-row>
                <v-col class="d-flex">
                  <v-spacer />
                  <v-text-field
                    v-model="search"
                    label="Search materials"
                    prepend-inner-icon="search"
                    single-line
                    variant="outlined"
                    hide-details
                    clearable
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col class="d-flex justify-end align-end">
                  <v-btn-toggle
                    v-model="materialsToShow"
                    density="compact"
                    mandatory
                    variant="outlined"
                    color="primary"
                  >
                    <v-btn density="compact" size="small" value="customerMaterials" height="30">
                      Envelope Materials
                    </v-btn>
                    <v-btn density="compact" size="small" value="structuralMaterials" height="30">
                      Structural Materials
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-card-title>

      <v-card-text>
        <v-data-table
          v-model:expanded="expanded"
          :headers="tableHeaders"
          :items="carbonDataMaterials[materialsToShow]"
          hover
          items-per-page="10"
          :show-expand="materialsToShow === 'structuralMaterials'"
          :search="search"
          :loading="loading"
          loading-text="Loading datasets..."
        >
          <template v-if="user.canUpdate('dataset')" #[`item.actions`]="{ item }">
            <v-btn
              icon="edit"
              size="small"
              density="comfortable"
              variant="text"
              color="primary"
              @click="editCarbonData(item)"
            />
          </template>

          <template #expanded-row="{ columns, item }">
            <tr>
              <td :colspan="columns.length">
                <span class="text-overline me-2">Assemblies</span>
                <v-chip
                  v-for="(assembly, index) in item.assemblies"
                  :key="index"
                  density="compact"
                  color="primary"
                  class="me-2"
                >
                  {{ assembly }}
                </v-chip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <CarbonDataModal
      v-model="showCarbonDataDialog"
      :materials="[
        ...carbonDataMaterials.customerMaterials,
        ...carbonDataMaterials.structuralMaterials
      ]"
    />

    <CreateDatasetModal v-model="showDatasetDialog" @new-dataset="setTab" />
  </v-dialog>
</template>

<script>
import { useDatasetStore } from '@/stores/dataset'
import { useMaterialStore } from '@/stores/material'
import { storeToRefs } from 'pinia'
import CreateDatasetModal from './CreateDatasetModal.vue'
import CarbonDataModal from './CarbonDataModal.vue'
import { ref, computed, nextTick } from 'vue'
import { useUser } from '@/composables/user'

const headerProps = { class: 'font-weight-bold' }
const headers = [
  { key: 'actions', title: '', headerProps, sortable: false },
  { key: 'name', title: 'Name', headerProps },
  { key: 'A1A3', title: 'A1A3 (kgCO2e/kg)', headerProps },
  { key: 'B1_1', title: 'B1-1 (kgCO2e/kg)', headerProps },
  { key: 'biogenic_carbon', title: 'Biogenic Carbon (kgCO2e/kg)', headerProps },
  { key: 'tr_distance', title: 'Transport Distance (mi)', headerProps },
  { key: 'tr_method', title: 'Transport Method', headerProps },
  { key: 'C2', title: 'C2 (kgCO2e/kg)', headerProps },
  { key: 'C3', title: 'C3 (kgCO2e/kg)', headerProps },
  { key: 'C4', title: 'C4 (kgCO2e/kg)', headerProps },
  { key: 'D1', title: 'D1 (kgCO2e/kg)', headerProps },
  { key: 'waste_rate', title: 'Waste Rate (%)', headerProps },
  { key: 'mat_service_life', title: 'Material Service Life (years)', headerProps }
]

export default {
  components: { CarbonDataModal, CreateDatasetModal },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  emits: ['update:modelValue'],

  setup() {
    const { fetchMaterials } = useMaterialStore()
    const { materials } = storeToRefs(useMaterialStore())
    const { user, getUser } = useUser()

    const {
      fetchDatasetsAndSelectFirst,
      fetchDataset,
      deleteDataset,
      copyDataset,
      carbonDataFields,
      fetchStructMaterials
    } = useDatasetStore()

    const {
      datasets,
      currentDataset,
      currentMaterial,
      newDataset,
      isEditingDataset,
      structMaterials,
      loading
    } = storeToRefs(useDatasetStore())

    fetchMaterials()
    fetchDatasetsAndSelectFirst()
    fetchStructMaterials()
    getUser()

    const showAssemblies = ref(false)
    const tab = ref(0)
    const showCarbonDataDialog = ref(false)
    const showDatasetDialog = ref(false)
    const materialsToShow = ref('customerMaterials')
    const expanded = ref([])
    const search = ref('')

    const tabs = computed(() => {
      if (!currentDataset.value) return []

      const firstThree = datasets.value.slice(0, 3)
      if (firstThree.find(({ id }) => id === currentDataset.value?.id)) {
        nextTick(() => {
          tab.value = currentDataset.value?.id
        })
        return firstThree
      }

      const current = datasets.value.find(({ id }) => id === currentDataset.value?.id)
      nextTick(() => {
        tab.value = current.id
      })
      return [...firstThree.slice(0, 3), current]
    })

    const moreTabs = computed(() => {
      if (!currentDataset.value) return []

      const remaining = datasets.value.slice(3)
      if (remaining.find(({ id }) => id === currentDataset.value?.id)) {
        return remaining.filter(({ id }) => id !== currentDataset.value?.id)
      }

      return remaining
    })

    return {
      materials,
      datasets,
      currentDataset,
      carbonDataFields,
      currentMaterial,
      newDataset,
      isEditingDataset,
      fetchDataset,
      deleteDataset,
      copyDataset,
      structMaterials,
      showAssemblies,
      tab,
      headers,
      showCarbonDataDialog,
      showDatasetDialog,
      materialsToShow,
      expanded,
      search,
      tabs,
      moreTabs,
      user,
      loading
    }
  },

  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },

    tableHeaders() {
      return this.materialsToShow == 'structuralMaterials'
        ? [...headers.slice(0, 2), { key: 'data-table-expand' }, ...headers.slice(2)]
        : headers
    },

    carbonDataMaterials() {
      if (!this.currentDataset)
        return {
          customerMaterials: [],
          structuralMaterials: []
        }

      const customerMaterials = this.materials.map((material) => ({
        ...material,
        ...this.currentDataset.materials.find((x) => x.material == material.id)
      }))

      const structuralMaterials = this.structMaterials.map((material) => ({
        ...material,
        ...this.currentDataset.materials.find((x) => x.material == material.id)
      }))

      return {
        customerMaterials,
        structuralMaterials
      }
    }
  },

  methods: {
    setTab(id) {
      this.$nextTick(() => {
        this.tab = id
      })
    },

    editCarbonData(material) {
      this.currentMaterial = material
      this.showCarbonDataDialog = true
    },

    editDataset() {
      this.isEditingDataset = true
      this.newDataset = { ...this.currentDataset }
      this.showDatasetDialog = true
    },

    async handleDeleteDataset(closeDialog) {
      closeDialog()
      await this.deleteDataset()
      this.tab = 0
    },

    async createDatasetCopy() {
      await this.copyDataset()
      this.isEditingDataset = false
      this.showDatasetDialog = true
    },

    clickCreateDataset() {
      this.isEditingDataset = false
      this.newDataset = {
        name: '',
        shortcode: '',
        materials: [],
        region: 'GLOBAL',
        lca_methodology: 'PREOPTIMA',
        active: true
      }
      this.showDatasetDialog = true
    }
  }
}
</script>
