<template>
  <div v-if="requestSent" class="text-center">
    <p class="material-symbols-outlined icon text-success">mark_email_read</p>
    <h1 class="h2 mb-3 font-weight-medium text-success">Verify your email</h1>

    <p class="text-center lh-lg py-3">
      We have sent an email to {{ email }} with a link to verify your email address and activate
      your account. If you don't receive it in a few minutes, please check your spam folder.
    </p>

    <router-link
      to="/login"
      class="font-weight-bold text-decoration-underline text-body-1 text-primary"
    >
      Go back to login
    </router-link>
  </div>

  <form v-else autocomplete="on" @submit.prevent="register">
    <h1 class="h2 mb-3 fw-bold">Create your free account</h1>
    <p class="text-muted pb-4">
      Already have an account?
      <router-link
        to="/login"
        class="ms-1 font-weight-bold text-decoration-underline text-body-1 text-primary"
      >
        Sign in
      </router-link>
    </p>

    <v-alert
      v-if="error"
      type="error"
      :icon="error == 'recaptcha' ? 'robot_2' : 'error'"
      class="mb-6"
      density="default"
      variant="tonal"
      :text="error == 'recaptcha' ? 'Please verify that you are not a robot' : error"
    />

    <v-select
      v-model="accountType"
      label="Account type"
      density="default"
      :items="[
        { title: 'Company', value: 'company' },
        { title: 'Personal', value: 'personal' }
      ]"
    />

    <v-text-field
      v-if="accountType == 'company'"
      id="customer_name"
      v-model="customer_name"
      text="Organisation"
      label="Organisation"
      autocomplete="organisation"
      density="default"
      class="mb-2"
      :error-messages="v.customer_name.$errors.map((e) => e.$message)"
      @update:focused="mixpanel.track('Input Focus', { field: 'Organisation' })"
    />

    <v-row>
      <v-col>
        <v-text-field
          id="first_name"
          v-model="first_name"
          text="First Name"
          name="first_name"
          label="First Name"
          autocomplete="first_name"
          density="default"
          class="mb-2"
          :error-messages="v.first_name.$errors.map((e) => e.$message)"
          @update:focused="mixpanel.track('Input Focus', { field: 'First Name' })"
        />
      </v-col>

      <v-col>
        <v-text-field
          id="last_name"
          v-model="last_name"
          text="Last Name"
          name="last_name"
          label="Last Name"
          autocomplete="last_name"
          density="default"
          class="mb-2"
          :error-messages="v.last_name.$errors.map((e) => e.$message)"
          @update:focused="mixpanel.track('Input Focus', { field: 'Last Name' })"
        />
      </v-col>
    </v-row>

    <v-text-field
      id="email"
      v-model="email"
      name="email"
      text="Email"
      label="Email"
      autocomplete="email"
      density="default"
      class="mb-2"
      :error-messages="v.email.$errors.map((e) => e.$message)"
      @update:focused="mixpanel.track('Input Focus', { field: 'Email' })"
    />

    <v-card class="my-6" color="warning" variant="tonal">
      <v-card-text class="text-medium-emphasis text-caption">
        Must contain 8+ characters with at least 1 uppercase letter, 1 lowercase letter, 1 number,
        and 1 special character
      </v-card-text>
    </v-card>

    <v-text-field
      id="current-password"
      v-model="password"
      name="password"
      label="Password"
      placeholder="Password"
      density="default"
      class="mb-2"
      :error-messages="v.password.$errors.map((e) => e.$message)"
      :type="showPassword ? 'text' : 'password'"
      :append-inner-icon="showPassword ? 'visibility' : 'visibility_off'"
      @click:append-inner="showPassword = !showPassword"
      @update:focused="mixpanel.track('Input Focus', { field: 'Password' })"
    />

    <re-captcha />

    <v-btn
      text="Sign up for free"
      color="success"
      block
      size="large"
      class="mt-6"
      :loading="loading"
      :disabled="loading"
      @click="register"
    />
  </form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { email, helpers, minLength, required } from '@vuelidate/validators'
import { pApiClient } from '@/library/api'
import ReCaptcha from './ReCaptcha.vue'
import mixpanel from 'mixpanel-browser'

export default {
  name: 'LoginView',
  components: { ReCaptcha },
  setup() {
    return {
      mixpanel,
      v: useVuelidate()
    }
  },
  data() {
    return {
      customer_name: '',
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      loading: false,
      error: null,
      showPassword: false,
      requestSent: false,
      accountType: 'company'
    }
  },
  validations() {
    return {
      customer_name: { required },
      first_name: { required },
      last_name: { required },
      email: { required, email },
      password: {
        required,
        minLength: minLength(8),
        valid: helpers.withMessage('Password is not valid, see requirements above.', (value) => {
          const containsUppercase = /[A-Z]/.test(value)
          const containsLowercase = /[a-z]/.test(value)
          const containsNumber = /[0-9]/.test(value)
          const containsSpecial = /[#?!@$%^&*-]/.test(value)
          return containsUppercase && containsLowercase && containsNumber && containsSpecial
        })
      }
    }
  },
  methods: {
    async register() {
      this.mixpanel.track('Sign Up Clicked')
      if (this.loading) return

      if (this.accountType === 'personal' && !this.customer_name) {
        this.customer_name = this.first_name + this.last_name
      }

      const result = await this.v.$validate()
      if (!result) return
      this.loading = true

      // @ts-ignore
      const response = window.grecaptcha.getResponse()
      if (!response) {
        this.loading = false
        this.error = 'recaptcha'
        this.mixpanel.track('Captcha Error')
        return
      }

      try {
        await pApiClient.post(
          'signup',
          {
            user_name: this.first_name,
            user_surname: this.last_name,
            user_email: this.email,
            user_password: this.password,
            user_passwordconfirm: this.password,
            recaptcha: response,
            customer_name: this.customer_name,
            customer_type: 'OTHER'
          },
          { suffix: 's/' }
        )
        this.resetState()
        this.requestSent = true
      } catch (error) {
        this.mixpanel.track('Signup Error', { error })
        if (error.response?.data?.user_email) {
          this.error = error.response.data.user_email[0]
        } else {
          this.error = 'There was a problem creating your account. Please try again.'
        }
        // @ts-ignore
        window.grecaptcha.reset()
      } finally {
        this.loading = false
      }
    },
    resetState() {
      this.first_name = ''
      this.last_name = ''
      this.email = ''
      this.password = ''
      this.v.$reset()
      this.error = null
      this.requestSent = false
    }
  }
}
</script>

<style scoped>
.icon {
  font-size: 4rem;
  font-variation-settings: 'wght' 100;
}

.fs-14 {
  font-size: 14px;
}
</style>
