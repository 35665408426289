<template>
  <v-expansion-panel expand-icon="keyboard_arrow_down" collapse-icon="keyboard_arrow_up">
    <template #title>
      <div class="w-100 d-flex align-center ga-3 me-3">
        <v-icon> home_work </v-icon>
        <span class="text-overline font-weight-bold">Use Type</span>
        <v-spacer />
        <div class="text-caption lh-sm d-flex flex-column ga-0 text-end">
          {{ buildingTypeLabel }}
        </div>
      </div>
    </template>

    <template #text>
      <v-row class="mt-2 pb-2 text-right">
        <v-col>
          <v-select
            v-model="selectedBuildingBlock.type"
            label="Building use type"
            :items="options"
            :disabled="locked || user.isReadonly('blocks.type')"
          />

          <v-btn
            v-if="!locked && selectedBuilding.isMultiblock"
            text="Apply to all blocks"
            color="secondary"
            class="align-self-end"
            @click="applyToAllBlocks"
          />
        </v-col>
      </v-row>
    </template>
  </v-expansion-panel>
</template>

<script>
import { useLiveediting } from '@/composables/liveediting'
import User from '@/library/models/User'

export default {
  props: {
    locked: {
      type: Boolean,
      default: false
    },
    user: {
      type: User,
      required: true
    }
  },

  setup() {
    const { selectedBuildingBlock, selectedBuilding } = useLiveediting()

    const options = [
      { title: 'Office', value: 'OFFICE' },
      { title: 'Residential', value: 'RESIDENTIAL' },
      { title: 'School', value: 'EDUCATIONAL' },
      { title: 'Retail', value: 'RETAIL' },
      { title: 'Hospital', value: 'HEALTHCARE' },
      { title: 'Car Park', value: 'MULTICARPARK' }
    ]

    return {
      selectedBuildingBlock,
      selectedBuilding,
      options
    }
  },

  computed: {
    buildingTypeLabel() {
      return this.options.find(({ value }) => value == this.selectedBuildingBlock.type)?.title
    }
  },

  methods: {
    applyToAllBlocks() {
      const selBlock = this.selectedBuildingBlock

      this.selectedBuilding.blocks.forEach((block) => {
        if (block.id != selBlock.id) {
          block.type = selBlock.type
        }
      })
    }
  }
}
</script>
