import { ref } from 'vue'
import { ConfigsManager } from '@/library/settings'
import { Building } from '@/library/models/Building'
import { BuildingBlock } from '@/library/models/BuildingBlock'
import { pMapClass } from '@/library/map'
import { storeToRefs } from 'pinia'
import { useBuildingStore } from '@/stores/building'

const bldgLE = ref(null)
const currentProject = ref(null)
const locked = ref(true)
const selectedBuilding = ref(null)
const selectedBuildingBlock = ref({})
const selectedBuildingBlockID = ref(0)
const selectedBlock = ref({
  storeys: 1,
  name: '',
  color: '',
  id: null,
  storeyHeight: 3,
  data: {}
})

export function useLiveediting() {
  const { buildings } = storeToRefs(useBuildingStore())

  function setCurrentProject(project) {
    currentProject.value = project
  }

  function isDraft(building) {
    return building?.status == 'D'
  }

  function selectDraft() {
    selectBuilding(buildings.value.find(isDraft))
  }

  function findDraft() {
    return buildings.value.find(isDraft)
  }

  function selectFirst() {
    selectBuilding(buildings.value[0])
  }

  function selectBuildingById(id) {
    selectBuilding(buildings.value.find((x) => x.id == id))
  }

  function selectBuilding(building) {
    if (!building) return (selectedBuilding.value = null)

    selectedBuilding.value = new Building(currentProject, building)
    selectedBuilding.value.blocks = selectedBuilding.value.blocks.map(
      (block) => new BuildingBlock(currentProject, structuredClone(block))
    )
  }

  function init() {
    if (findDraft()) selectDraft()
    if (!selectedBuilding.value) selectFirst()
    selectBuildingById(selectedBuilding.value.id)
    if (isDraft(selectedBuilding.value)) locked.value = false
    initMapEvents()
  }

  function initMapEvents() {
    const map = pMapClass.getInstance()._map
    map.on('click', (e) => {
      const layers2query = bldgLE.value.blocks.map((b) => b.mapRefs.layerID)
      const features = map.queryRenderedFeatures(e.point, {
        layers: layers2query
      })
      if (features.length > 0) {
        deselectBlockByID(selectedBlock.value.id)
        selectBlockByID(features[0].id)
      }
    })
  }

  function deselectBlockByID(blockId) {
    selectedBlock.value = bldgLE.value.blocks.find(({ id }) => id == blockId)
    bldgLE.value.resetBlockColor(selectedBlock.value)
  }

  function selectBlockByID(blockId) {
    selectedBlock.value = bldgLE.value.blocks.find(({ id }) => id == blockId)
    selectedBuildingBlock.value = selectedBuilding.value.blocks.find(({ id }) => id == blockId)
    selectedBuildingBlockID.value = blockId
    bldgLE.value.changeBlockColor(selectedBlock.value, 'yellow')
  }

  function getConfig() {
    return {
      num_storey: ConfigsManager.numOfStoreys,
      UG_storeys: ConfigsManager.UGStoreys,
      foundation_type: ConfigsManager.listFoundationTypes(),
      soil_type: ConfigsManager.listSoilTypes(),
      fsr: ConfigsManager.fsr,
      height: ConfigsManager.max_height,
      structure_types: ConfigsManager.listStructureTypes(),
      floorslabs: ConfigsManager.listFloorSlabsTypes(),
      lateral_sys: ConfigsManager.listLateralSysTypes(),
      steel_beams: ConfigsManager.listSteelBeamTypes(),
      heating_sys: ConfigsManager.listHeatingSysTypes(),
      cooling_sys: ConfigsManager.listCoolingSysTypes(),
      lighting_sys: ConfigsManager.listLightingSysTypes(),
      ventilation_sys: ConfigsManager.listVentilationSysTypes(),
      heating_capacity: ConfigsManager.heatingCapacity,
      cooling_capacity: ConfigsManager.coolingCapacity,
      lighting_capacity: ConfigsManager.lightingCapacity,
      ventilation_capacity: ConfigsManager.ventilationCapacity,
      eui: ConfigsManager.eui,
      electricity_share: ConfigsManager.electricity_share,
      grid_decarbon_scenario: ConfigsManager.listGridDecarbScenarios(),
      gridsizeD1: ConfigsManager.gridSizeD1,
      gridsizeD2: ConfigsManager.gridSizeD2,
      wall_types: ConfigsManager.listWallTypes(),
      roof_types: ConfigsManager.listRoofTypes(),
      glazedArea: ConfigsManager.glazedArea,
      skyightArea: ConfigsManager.skylightArea,
      uvalueskylight: ConfigsManager.uValueSkylight
    }
  }

  return {
    init,
    isDraft,
    getConfig,
    selectDraft,
    selectFirst,
    selectBuildingById,
    selectBuilding,
    selectedBuilding,
    selectedBlock,
    selectedBuildingBlock,
    selectedBuildingBlockID,
    deselectBlockByID,
    selectBlockByID,
    locked,
    bldgLE,
    setCurrentProject
  }
}
