<template>
  <v-expansion-panel expand-icon="keyboard_arrow_down" collapse-icon="keyboard_arrow_up">
    <template #title>
      <div class="w-100 d-flex align-center ga-3 me-3">
        <v-icon> deployed_code </v-icon>
        <span class="text-overline font-weight-bold">Geometry</span>
        <v-spacer />
        <div class="text-caption lh-sm d-flex flex-column ga-0 text-end">
          <span> Height: {{ height }} </span>
          <span> Storeys: {{ selectedBuildingBlock.num_storey }} </span>
          <span> ISH: {{ config.displayValue(selectedBuildingBlock.interstorey_height) }} </span>
        </div>
      </div>
    </template>

    <template #text>
      <v-row class="mt-2">
        <v-col>
          <span class="text-subtitle-2 text-grey-darken-2 d-flex align-center ga-2">
            <v-icon v-if="user.isReadonly('blocks.num_storey')" icon="lock" size="small" />
            Number of storeys
          </span>

          <v-slider
            v-if="!num_storey.hidden"
            v-model.number="selectedBuildingBlock.num_storey"
            :min="1"
            :max="15"
            :step="1"
            :disabled="locked || user.isReadonly('blocks.num_storey')"
            color="primary"
            hide-details
            @update:model-value="refreshStoreysBlock(selectedBuildingBlock)"
          >
            <template #append>
              {{ selectedBuildingBlock.num_storey }}
            </template>
          </v-slider>
        </v-col>
        <v-col>
          <span class="text-subtitle-2 text-grey-darken-2 d-flex align-center ga-2">
            <v-icon v-if="user.isReadonly('blocks.interstorey_height')" icon="lock" size="small" />
            Storey height ({{ config.um }})
          </span>

          <v-slider
            v-model.number="selectedBuildingBlock.interstorey_height"
            :min="2.5"
            :max="6"
            :step="config.step"
            :disabled="locked || user.isReadonly('blocks.interstorey_height')"
            color="primary"
            hide-details
            @update:model-value="refreshStoreysBlock(selectedBuildingBlock)"
          >
            <template #append>
              {{ config.displayValue(selectedBuildingBlock.interstorey_height) }}
            </template>
          </v-slider>
        </v-col>
      </v-row>
      <v-row class="text-right pb-2">
        <v-col>
          <v-btn
            v-if="!locked && selectedBuilding.isMultiblock"
            text="Apply to all blocks"
            color="secondary"
            class="align-self-end"
            @click="applyToAllBlocks"
          />
        </v-col>
      </v-row>
    </template>
  </v-expansion-panel>
</template>

<script>
import { useLiveediting } from '@/composables/liveediting'
import User from '@/library/models/User'

export default {
  props: {
    locked: {
      type: Boolean,
      default: false
    },
    appConfigs: {
      type: Object,
      required: true
    },
    user: {
      type: User,
      required: true
    }
  },
  setup() {
    const { selectedBuilding, selectedBuildingBlock, selectedBlock, bldgLE, getConfig } =
      useLiveediting()
    const { num_storey } = getConfig()

    return {
      selectedBuilding,
      selectedBuildingBlock,
      selectedBlock,
      bldgLE,
      num_storey
    }
  },

  computed: {
    config() {
      return this.appConfigs.building.storeyHeight
    },

    height() {
      return this.config.displayValue(
        this.selectedBuildingBlock.num_storey * this.selectedBuildingBlock.interstorey_height
      )
    }
  },

  methods: {
    refreshStoreysBlock(block) {
      this.selectedBlock = this.bldgLE.blocks.find(({ data }) => data.id == block.id)
      this.selectedBlock.storeyHeight = block.interstorey_height
      this.selectedBlock.storeys = block.num_storey
      this.bldgLE.changeBlockStoreys(this.selectedBlock, block.num_storey, true)
    },

    applyToAllBlocks() {
      const selBlock = this.selectedBuildingBlock

      this.selectedBuilding.blocks.forEach((block) => {
        if (block.id != selBlock.id) {
          block.num_storey = selBlock.num_storey
          block.interstorey_height = selBlock.interstorey_height
        }
      })
    }
  }
}
</script>
