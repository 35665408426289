<template>
  <v-overlay v-model="LCArunning" class="d-flex flex-column align-center justify-center" persistent>
    <TransitionGroup :css="false" @before-enter="onBeforeEnter" @enter="onEnter" @leave="onLeave">
      <li v-for="msg in displayMsgs" :key="msg" class="text-white">{{ msg }}</li>
    </TransitionGroup>

    <v-progress-linear
      :model-value="pctComplete"
      color="success"
      bg-color="white"
      bg-opacity="0.5"
      height="20"
      style="width: 400px"
      class="shadow"
      striped
      rounded="3"
    >
      <template #default="{ value }">
        <strong>{{ Math.ceil(value) }}%</strong>
      </template>
    </v-progress-linear>
  </v-overlay>
</template>

<script>
import gsap from 'gsap'
import { useLCA } from '@/composables/lca'

export default {
  setup() {
    const { pctComplete, displayMsgs, LCArunning } = useLCA()

    return {
      pctComplete,
      displayMsgs,
      LCArunning
    }
  },

  methods: {
    onBeforeEnter(el) {
      el.style.opacity = 0
      el.style.height = '0'
    },
    onEnter(el, done) {
      gsap.to(el, {
        opacity: 1,
        height: '30px',
        delay: el.dataset.index * 0.15,
        onComplete: done
      })
    },
    onLeave(el, done) {
      gsap.to(el, {
        opacity: 0,
        height: '0',
        delay: el.dataset.index * 0.15,
        onComplete: done
      })
    }
  }
}
</script>

<style scoped>
li {
  list-style: none;
  filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.6));
  font-weight: 600;
}

ul {
  margin: 10px;
  padding: 0;
}
</style>

<style>
.v-overlay__scrim {
  opacity: 0.7 !important;
}
</style>
