import { defineStore } from 'pinia'
import { ref } from 'vue'
import { pApiClient } from '@/library/api'
import { MESSAGES } from '@/library/messages'
import { useMessenger } from '@/composables/messenger'
import { Building } from '@/library/models/Building'

const { addMessage } = useMessenger()

export const useBuildingStore = defineStore('building', () => {
  const buildings = ref([])
  const project = ref(null)

  async function setProject(newValue) {
    if (project.value?.id === newValue.id) return

    project.value = newValue
    await loadBuildings(true)
  }

  async function loadBuildings(reload = false) {
    if (!project.value) return
    if (buildings.value.length > 0 && !reload) return

    try {
      const { data } = await pApiClient.get(`project/${project.value.id}/buildings`)
      buildings.value = await Promise.all(
        data.map(async (item) => {
          const building = new Building(project.value, item, true)
          await loadMaterials(building)
          building.refreshQTOLateral()
          return building
        })
      )
    } catch (e) {
      addMessage({ text: MESSAGES.BUILDING_LIST_ERROR, color: 'error' })
    }
  }

  async function saveBuilding(building) {
    try {
      const { data } = await pApiClient.put(`building/${building.id}`, building)
      await loadBuildings(true)
      return buildings.value.find((b) => b.id === data.id)
    } catch (e) {
      addMessage({ text: MESSAGES.BUILDING_SAVE_ERROR, color: 'error' })
    }
  }

  async function saveNewBuilding(building) {
    const { data } = await pApiClient.post('building', building)
    await loadBuildings(true)
    return buildings.value.find((b) => b.id === data.id)
  }

  async function deleteBuilding(id) {
    try {
      await pApiClient.delete('building/' + id)
      await loadBuildings(true)
      addMessage({ text: MESSAGES.BUILDING_UNCONFIRM_CONFIRM, color: 'success' })
    } catch (e) {
      addMessage({ text: MESSAGES.BUILDING_UNCONFIRM_ERROR, color: 'error' })
    }
  }

  async function loadMaterials(building) {
    try {
      const { data } = await pApiClient.get(`building/${building.id}/materials`)
      building.materials = data
    } catch (e) {
      addMessage({ text: MESSAGES.BUILDINGMATERIAL_LOAD_ERROR, color: 'error' })
    }
  }

  function newDraftBuilding(blocks) {
    const bldg = Building.newDraftBuilding(this.project, blocks)
    removeDraftBuildings()
    buildings.value.push(bldg)
    return bldg
  }

  function removeDraftBuildings() {
    buildings.value = buildings.value.filter((b) => b.status != 'D')
  }

  function resetBuildings() {
    buildings.value = []
  }

  return {
    setProject,
    loadBuildings,
    saveBuilding,
    saveNewBuilding,
    deleteBuilding,
    removeDraftBuildings,
    newDraftBuilding,
    resetBuildings,
    buildings,
    project
  }
})
