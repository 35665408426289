<template>
  <v-card v-if="buildings.length > 1 || showNewDeesignBtn" max-width="600" color="grey-lighten-4">
    <v-slide-group prev-icon="chevron_left" next-icon="chevron_right" show-arrows>
      <v-slide-group-item v-if="showNewDeesignBtn">
        <v-btn
          class="ma-2"
          size="small"
          :disabled="locked"
          stacked
          prepend-icon="add"
          @click="emit('new-design')"
        >
          New Design
        </v-btn>
      </v-slide-group-item>
      <v-slide-group-item v-for="building in buildings" :key="building.id">
        <v-tooltip :text="building.label" location="top" :disabled="building.label.length <= 15">
          <template #activator="{ props }">
            <v-btn
              v-bind="props"
              class="ma-2"
              size="small"
              :color="selectedBuilding?.id == building.id ? 'blue-grey' : ''"
              :disabled="locked"
              stacked
              prepend-icon="deployed_code"
              @click="selectBuilding(building)"
            >
              {{ truncateLabel(building.label, 15) }}
            </v-btn>
          </template>
        </v-tooltip>
      </v-slide-group-item>
    </v-slide-group>
  </v-card>
</template>

<script setup>
import { useLiveediting } from '@/composables/liveediting'
import { router } from '@/router'
import { useBuildingStore } from '@/stores/building'
import { useLicenceStore } from '@/stores/licence'
import { storeToRefs } from 'pinia'
import { computedAsync } from '@vueuse/core'

defineProps({
  locked: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['new-design'])

const { selectedBuilding, selectBuilding, selectFirst } = useLiveediting()
const { buildings } = storeToRefs(useBuildingStore())
const { checkDesignLimit } = useLicenceStore()

function truncateLabel(text, length) {
  if (text.length > length) {
    return `${text.slice(0, length - 3)}..`
  }
  return text
}

if (!selectedBuilding.value) selectFirst()

const showNewDeesignBtn = computedAsync(async () => {
  const isLiveEditing = router.currentRoute.value.name === 'liveediting'
  const designLimitReached = await checkDesignLimit(buildings.value.length)
  return isLiveEditing && !designLimitReached
})
</script>
