import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import { router } from './router'
import * as Sentry from '@sentry/vue'
import flagsmith from 'flagsmith'
import { useFlagsmith, getEnvironmentID } from './composables/flagsmith'
import mixpanel from 'mixpanel-browser'
import vuetifyConfig from '../vuetify'

const environment =
  process.env.NODE_ENV === 'development'
    ? 'development'
    : window.location.host.startsWith('app')
    ? 'production'
    : 'qa'

const mixpanelToken =
  environment === 'development'
    ? process.env.VUE_APP_MIXPANEL_TOKEN
    : environment === 'qa'
    ? process.env.VUE_APP_MIXPANEL_TOKEN_QA
    : process.env.VUE_APP_MIXPANEL_TOKEN_PROD

mixpanel.init(mixpanelToken, {
  persistence: 'localStorage',
  ignore_dnt: true
})

global.jQuery = global.$ = require('jquery')

const pinia = createPinia()

flagsmith.init({
  environmentID: getEnvironmentID(environment),
  onChange: () => {
    const { setFlag } = useFlagsmith()
    if (flagsmith.hasFeature('registration')) setFlag('registration', true)
  }
})

const app = createApp(App)
app.use(pinia)

Sentry.init({
  app,
  dsn: 'https://48ae6790b40746a8bc722e33070301d0@o4505438719115264.ingest.sentry.io/4505438722260992',
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost', 'preoptima.com'],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    }),
    new Sentry.Replay()
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment,
  enabled: environment !== 'development'
})

app.use(router)
app.use(vuetifyConfig)
app.mount('#app')
