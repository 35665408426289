<template>
  <section v-if="error == 'invalid_token'" class="text-center">
    <p class="material-symbols-outlined icon text-danger">sentiment_worried</p>
    <h1 class="h2 mb-3 font-weight-medium text-danger">Invaid password reset link</h1>

    <p class="text-center lh-lg py-3">
      Your password reset link is invalid. It may have expired or already been used. Please try
      again, or contact the support team if you need further assistance.
    </p>

    <router-link to="/login" class="font-weight-medium">Go back to login</router-link>
  </section>

  <section v-else-if="success" class="text-center">
    <p class="material-symbols-outlined icon text-success">task_alt</p>
    <h1 class="h2 mb-3 font-weight-medium text-success">Password changed</h1>

    <p class="text-center lh-lg py-3">
      Your password has been changed. Please login with your new password.
    </p>

    <router-link to="/login" class="font-weight-medium">Go back to login</router-link>
  </section>
  <form v-else @submit.prevent>
    <h1 class="h2 mb-6 fw-bold">New Password</h1>

    <v-alert
      v-if="error == 'saving_error'"
      type="error"
      icon="error"
      variant="tonal"
      text="There was an error saving your password. Please try again or contact support."
    />

    <v-alert
      v-if="error == 'captcha_error'"
      type="error"
      icon="robot_2"
      variant="tonal"
      text="Please verify that you are not a robot."
    />

    <v-card class="my-6" color="warning" variant="tonal">
      <v-card-text class="text-medium-emphasis text-caption">
        Must contain 8+ characters with at least 1 uppercase letter, 1 lowercase letter, 1 number,
        and 1 special character
      </v-card-text>
    </v-card>

    <v-text-field
      id="current-password"
      v-model="password"
      name="password"
      label="Password"
      placeholder="Password"
      density="default"
      :type="showPassword ? 'text' : 'password'"
      :append-inner-icon="showPassword ? 'visibility' : 'visibility_off'"
      :error-messages="v.password.$errors.map((e) => e.$message)"
      @click:append-inner="showPassword = !showPassword"
    />

    <re-captcha class="my-6" />

    <v-btn
      text="Save Password"
      color="success"
      block
      size="large"
      :loading="loading"
      :disabled="loading"
      @click="savePassword"
    />
  </form>
</template>

<script>
import { pApiClient } from '@/library/api'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, minLength } from '@vuelidate/validators'
import ReCaptcha from './ReCaptcha.vue'

export default {
  name: 'NewPassword',

  components: { ReCaptcha },

  setup() {
    return { v: useVuelidate() }
  },

  data() {
    return {
      password: '',
      showPassword: false,
      loading: false,
      error: null,
      success: false,
      token: null
    }
  },

  validations() {
    return {
      password: {
        required,
        minLength: minLength(8),
        valid: helpers.withMessage('Password is not valid, see requirements above.', (value) => {
          const containsUppercase = /[A-Z]/.test(value)
          const containsLowercase = /[a-z]/.test(value)
          const containsNumber = /[0-9]/.test(value)
          const containsSpecial = /[#?!@$%^&*-]/.test(value)
          return containsUppercase && containsLowercase && containsNumber && containsSpecial
        })
      }
    }
  },
  mounted() {
    this.token = this.$route.params.token
    if (!this.token) {
      this.error = 'invalid_token'
    }
  },
  methods: {
    async savePassword() {
      if (this.loading) return
      const result = await this.v.$validate()
      if (!result) return
      this.loading = true

      // @ts-ignore
      const response = window.grecaptcha.getResponse()
      if (!response) {
        this.loading = false
        this.error = 'captcha_error'
        return
      }
      try {
        await pApiClient.put(
          '/reset-password/' + this.token,
          {
            password: this.password,
            recaptcha: response
          },
          {
            suffix: 's/'
          }
        )
        this.success = true
      } catch (error) {
        if (error.response.status === 404) {
          this.error = 'invalid_token'
        } else if (error.response.status === 400) {
          this.error = 'invalid_password'
        } else {
          this.error = 'saving_error'
        }
        // @ts-ignore
        window.grecaptcha.reset()
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.icon {
  font-size: 4rem;
  font-variation-settings: 'wght' 100;
}

.fs-14 {
  font-size: 14px;
}
</style>
