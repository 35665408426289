<template>
  <v-dialog v-model="show" max-width="500">
    <v-card title="Reset Password">
      <v-card-text>
        <div v-if="requestSent">
          <p class="material-symbols-outlined task-icon text-success w-100 text-center">task_alt</p>
          <p>
            We have sent an email to {{ email }} with a link to reset your password. If you don't
            receive it in a few minutes, please check your spam folder.
          </p>
        </div>

        <div v-else>
          <div v-if="error" class="mb-4">
            <v-alert
              v-if="error === 'email_not_found'"
              type="error"
              icon="error"
              variant="tonal"
              text="No account with that email was found. Please check your email and try again."
            />

            <v-alert
              v-else-if="error === 'recaptcha'"
              type="error"
              icon="robot_2"
              variant="tonal"
              text="Please verify that you are not a robot."
            />

            <v-alert
              v-else
              type="error"
              icon="error"
              variant="tonal"
              text="There was a problem resetting your password. Please try again."
            />
          </div>

          <p>Enter your email address and we will send you a link to reset your password.</p>

          <v-text-field
            id="email"
            v-model="email"
            name="email"
            text="Email"
            label="Email"
            autocomplete="email"
            density="default"
            :error-messages="v.email.$errors.map((e) => e.$message)"
          />

          <ReCaptcha id="reset-password-recaptcha" @widget-id="setWidgetId" />
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn v-if="requestSent" text="Close" color="success" @click="closeModal" />

        <v-btn
          v-else
          text="Reset Password"
          color="success"
          :loading="loading"
          :disabled="loading"
          @click="resetPassword"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import ReCaptcha from './ReCaptcha.vue'
import { pApiClient } from '@/library/api'

export default {
  name: 'PasswordReset',

  components: {
    ReCaptcha
  },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  emits: ['update:modelValue'],

  setup() {
    return {
      v: useVuelidate()
    }
  },

  data() {
    return {
      email: '',
      requestSent: false,
      loading: false,
      error: null,
      widgetId: null
    }
  },

  validations: {
    email: {
      required,
      email
    }
  },

  computed: {
    show: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },

  methods: {
    setWidgetId(id) {
      this.widgetId = id
    },

    async resetPassword() {
      if (this.loading) return

      const result = await this.v.$validate()
      if (!result) return

      // @ts-ignore
      const response = window.grecaptcha.getResponse(this.widgetId)
      if (!response) {
        this.loading = false
        this.error = 'recaptcha'
        return
      }

      this.loading = true

      try {
        await pApiClient.post(
          '/reset-password/',
          { email: this.email, recaptcha: response },
          { suffix: 's/' }
        )
        this.requestSent = true
      } catch (error) {
        if (error.response.status === 404) {
          this.error = 'email_not_found'
        } else {
          this.error = 'other_error'
        }
        // @ts-ignore
        window.grecaptcha.reset(this.widgetId)
      } finally {
        this.loading = false
      }
    },

    closeModal() {
      this.show = false
      this.requestSent = false
      this.email = ''
      this.error = null
      this.v.$reset()
    }
  }
}
</script>

<style scoped>
.task-icon {
  font-size: 4rem;
  font-variation-settings: 'wght' 100;
}
</style>
