<template>
  <v-expansion-panel
    expand-icon="keyboard_arrow_down"
    collapse-icon="keyboard_arrow_up"
    :color="errors.length ? 'red-lighten-5' : ''"
  >
    <template #title>
      <div class="w-100 d-flex align-center ga-3 me-3" :class="{ 'text-error': errors.length }">
        <v-icon>cloud</v-icon>
        <span class="text-overline font-weight-bold">Carbon Dataset</span>
        <v-spacer />
        <div class="text-caption lh-sm d-flex flex-column ga-0 text-end">
          <span>
            {{ currentDataset?.name }}
          </span>
          <div>
            <span>
              {{
                assessmentBoundaries.find(({ value }) => value == selectedBuilding.lca_assesment)
                  ?.label
              }}
            </span>
            <span v-if="currentMethodology === 'PREOPTIMA'"
              >, RSP: {{ selectedBuilding.RSP }}
            </span>
          </div>
        </div>
      </div>
    </template>

    <template #text>
      <v-row class="mt-2">
        <v-col>
          <v-select
            v-model="selectedBuilding.lci_dataset"
            :items="datasetItems"
            label="Carbon Dataset"
            :error-messages="errors"
            :prepend-icon="user.isReadonly('lci_dataset') ? 'lock' : null"
            :disabled="locked || user.isReadonly('lci_dataset')"
            class="w-100"
          />

          <v-slider
            v-model="selectedBuilding.RSP"
            label="Reference Study Period"
            min="25"
            max="125"
            step="1"
            color="primary"
            :disabled="locked || user.isReadonly('RSP') || currentMethodology !== 'PREOPTIMA'"
          >
            <template #append>
              <span>
                {{ selectedBuilding.RSP }}
              </span>
              <span class="ms-1 text-caption">years</span>
            </template>
          </v-slider>

          <div class="d-flex flex-column align-center">
            <span class="text-overline d-flex align-center ga-2">
              <v-icon v-if="user.isReadonly('lca_assesment')" icon="lock" size="small" />
              Assessment boundaries
            </span>

            <v-radio-group v-model="selectedBuilding.lca_assesment" inline>
              <v-radio
                v-for="item in assessmentBoundaries"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                color="primary"
                :disabled="!item.enabled || locked || user.isReadonly('lca_assesment')"
              />
            </v-radio-group>
          </div>
        </v-col>
      </v-row>
    </template>
  </v-expansion-panel>
</template>

<script>
import { useLiveediting } from '@/composables/liveediting'
import User from '@/library/models/User'
import { useDatasetStore } from '@/stores/dataset'
import { storeToRefs } from 'pinia'

export default {
  props: {
    locked: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    user: {
      type: User,
      required: true
    }
  },

  setup() {
    const { fetchDataset } = useDatasetStore()
    const { datasets } = storeToRefs(useDatasetStore())
    const { selectedBuilding } = useLiveediting()

    return {
      datasets,
      fetchDataset,
      selectedBuilding
    }
  },

  computed: {
    datasetItems() {
      const options = this.user.getOptions('lci_dataset')
      const items = this.datasets.map((dataset) => ({
        value: dataset.shortcode,
        title: dataset.name
      }))

      return options.length ? this.user.limitItems(options, items) : items
    },

    errors() {
      return this.error ? ['Please select a dataset'] : []
    },

    currentDataset() {
      return this.datasets.find(({ shortcode }) => shortcode == this.selectedBuilding.lci_dataset)
    },

    currentMethodology() {
      return this.currentDataset?.lca_methodology
    },

    assessmentBoundaries() {
      return [
        {
          label: 'A1-A3',
          value: 'A1_A3',
          enabled: this.currentMethodology == 'PREOPTIMA'
        },
        {
          label: 'A1-A5',
          value: 'A1_A5',
          enabled: this.currentMethodology == 'PREOPTIMA'
        },
        {
          label: 'A-C',
          value: 'A_C',
          enabled: this.currentMethodology == 'PREOPTIMA'
        },
        {
          label: 'A-D',
          value: 'A_D',
          enabled: this.currentMethodology != 'PREOPTIMA'
        }
      ]
    },

    lciDataset() {
      return this.datasets.find(({ shortcode }) => shortcode == this.selectedBuilding.lci_dataset)
    }
  },

  watch: {
    selectedBuilding: {
      handler() {
        if (!this.selectedBuilding) return
        this.selectLCAAssesment()
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    selectLCAAssesment() {
      if (this.lciDataset) {
        const { lca_assesment } = this.selectedBuilding
        if (this.lciDataset.lca_methodology != 'PREOPTIMA' && lca_assesment != 'A_D') {
          this.selectedBuilding.lca_assesment = 'A_D'
          this.selectedBuilding.RSP = 60
        } else if (this.lciDataset.lca_methodology == 'PREOPTIMA' && lca_assesment == 'A_D') {
          this.selectedBuilding.lca_assesment = 'A_C'
        }
      }
    }
  }
}
</script>

<style>
.accordion-header img {
  width: 40px;
}
</style>
