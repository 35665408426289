import { ref } from 'vue'
import { pApiClient } from '@/library/api'

const config = ref(null)

export function useConfig() {
  async function getConfig() {
    try {
      if (config.value) return
      const { data } = await pApiClient.get('appconfigs', { suffix: 's/bldg/' })
      config.value = data
    } catch (err) {
      console.log(err)
    }
  }

  return {
    config,
    getConfig
  }
}
