import { h } from 'vue'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import 'vuetify/styles'

const formProps = {
  variant: 'outlined',
  density: 'compact',
  color: 'primary',
  hideDetails: 'auto',
  class: 'mb-4',
  bgColor: 'surface',
  validateOn: 'submit'
}

const themes = {
  dark: {
    colors: {
      toolbar: '#37474F',
      'surface-1': '#607D8B',
      'surface-2': '#546E7A',
      'surface-3': '#455A64',
      'surface-4': '#37474F',
      'surface-5': '#263238',
      'surface-v-1': '#9E9E9E',
      'surface-v-2': '#757575',
      'surface-v-3': '#616161',
      'surface-v-4': '#424242',
      'surface-v-5': '#212121'
    }
  },
  light: {
    colors: {
      toolbar: '#E0F2F1',
      'surface-1': '#78909C',
      'surface-2': '#90A4AE',
      'surface-3': '#B0BEC5',
      'surface-4': '#CFD8DC',
      'surface-5': '#ECEFF1',
      'surface-v-1': '#BDBDBD',
      'surface-v-2': '#E0E0E0',
      'surface-v-3': '#EEEEEE',
      'surface-v-4': '#F5F5F5',
      'surface-v-5': '#FAFAFA'
    }
  }
}

export default createVuetify({
  components,
  directives,
  defaults: {
    VTextField: formProps,
    VSelect: { ...formProps, menuIcon: 'expand_more' },
    VTextarea: formProps,
    VAutocomplete: formProps,
    VFileInput: formProps
  },
  theme: {
    themes,
    defaultTheme: 'light'
  },
  icons: {
    defaultSet: 'msi',
    aliases,
    sets: {
      msi: {
        component: (props) =>
          h(components.VLigatureIcon, {
            ...props,
            class: 'material-symbols-outlined'
          })
      },
      mdi
    }
  }
})
