// TODO: Remove dependency on GenDesignManager intialization
// eslint-disable-next-line no-unused-vars

import axios from '@/library/axios'

const pApiClient = {
  /**
   * @param {string} url
   * @param {object} [config={}]
   * @returns {Promise}
   */
  async get(url, config = null) {
    return await axios.get(url, config)
  },

  /**
   * @param {string} url
   * @param {object} data
   * @returns {Promise}
   */
  async post(url, data, config = null) {
    return await axios.post(url, data, config)
  },

  /**
   * @param {string} url
   * @param {object} data
   * @returns {Promise}
   */
  async put(url, data, config = null) {
    return await axios.put(url, data, config)
  },

  /**
   * @param {string} url
   * @param {object} data
   * @returns {Promise}
   */
  async patch(url, data, config = null) {
    return await axios.patch(url, data, config)
  },

  /**
   * @param {string} url
   * @returns {Promise}
   */
  async delete(url, config = null) {
    return await axios.delete(url, config)
  }
}

export { pApiClient }
