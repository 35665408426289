<template>
  <v-expansion-panel expand-icon="keyboard_arrow_down" collapse-icon="keyboard_arrow_up">
    <template #title>
      <div class="w-100 d-flex align-center ga-3 me-3">
        <v-icon> energy_savings_leaf </v-icon>
        <span class="text-overline font-weight-bold"> Operational Energy </span>
        <v-spacer />
        <div class="text-caption lh-sm d-flex flex-column ga-0 text-end">
          <span> EUI: {{ config.displayValue(selectedBuilding?.EUI) }} </span>
          <span> Electricity Share: {{ selectedBuilding.electricity_share_perc }}% </span>
          <span> {{ decarbScenarioLabel }} </span>
        </div>
      </div>
    </template>

    <template #text>
      <v-row class="mt-2">
        <v-col>
          <div class="text-subtitle-2 text-grey-darken-2 d-flex align-center ga-2">
            <v-icon v-if="user.isReadonly('EUI')" icon="lock" size="small" />
            EUI ({{ config.um }})
          </div>
          <v-slider
            v-model.number="selectedBuilding.EUI"
            :min="0"
            :max="500"
            :step="config.step"
            :disabled="locked || user.isReadonly('EUI')"
            color="primary"
            hide-details
            class="mb-4"
            @change="refreshStoreysBlock(selectedBuildingBlock)"
          >
            <template #append>
              {{ config.displayValue(selectedBuilding.EUI) }}
            </template>
          </v-slider>
        </v-col>

        <v-col>
          <div class="text-subtitle-2 text-grey-darken-2 d-flex align-center ga-2">
            <v-icon v-if="user.isReadonly('electricity_share_perc')" icon="lock" size="small" />
            Electricity share (%)
          </div>
          <v-slider
            v-model.number="selectedBuilding.electricity_share_perc"
            :min="0"
            :max="100"
            :step="1"
            :disabled="locked || user.isReadonly('electricity_share_perc')"
            color="primary"
            hide-details
            class="mb-4"
            @change="refreshStoreysBlock(selectedBuildingBlock)"
          >
            <template #append>
              {{ selectedBuilding.electricity_share_perc }}
            </template>
          </v-slider>
        </v-col>
      </v-row>

      <v-row>
        <v-col col="6">
          <v-select
            id="grid_decarbon_scenario"
            v-model="selectedBuilding.grid_decarbon_scenario"
            :label="isRICS ? 'Grid decarbonisation scenario' : 'Grid scenario'"
            :items="isRICS ? decarbScenarios : allScenarios"
            :prepend-inner-icon="user.isReadonly('grid_decarbon_scenario') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('grid_decarbon_scenario')"
            hide-details
          />
        </v-col>

        <v-col v-if="selectedBuilding.grid_decarbon_scenario === 'CUSTOM'" col="6">
          <div class="text-subtitle-2 text-grey-darken-2 d-flex align-center ga-2">
            <v-icon v-if="user.isReadonly('electricity_share_perc')" icon="lock" size="small" />
            Grid Emissions Factor (kgCO2e/kWh)
          </div>
          <v-slider
            v-model.number="selectedBuilding.grid_coefficient_decarb"
            :min="0"
            :max="1.5"
            :step="0.01"
            :disabled="locked || user.isReadonly('grid_coefficient_decarb')"
            color="primary"
            hide-details
            class="mb-4"
            @change="refreshStoreysBlock(selectedBuildingBlock)"
          >
            <template #append>
              {{ selectedBuilding.grid_coefficient_decarb.toFixed(2) }}
            </template>
          </v-slider>
        </v-col>
      </v-row>

      <v-row v-if="isRICS">
        <v-col col="6">
          <v-select
            id="grid_nondecarb_scenario"
            v-model="selectedBuilding.grid_nondecarb_scenario"
            label="Grid non decarbonisation scenario"
            :items="nonDecarbScenarios"
            :prepend-inner-icon="user.isReadonly('grid_nondecarb_scenario') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('grid_nondecarb_scenario')"
            hide-details
          />
        </v-col>
        <v-col v-if="selectedBuilding.grid_nondecarb_scenario === 'CUSTOM'" col="6">
          <div class="text-subtitle-2 text-grey-darken-2 d-flex align-center ga-2">
            <v-icon v-if="user.isReadonly('electricity_share_perc')" icon="lock" size="small" />
            Grid Emissions Factor (kgCO2e/kWh)
          </div>
          <v-slider
            v-model.number="selectedBuilding.grid_coefficient_nondecarb"
            :min="0"
            :max="1.5"
            :step="0.01"
            :disabled="locked || user.isReadonly('grid_coefficient_nondecarb')"
            color="primary"
            hide-details
            class="mb-4"
            @change="refreshStoreysBlock(selectedBuildingBlock)"
          >
            <template #append>
              {{ selectedBuilding.grid_coefficient_nondecarb.toFixed(2) }}
            </template>
          </v-slider>
        </v-col>
      </v-row>
    </template>
  </v-expansion-panel>
</template>

<script>
import { useLiveediting } from '@/composables/liveediting'
import { operationalEnergy } from '@/library/appConfigs'
import { useDatasetStore } from '@/stores/dataset'
import { storeToRefs } from 'pinia'

export default {
  props: {
    locked: {
      type: Boolean,
      default: false
    },
    appConfigs: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  setup() {
    const { datasets } = storeToRefs(useDatasetStore())
    const { selectedBuilding } = useLiveediting()

    return {
      selectedBuilding,
      operationalEnergy,
      datasets
    }
  },

  computed: {
    currentDataset() {
      return this.datasets.find(({ shortcode }) => shortcode === this.selectedBuilding.lci_dataset)
    },

    isRICS() {
      return this.currentDataset?.lca_methodology === 'RICSv2'
    },

    config() {
      return this.appConfigs.building.eui
    },

    decarbScenarioLabel() {
      const { grid_decarbon_scenario: scenario } = this.selectedBuilding
      return scenario
        ? this.operationalEnergy.decarbScenarios.find(({ value }) => value == scenario)?.title
        : ''
    },

    allScenarios() {
      return [
        ...this.operationalEnergy.decarbScenarios,
        ...this.operationalEnergy.nonDecarbScenarios,
        {
          value: 'CUSTOM',
          title: 'Custom',
          disabled: false
        }
      ]
    },

    decarbScenarios() {
      return [
        ...this.operationalEnergy.decarbScenarios,
        {
          value: 'CUSTOM',
          title: 'Custom',
          disabled: false
        }
      ]
    },

    nonDecarbScenarios() {
      return [
        ...this.operationalEnergy.nonDecarbScenarios,
        {
          value: 'CUSTOM',
          title: 'Custom',
          disabled: false
        }
      ]
    }
  },

  watch: {
    isRICS(value) {
      if (value) {
        const scenario = this.decarbScenarios.find(
          ({ value }) => value === this.selectedBuilding.grid_decarbon_scenario
        )
        if (!scenario) this.selectedBuilding.grid_decarbon_scenario = this.decarbScenarios[0].value
      }
    }
  }
}
</script>
