<template>
  <v-expansion-panel expand-icon="keyboard_arrow_down" collapse-icon="keyboard_arrow_up">
    <template #title>
      <div class="w-100 d-flex align-center ga-3 me-3">
        <v-icon> texture </v-icon>
        <span class="text-overline font-weight-bold"> Finishes </span>
        <v-spacer />
        <div class="text-caption lh-sm d-flex flex-column ga-0 text-end">
          <span> Floor: {{ selectedFlooringAssembly?.name || 'None' }} </span>
          <span> Ceiling: {{ selectedCeilingAssembly?.name || 'None' }} </span>
        </div>
      </div>
    </template>

    <template #text>
      <v-row class="mt-2 pb-2">
        <v-col class="text-right">
          <v-select
            id="floor_finishes"
            v-model="selectedBuildingBlock.floor_finish_system"
            :items="flooringAssemblies"
            :prepend-inner-icon="user.isReadonly('blocks.floor_finish_system') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('blocks.floor_finish_system')"
            label="Floor Finishes"
            clearable
          />
          <v-select
            id="ceiling_finishes"
            v-model="selectedBuildingBlock.ceiling_finish_system"
            :items="ceilingAssemblies"
            :prepend-inner-icon="user.isReadonly('blocks.ceiling_finish_system') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('blocks.ceiling_finish_system')"
            label="Ceiling Finishes"
            class="my-6"
            clearable
          />
          <v-btn
            v-if="!locked && selectedBuilding.isMultiblock"
            text="Apply to all blocks"
            color="secondary"
            class="align-self-end"
            @click="applyToAll"
          />
        </v-col>
      </v-row>
    </template>
  </v-expansion-panel>
</template>

<script>
import { storeToRefs } from 'pinia'
import { useLiveediting } from '@/composables/liveediting'
import { useAssemblyStore } from '@/stores/assembly'

export default {
  name: 'FinishesItem',
  props: {
    locked: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      required: true
    }
  },

  setup() {
    const { assemblies } = storeToRefs(useAssemblyStore())
    const { filteredAssemblies } = useAssemblyStore()
    const { selectedBuildingBlock, selectedBuilding } = useLiveediting()

    return {
      assemblies,
      filteredAssemblies,
      selectedBuildingBlock,
      selectedBuilding
    }
  },

  computed: {
    formattedAssemblies() {
      const assemblies = this.filteredAssemblies(this.selectedBuildingBlock.is_retrofit)
      return assemblies.map(({ shortcode, name, type }) => {
        return {
          value: shortcode,
          title: name,
          type
        }
      })
    },

    ceilingAssemblies() {
      const assemblies = this.formattedAssemblies.filter(({ type }) => type === 'CEILINGFINISH')
      const options = this.user.getOptions('blocks.ceiling_finish_system')
      return options.length ? this.user.limitItems(options, assemblies) : assemblies
    },

    flooringAssemblies() {
      const assemblies = this.formattedAssemblies.filter(({ type }) => type === 'FLOORFINISH')
      const options = this.user.getOptions('blocks.floor_finish_system')
      return options.length ? this.user.limitItems(options, assemblies) : assemblies
    },

    selectedFlooringAssembly() {
      return this.assemblies.find(
        ({ shortcode }) => shortcode == this.selectedBuildingBlock.floor_finish_system
      )
    },

    selectedCeilingAssembly() {
      return this.assemblies.find(
        ({ shortcode }) => shortcode == this.selectedBuildingBlock.ceiling_finish_system
      )
    }
  },

  methods: {
    applyToAll() {
      const selBlock = this.selectedBuildingBlock

      this.selectedBuilding.blocks.forEach((block) => {
        if (block.id != selBlock.id) {
          block.floor_finish_system = selBlock.floor_finish_system
          block.ceiling_finish_system = selBlock.ceiling_finish_system
        }
      })
    }
  }
}
</script>
