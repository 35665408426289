<template>
  <v-app-bar :elevation="2" color="toolbar" class="px-2" density="comfortable">
    <template #default>
      <div class="d-flex align-center justify-space-between w-100">
        <router-link to="/" class="d-flex align-center text-decoration-none">
          <img src="img/preoptima_logo.png" class="d-lg-none" height="40" />
          <img
            src="img/preoptima_full_logo_noborder.png"
            class="d-none d-lg-inline-block"
            height="40"
            style="filter: contrast(1.2) brightness(1.1)"
          />
          <div
            class="text-surface-1 font-weight-bold ms-3 text-overline d-none d-lg-inline-block"
            style="transform: translateY(-10px)"
          >
            CONCEPT
          </div>
        </router-link>

        <v-spacer />

        <div class="position-right d-flex">
          <v-menu v-if="showDownloadLinks">
            <template #activator="{ props }">
              <v-btn
                height="60"
                prepend-icon="download"
                v-bind="props"
                stacked
                class="d-grid d-lg-none"
              />
            </template>
            <v-list>
              <v-list-item title="PDF" @click="exportPDF" />
              <v-list-item title="OBJ" @click="exportOBJ" />
              <v-list-item title="XLS" @click="exportXLS" />
            </v-list>
          </v-menu>

          <div v-if="showDownloadLinks" class="d-none d-md-block">
            <v-btn height="60" prepend-icon="download" stacked @click="exportPDF">
              <span class="fs-sm font-weight-medium"> PDF </span>
            </v-btn>
            <v-btn height="60" prepend-icon="download" stacked @click="exportOBJ">
              <span class="fs-sm font-weight-medium"> OBJ </span>
            </v-btn>
            <v-btn height="60" prepend-icon="download" stacked @click="exportXLS">
              <span class="fs-sm font-weight-medium"> XLS </span>
            </v-btn>
          </div>
          <v-divider v-if="showDownloadLinks" vertical class="mx-3 d-none d-sm-block" />

          <v-btn height="60" to="/" prepend-icon="folder_open" stacked>
            <span class="fs-sm font-weight-medium d-none d-md-inline"> Projects </span>
          </v-btn>

          <v-btn
            height="60"
            data-testid="materials-link"
            prepend-icon="pallet"
            stacked
            @click="showMaterialsView = true"
          >
            <span class="fs-sm font-weight-medium d-none d-md-inline"> Materials </span>
          </v-btn>

          <v-btn
            height="60"
            data-testid="materials-link"
            prepend-icon="dataset"
            stacked
            @click="showDatasetsView = true"
          >
            <span class="fs-sm font-weight-medium d-none d-md-inline"> Datasets </span>
          </v-btn>

          <v-btn
            height="60"
            data-testid="assemblies-link"
            prepend-icon="stacks"
            stacked
            @click="showAssembliesView = true"
          >
            <span class="fs-sm font-weight-medium d-none d-md-inline"> Assemblies </span>
          </v-btn>

          <v-menu>
            <template #activator="{ props }">
              <v-btn height="60" prepend-icon="account_circle" stacked v-bind="props">
                <span class="fs-sm font-weight-medium d-none d-md-inline"> Account </span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title>{{ user.fullName }}</v-list-item-title>
              </v-list-item>
              <v-list-item to="/account">
                <v-list-item-title>Settings</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="showAdminLink" :href="adminLink" target="_blank">
                <v-list-item-title>Preoptima Admin</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="user.is_admin" to="/manage-users">
                <v-list-item-title>Manage Users</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="user.is_admin && licence?.is_subscription" :to="stripeUrl">
                <v-list-item-title>Subscription</v-list-item-title>
              </v-list-item>
              <v-list-item
                href="https://preoptima.notion.site/Preoptima-Knowledge-Hub-d3a4137110c1428dba4c7fc3bc51c1fe"
                target="_blank"
                rel="noopener"
              >
                <v-list-item-title>Documentation</v-list-item-title>
              </v-list-item>
              <v-list-item @click="logout">
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </template>
  </v-app-bar>
  <PDFViewer v-if="pdfURL" :url="pdfURL" @close="pdfURL = null" />

  <AssemblyList v-model="showAssembliesView" />
  <MaterialsList v-model="showMaterialsView" />
  <DatasetList v-model="showDatasetsView" />
</template>

<script>
import { router } from '@/router'
import { baseURL } from '@/library/axios'
import PDFViewer from './PDFViewer.vue'
import { useLiveediting } from '@/composables/liveediting'
import axios from '@/library/axios'
import { useUser } from '@/composables/user'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import mixpanel from 'mixpanel-browser'
import { useLicenceStore } from '@/stores/licence'
import { storeToRefs } from 'pinia'
import { useProjectStore } from '@/stores/project'
import { useProgress } from '@/composables/progress'
import AssemblyList from '@/views/materials/AssemblyList.vue'
import MaterialsList from '@/views/materials/MaterialsList.vue'
import DatasetList from '@/views/materials/DatasetList.vue'
import { pApiClient } from '@/library/api'
import { useMessenger } from '@/composables/messenger'

export default {
  name: 'TopToolbar',

  components: {
    PDFViewer,
    AssemblyList,
    MaterialsList,
    DatasetList
  },

  setup() {
    const { isNewProject, currentProject } = storeToRefs(useProjectStore())
    const { fetchLicence } = useLicenceStore()
    const { licence } = storeToRefs(useLicenceStore())
    const { selectedBuilding } = useLiveediting()
    const { user, getUser } = useUser()
    const pdfURL = ref(null)
    const { setProgress, resetProgress } = useProgress()
    const { addMessage } = useMessenger()

    const route = useRoute()

    const showAssembliesView = ref(false)
    const showMaterialsView = ref(false)
    const showDatasetsView = ref(false)

    getUser()
    fetchLicence()

    return {
      isNewProject,
      selectedBuilding,
      currentProject,
      user,
      licence,
      pdfURL,
      route,
      setProgress,
      resetProgress,
      showAssembliesView,
      showMaterialsView,
      showDatasetsView,
      addMessage
    }
  },

  computed: {
    stripeUrl() {
      const test = 'https://billing.stripe.com/p/login/test_5kA29o3vJ8ha2645kk'
      const live = 'https://billing.stripe.com/p/login/eVa29D0Dhepk0lWcMM'
      const params = `?prefilled_email=${this.user.email}`

      if (window.location.host.startsWith('app')) return live + params
      return test + params
    },

    adminLink() {
      return `${baseURL}admin`
    },

    showAdminLink() {
      return this.user.email.endsWith('@preoptima.com')
    },

    showDownloadLinks() {
      const { name } = this.route
      return (
        this.selectedBuilding &&
        this.selectedBuilding.status !== 'D' &&
        ['liveediting', 'data', 'detailedresults'].includes(name)
      )
    }
  },

  watch: {
    $route: {
      immediate: true,
      handler({ query }) {
        if (!query.dialog) return
        if (query.dialog === 'assemblies') this.showAssembliesView = true
        if (query.dialog === 'materials') this.showMaterialsView = true
        if (query.dialog === 'datasets') this.showDatasetsView = true
        router.replace({ query: null })
      }
    }
  },

  methods: {
    async exportPDF() {
      this.setProgress(10, 'Preparing file...<br />This may take up to 40 seconds.', 90)
      try {
        const { data } = await pApiClient.get(`/bldg/report/${this.selectedBuilding.id}/`, {
          suffix: 's/'
        })
        this.pdfURL = data.pdf
      } catch (err) {
        this.addMessage({ text: err.message, color: 'error' })
      } finally {
        this.resetProgress()
      }
    },

    async exportOBJ() {
      if (!this.selectedBuilding) return
      const url = `${baseURL}s/bldg/obj/${this.selectedBuilding.id}`
      const label = `${this.currentProject.name}_${this.selectedBuilding.label}.obj`
      await this.downloadFile(url, label, 'obj')
    },

    async exportXLS() {
      if (!this.selectedBuilding) return
      const url = `${baseURL}s/bldg/xls/${this.selectedBuilding.id}`
      const label = `${this.currentProject.name}_${this.selectedBuilding.label}.xlsx`
      await this.downloadFile(url, label, 'vnd.openxmlformats-officedocument.spreadsheetml.sheet')
    },

    async downloadFile(url, label, filetype) {
      this.setProgress(10, 'Preparing file...')
      try {
        const { data } = await axios.get(url, { responseType: 'blob' })
        this.setProgress(80)
        const blob = new Blob([data], { type: `application/${filetype}` })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = label
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (err) {
        console.error(err)
      } finally {
        this.resetProgress()
      }
    },

    logout() {
      localStorage.removeItem('token')
      mixpanel.reset()
      router.push('/login')
    }
  }
}
</script>
