import $ from 'jquery'

const PROGRESS_TYPES = {
  TASK: 'task',
  STARTUP: 'startup',
  BLANK: 'blank'
}

const Progress = {
  val: 0,
  autoInterval: null,

  TYPES: PROGRESS_TYPES,

  setValue(p, msg = null) {
    this.val = p
    $('#waiting-bg .progress-bar').width(p + '%')
    $('#waiting-bg .progress-bar').html(p + '%')
    if (msg != null) {
      this.setMsg(msg)
    }
  },

  setMsg(msg) {
    $('#waiting-bg span').html(msg)
  },

  setAuto(Vmax) {
    if (this.val > Vmax) {
      clearInterval(this.autoInterval)
      this.autoInterval = null
      return
    }

    this.setValue(this.val + 1)
  },

  showStartup(p, msg, T) {
    this._show(p, this.TYPES.STARTUP, msg, T)
  },

  showTask(p, msg, T) {
    this._show(p, this.TYPES.TASK, msg, T)
  },

  showBlank(p, msg, T) {
    this._show(p, this.TYPES.BLANK, msg, T)
  },

  _show(p, type, msg, T) {
    this.setValue(p, msg)
    $('#waiting-bg').removeClass()
    $('#waiting-bg').addClass('waiting-bg-' + type)
    $('#waiting-bg').show()

    if (T != null) {
      let Tms = T
      let Vmax = 94
      if (typeof T == typeof {}) {
        Tms = T.interval
        Vmax = T.maxValue
      }

      let self = this
      this.autoInterval = setInterval(() => {
        self.setAuto(Vmax)
      }, Tms)
    }
  },

  hide() {
    $('#waiting-bg .progress-bar').width('100%')
    $('#waiting-bg .progress-bar').html('100%')
    $('#waiting-bg').hide()
    clearInterval(this.autoInterval)
    this.autoInterval = null
  }
}

function roundTo(v, decimals = 4) {
  let scaleFactor = Math.pow(10, decimals)
  //console.log(scaleFactor, v, Math.round(v * scaleFactor) / scaleFactor);
  return Math.round(v * scaleFactor) / scaleFactor
}

const MEASSYS = {
  IMPERIAL: 'I',
  METRIC: 'M'
}

const UMs = {
  ft: {
    sys: MEASSYS.IMPERIAL,
    code: 'ft',
    label: 'ft',
    precision: 0
  },
  m: {
    sys: MEASSYS.METRIC,
    code: 'm',
    label: 'm',
    precision: 0
  },
  ft2: {
    sys: MEASSYS.IMPERIAL,
    code: 'ft2',
    label: 'ft<sup>2</sup>',
    precision: 0
  },
  m2: {
    sys: MEASSYS.METRIC,
    code: 'm2',
    label: 'm²',
    precision: 0
  },
  amt_m2: {
    sys: MEASSYS.METRIC,
    code: 'amt_m2',
    label: 'amt/m<sup>2</sup>',
    precision: 0
  },
  amt_ft2: {
    sys: MEASSYS.IMPERIAL,
    code: 'amt_ft2',
    label: 'amt/ft<sup>2</sup>',
    precision: 0
  },

  Uv: {
    sys: MEASSYS.METRIC,
    code: 'Uv',
    label: 'W/(m<sup>2</sup>K)',
    precision: 2
  },
  Rv: {
    sys: MEASSYS.IMPERIAL,
    code: 'Rv',
    label: 'ft<sup>2</sup>Fh/BTU',
    precision: 0
  },
  Uv_w: {
    sys: MEASSYS.METRIC,
    code: 'Uv_w',
    label: 'W/(m<sup>2</sup>K)',
    precision: 1
  },
  Uv_w_I: {
    sys: MEASSYS.IMPERIAL,
    code: 'Uv_w_I',
    label: 'BTU/ft<sup>2</sup>Fh',
    precision: 2
  },
  ms: {
    sys: MEASSYS.METRIC,
    code: 'ms',
    label: 'm/s',
    precision: 0
  },
  mph: {
    sys: MEASSYS.IMPERIAL,
    code: 'mph',
    label: 'mph',
    precision: 0
  },

  length(sys) {
    return sys == MEASSYS.METRIC ? this.m : this.ft
  },
  speed(sys) {
    return sys == MEASSYS.METRIC ? this.ms : this.mph
  },
  area(sys) {
    return sys == MEASSYS.METRIC ? this.m2 : this.ft2
  },
  thermal(sys) {
    return sys == MEASSYS.METRIC ? this.Uv : this.Rv
  },
  thermalW(sys) {
    return sys == MEASSYS.METRIC ? this.Uv_w : this.Uv_w_I
  }
}

const CONV_FACTORS = [
  {
    from: UMs.m2.code,
    to: UMs.ft2.code,
    c: (v) => v * (3.28084 * 3.28084),
    rev: (v) => v / (3.28084 * 3.28084)
  },
  {
    from: UMs.m.code,
    to: UMs.ft.code,
    c: (v) => v * 3.28084,
    rev: (v) => v / 3.28084
  },
  {
    from: UMs.Uv.code,
    to: UMs.Rv.code,
    //e.g. for U-v = 0.16 [W/(m2K)] ==> RSI = 6.25 [(m2K)/W] ==> R-value = 6.25 * 5.678 = 35.5 ft2·°F·h/BTU
    c: (u) => 5.678 / u,
    rev: (r) => 5.678 / r
  },
  {
    from: UMs.Uv_w.code,
    to: UMs.Uv_w_I.code,
    //e.g. for U-v = 0.16 [W/(m2K)] ==> RSI = 6.25 [(m2K)/W] ==> R-value = 6.25 * 5.678 = 35.5 ft2·°F·h/BTU
    c: (u) => u / 5.678,
    rev: (r) => 5.678 * r
  },
  {
    from: UMs.amt_m2.code,
    to: UMs.amt_ft2.code,
    c: (v) => v * 3.28084,
    rev: (v) => v / 3.28084
  },
  {
    from: UMs.ms.code,
    to: UMs.mph.code,
    c: (v) => v * 2.23694,
    rev: (v) => v / 2.23694
  }
]

const UOMConv = {
  MEASSYS: MEASSYS,
  UMs: UMs,
  BASE_SOM: MEASSYS.METRIC,

  cFavct() {
    return CONV_FACTORS
  },

  getCFactor(fromUM) {
    let m = CONV_FACTORS.find((x) => [x.from, x.to].includes(fromUM))

    if (m == undefined) throw `Missing UoM conversion factor!!! (${fromUM})`

    if (m.to == fromUM) {
      m = {
        from: m.to,
        to: m.from,
        c: m.rev
      }
    }

    m.precision = UMs[m.to].precision
    return m
  },

  switchSys(v, fromUM, rounding = true) {
    let m = this.getCFactor(fromUM)

    let res = {
      code: m.to,
      label: UMs[m.to].label,
      value: 0
    }

    //let r = roundTo;
    //rounding = false;
    let precision = typeof rounding == typeof 1 ? rounding : m.precision
    let rounded_conv =
      rounding !== false
        ? (y) => {
            return roundTo(m.c(y), precision)
          }
        : m.c
    if (Array.isArray(v)) {
      res.value = v.map((x) => rounded_conv(x)).sort((a, b) => a - b)
    } else {
      res.value = rounded_conv(v)
    }

    return res
  },

  conv(v, fromUM, toSys, rounding = false) {
    let res = {
      code: fromUM,
      label: UMs[fromUM].label,
      value: v
    }

    if (UMs[fromUM].sys == toSys) {
      if (rounding) {
        let precision = typeof rounding == typeof 1 ? rounding : UMs[fromUM].precision
        res.value = roundTo(res.value, precision)
      }
      return res
    }

    res = this.switchSys(v, fromUM, rounding)
    return res
  },

  convLength(l, fromSys, toSys, rounding = false) {
    return this.conv(l, UMs.length(fromSys).code, toSys, rounding).value
  },

  convThermal(l, fromSys, toSys, rounding = false) {
    return this.conv(l, UMs.thermal(fromSys).code, toSys, rounding).value
  },

  convArea(l, fromSys, toSys, rounding = false) {
    return this.conv(l, UMs.area(fromSys).code, toSys, rounding).value
  },

  convLengthFromBase(l, toSys, rounding = 1) {
    return this.conv(l, UMs.length(this.BASE_SOM).code, toSys, rounding).value
  },

  convThermalFromBase(l, toSys, rounding = 2) {
    return this.conv(l, UMs.thermal(this.BASE_SOM).code, toSys, rounding).value
  },

  convThermalWFromBase(l, toSys, rounding = 1) {
    return this.conv(l, UMs.thermalW(this.BASE_SOM).code, toSys, rounding).value
  },

  convAreaFromBase(l, toSys, rounding = false, onlyValue = true) {
    let res = this.conv(l, UMs.area(this.BASE_SOM).code, toSys, rounding)

    return onlyValue ? res.value : res
  },

  convSpeedFromBase(l, toSys, rounding = 1) {
    return this.conv(l, UMs.speed(this.BASE_SOM).code, toSys, rounding).value
  },

  convLengthToBase(l, fromSys, rounding = false) {
    return this.conv(l, UMs.length(fromSys).code, this.BASE_SOM, rounding).value
  },

  convThermalToBase(l, fromSys, rounding = false) {
    return this.conv(l, UMs.thermal(fromSys).code, this.BASE_SOM, rounding).value
  },

  convThermalWToBase(l, fromSys, rounding = false) {
    return this.conv(l, UMs.thermalW(fromSys).code, this.BASE_SOM, rounding).value
  },

  convSpeedToBase(l, fromSys, rounding = false) {
    return this.conv(l, UMs.speed(fromSys).code, this.BASE_SOM, rounding).value
  },

  convAreaToBase(l, fromSys, rounding = false) {
    return this.conv(l, UMs.area(fromSys).code, this.BASE_SOM, rounding).value
  },

  convToBase(v, fromUM, rounding = false) {
    return this.conv(v, fromUM, this.BASE_SOM, rounding).value
  },

  convToImperial(v, fromUM, rounding = false) {
    return this.conv(v, fromUM, this.MEASSYS.IMPERIAL, rounding).value
  }
}

export { Progress, UOMConv, roundTo }
