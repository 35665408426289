import { ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
export { MESSAGES } from '@/library/messages'

const messages = ref([])

export function useMessenger() {
  function removeClosedMessages() {
    messages.value = messages.value.filter((m) => m.show)
  }

  function addMessage(message) {
    removeClosedMessages()
    if (messages.value.length >= 5) messages.value.pop()
    messages.value.unshift(new Message(message))
  }

  function removeMessage(index) {
    messages.value[index].show = false
    removeClosedMessages()
  }

  return {
    addMessage,
    removeMessage,
    messages
  }
}

class Message {
  constructor(details) {
    this.id = uuidv4()
    this.show = true
    this.title = details.title || ''
    this.text = details.text || ''
    this.action = details.action || { to: '', text: '', callback: null }
    this.timeout = details.timeout || 6000
    this.color = details.color || ''
  }
}
