<template>
  <v-expansion-panel expand-icon="keyboard_arrow_down" collapse-icon="keyboard_arrow_up">
    <template #title>
      <div class="w-100 d-flex align-center ga-3 me-3">
        <v-icon> heat_pump </v-icon>
        <span class="text-overline font-weight-bold"> MEP </span>
        <v-spacer />
        <div class="text-caption lh-sm d-flex flex-column ga-0 text-end">
          <span> {{ heatingSysLabel }} </span>
          <span> {{ coolingSysLabel }} </span>
        </div>
      </div>
    </template>

    <template #text>
      <h3 class="text-body-1 mt-2 font-weight-bold text-grey-darken-3">
        Heating, Ventilation and Cooling (HVAC)
      </h3>

      <v-row class="mt-2">
        <v-col>
          <v-select
            v-model="selectedBuilding.heating_sys"
            :items="heatingSystems"
            label="Heating System"
            data-testid="heating-system"
            :prepend-inner-icon="user.isReadonly('heating_sys') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('heating_sys')"
          />
        </v-col>
        <v-col>
          <v-select
            v-model="selectedBuilding.cooling_sys"
            :items="coolingSystems"
            label="Cooling System"
            data-testid="heating-system"
            :prepend-inner-icon="user.isReadonly('cooling_sys') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('cooling_sys')"
          />

          <template v-if="selectedBuilding.cooling_sys === 'CHILLER'">
            <div class="text-subtitle-2 text-grey-darken-2 d-flex align-center ga-2">
              <v-icon v-if="user.isReadonly('cooling_capacity')" icon="lock" size="small" />
              Capacity [k/W]
            </div>
            <v-slider
              v-model.number="selectedBuilding.cooling_capacity"
              :min="50"
              :max="500"
              :step="1"
              :disabled="locked"
              color="primary"
            >
              <template #append>
                {{ selectedBuilding.cooling_capacity }}
              </template>
            </v-slider>
          </template>
        </v-col>
      </v-row>

      <h3 class="text-body-2 text-grey-darken-1">Ventilation System Emissions (kgCO2e/m2)</h3>
      <v-row class="mt-1">
        <v-col>
          <v-text-field
            v-model.number="selectedBuilding.ventilation_A1A3_kgCO2m2"
            label="A1A3"
            type="number"
            :prepend-inner-icon="user.isReadonly('ventilation_A1A3_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('ventilation_A1A3_kgCO2m2')"
          />
          <v-text-field
            v-model.number="selectedBuilding.ventilation_C3_kgCO2m2"
            label="C3"
            type="number"
            :prepend-inner-icon="user.isReadonly('ventilation_C3_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('ventilation_C3_kgCO2m2')"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="selectedBuilding.ventilation_A4_kgCO2m2"
            label="A4"
            type="number"
            :prepend-inner-icon="user.isReadonly('ventilation_A4_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('ventilation_A4_kgCO2m2')"
          />
          <v-text-field
            v-model.number="selectedBuilding.ventilation_C4_kgCO2m2"
            label="C4"
            type="number"
            :prepend-inner-icon="user.isReadonly('ventilation_C4_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('ventilation_C4_kgCO2m2')"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="selectedBuilding.ventilation_A5_3_kgCO2m2"
            label="A5.3"
            type="number"
            :prepend-inner-icon="user.isReadonly('ventilation_A5_3_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('ventilation_A5_3_kgCO2m2')"
          />
          <v-text-field
            v-model.number="selectedBuilding.ventilation_D1_kgCO2m2"
            label="D1"
            type="number"
            :prepend-inner-icon="user.isReadonly('ventilation_D1_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('ventilation_D1_kgCO2m2')"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="selectedBuilding.ventilation_C2_kgCO2m2"
            label="C2"
            type="number"
            :prepend-inner-icon="user.isReadonly('ventilation_C2_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('ventilation_C2_kgCO2m2')"
          />
        </v-col>
      </v-row>
      <h3 class="text-body-1 mt-2 font-weight-bold text-grey-darken-3">Electrical</h3>
      <h3 class="text-body-2 text-grey-darken-1">Electrical Installations (kgCO2e/m2)</h3>
      <v-row class="mt-1">
        <v-col>
          <v-text-field
            v-model.number="selectedBuilding.electrical_A1A3_kgCO2m2"
            label="A1A3"
            type="number"
            :prepend-inner-icon="user.isReadonly('electrical_A1A3_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('electrical_A1A3_kgCO2m2')"
          />
          <v-text-field
            v-model.number="selectedBuilding.electrical_C3_kgCO2m2"
            label="C3"
            type="number"
            :prepend-inner-icon="user.isReadonly('electrical_C3_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('electrical_C3_kgCO2m2')"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="selectedBuilding.electrical_A4_kgCO2m2"
            label="A4"
            type="number"
            :prepend-inner-icon="user.isReadonly('electrical_A4_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('electrical_A4_kgCO2m2')"
          />
          <v-text-field
            v-model.number="selectedBuilding.electrical_C4_kgCO2m2"
            label="C4"
            type="number"
            :prepend-inner-icon="user.isReadonly('electrical_C4_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('electrical_C4_kgCO2m2')"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="selectedBuilding.electrical_A5_3_kgCO2m2"
            label="A5.3"
            type="number"
            :prepend-inner-icon="user.isReadonly('electrical_A5_3_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('electrical_A5_3_kgCO2m2')"
          />
          <v-text-field
            v-model.number="selectedBuilding.electrical_D1_kgCO2m2"
            label="D1"
            type="number"
            :prepend-inner-icon="user.isReadonly('electrical_D1_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('electrical_D1_kgCO2m2')"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="selectedBuilding.electrical_C2_kgCO2m2"
            label="C2"
            type="number"
            :prepend-inner-icon="user.isReadonly('electrical_C2_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('electrical_C2_kgCO2m2')"
          />
        </v-col>
      </v-row>

      <h3 class="text-body-1 mt-2 font-weight-bold text-grey-darken-3">Plumbing</h3>
      <h3 class="text-body-2 text-grey-darken-1">Public Health (kgCO2e/m2)</h3>
      <v-row class="mt-1">
        <v-col>
          <v-text-field
            v-model.number="selectedBuilding.plumbing_A1A3_kgCO2m2"
            label="A1A3"
            type="number"
            :prepend-inner-icon="user.isReadonly('plumbing_A1A3_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('plumbing_A1A3_kgCO2m2')"
          />
          <v-text-field
            v-model.number="selectedBuilding.plumbing_A4_kgCO2m2"
            label="C3"
            type="number"
            :prepend-inner-icon="user.isReadonly('plumbing_A4_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('plumbing_A4_kgCO2m2')"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="selectedBuilding.plumbing_A5_3_kgCO2m2"
            label="A4"
            type="number"
            :prepend-inner-icon="user.isReadonly('plumbing_A5_3_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('plumbing_A5_3_kgCO2m2')"
          />
          <v-text-field
            v-model.number="selectedBuilding.plumbing_C2_kgCO2m2"
            label="C4"
            type="number"
            :prepend-inner-icon="user.isReadonly('plumbing_C2_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('plumbing_C2_kgCO2m2')"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="selectedBuilding.plumbing_C3_kgCO2m2"
            label="A5.3"
            type="number"
            :prepend-inner-icon="user.isReadonly('plumbing_C3_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('plumbing_C3_kgCO2m2')"
          />
          <v-text-field
            v-model.number="selectedBuilding.plumbing_C4_kgCO2m2"
            label="D1"
            type="number"
            :prepend-inner-icon="user.isReadonly('plumbing_C4_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('plumbing_C4_kgCO2m2')"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="selectedBuilding.plumbing_D1_kgCO2m2"
            label="C2"
            type="number"
            :prepend-inner-icon="user.isReadonly('plumbing_D1_kgCO2m2') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('plumbing_D1_kgCO2m2')"
          />
        </v-col>
      </v-row>
    </template>
  </v-expansion-panel>
</template>

<script>
import { useLiveediting } from '@/composables/liveediting'

export default {
  props: {
    locked: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      required: true
    }
  },
  setup() {
    const { selectedBuilding, getConfig } = useLiveediting()
    const config = getConfig()

    return {
      selectedBuilding,
      config
    }
  },

  computed: {
    heatingSysLabel() {
      return this.selectedBuilding.heating_sys
        ? this.config.heating_sys.find(({ id }) => id == this.selectedBuilding.heating_sys).label
        : ''
    },

    heatingSystems() {
      const systems = [
        {
          value: 'GAS_BOILER',
          title: 'Gas boiler'
        },
        {
          value: 'ASHP',
          title: 'Air Source Heat Pump'
        },
        {
          value: 'VRF',
          title: 'Variable Refrigerant Flow'
        },
        {
          value: 'DELECHEATING',
          title: 'Direct Electric Heating'
        },
        {
          value: 'EAHP',
          title: 'Exhaust Air Heat Pump'
        },
        {
          value: 'COMGB_ASHP',
          title: 'Communal Gas Boilers & ASHP'
        },
        {
          value: 'COM_ASHP',
          title: 'Communal ASHP'
        },
        {
          value: 'COM_HEAT_POWER',
          title: 'Communal Combined Heat and Power'
        }
      ]
      const options = this.user.getOptions('heating_sys')
      return options.length ? this.user.limitItems(options, systems) : systems
    },

    coolingSysLabel() {
      const { cooling_sys: coolingSys } = this.selectedBuilding

      return coolingSys && coolingSys != 'NONE'
        ? this.config.cooling_sys.find(({ id }) => id == coolingSys).label +
            ` ${this.selectedBuilding.cooling_capacity} W`
        : 'None'
    },

    coolingSystems() {
      const systems = [
        {
          title: 'Chiller',
          value: 'CHILLER'
        }
      ]
      const options = this.user.getOptions('cooling_sys')
      return options.length ? this.user.limitItems(options, systems) : systems
    }
  }
}
</script>
