<template>
  <v-dialog v-model="show" scrollable>
    <v-card flat>
      <v-card-title class="d-flex ga-3 mt-2 flex-column">
        <div class="d-flex align-center justify-space-between mb-2">
          <h2 class="text-h5 font-weight-bold text-surface-variant">Manage Assemblies</h2>

          <v-tooltip>
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                icon="close"
                density="comfortable"
                color="error"
                variant="tonal"
                @click="show = false"
              />
            </template>
            <span>Close</span>
          </v-tooltip>
        </div>

        <div class="d-flex ga-4 w-100">
          <v-spacer />
          <v-text-field
            v-model="searchTerm"
            prepend-inner-icon="search"
            density="compact"
            label="Find assemblies"
            single-line
            flat
            hide-details
            variant="solo-filled"
            type="search"
            clearable
          />
          <v-btn
            color="primary"
            :prepend-icon="user.canCreate('assembly') ? 'add' : 'lock'"
            :disabled="!user.canCreate('assembly')"
            @click="onAddAssembly"
          >
            Add Assembly
          </v-btn>
        </div>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :items="assemblies"
          :headers="assemblyHeaders"
          :search="searchTerm"
          :items-per-page="10"
          no-data-text="No assemblies found"
          hover
          sort-asc-icon="arrow_drop_up"
          sort-desc-icon="arrow_drop_down"
          :loading="loading && 'secondary'"
          loading-text="Loading assemblies..."
          sticky
        >
          <template v-if="showActions" #[`item.actions`]="{ item }">
            <v-container class="ma-0 pa-0 d-flex flex-no-wrap">
              <v-btn
                v-if="user.canUpdate('assembly')"
                icon="edit"
                size="small"
                density="comfortable"
                color="primary"
                variant="text"
                @click="onEditAssembly(item.id)"
              />
              <v-btn
                v-if="user.canUpdate('assembly')"
                icon="content_copy"
                size="small"
                density="comfortable"
                color="success"
                variant="text"
                @click="copyAssembly(item)"
              />

              <v-dialog v-if="user.canDelete('assembly')" width="500">
                <template #activator="{ props }">
                  <v-btn
                    v-bind="props"
                    icon="delete"
                    size="small"
                    density="comfortable"
                    color="error"
                    variant="text"
                  />
                </template>

                <template #default="{ isActive }">
                  <v-card>
                    <v-card-text color="grey-darken-3">
                      Are you sure you want to delete this assembly?
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer />
                      <v-btn text="Cancel" color="secondary" @click="isActive.value = false" />
                      <v-btn
                        text="Delete"
                        color="error"
                        @click="onDeleteAssembly(item.id, () => (isActive.value = false))"
                      />
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </v-container>
          </template>
          <template #[`item.components`]="{ item }">
            <v-container class="py-1 px-0">
              <v-chip
                v-for="{ material } in item.components"
                :key="material.id"
                density="comfortable"
                class="ma-1"
                size="small"
              >
                {{ material.name }}
              </v-chip>
            </v-container>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showNewAssemblyDialog" max-width="1000" retain-focus>
      <v-card>
        <v-card-title class="px-6 py-3">
          {{ currentAssembly.id ? 'Update assembly' : 'New assembly' }}
        </v-card-title>

        <v-card-text>
          <v-row class="my-1">
            <v-col cols="12" md="4" class="py-0">
              <v-text-field
                v-model="currentAssembly.name"
                label="Name"
                :error-messages="v.currentAssembly.name.$errors.map((e) => e.$message)"
              />
            </v-col>
            <v-col cols="12" md="4" class="py-0">
              <v-text-field
                v-model="currentAssembly.shortcode"
                label="Shortcode"
                :error-messages="v.currentAssembly.shortcode.$errors.map((e) => e.$message)"
              />
            </v-col>
            <v-col cols="12" md="4" class="py-0">
              <v-select
                v-model="currentAssembly.type"
                label="Type"
                :items="assemblyTypes"
                :error-messages="v.currentAssembly.type.$errors.map((e) => e.$message)"
              />
            </v-col>
          </v-row>

          <v-row class="my-1">
            <v-col class="pt-0">
              <v-checkbox
                v-model="currentAssembly.is_retrofit"
                label="Use this assembly for Retrofit"
                color="primary"
                hide-details
              />
            </v-col>
          </v-row>

          <v-alert
            v-if="
              v.currentAssembly.components.$errors.length &&
              !v.$validationGroups.material.$errors.length
            "
            type="error"
            density="compact"
            variant="tonal"
            class="mb-4"
          >
            At least one material should be added to the assembly.
          </v-alert>

          <v-card color="grey-lighten-4">
            <v-card-title class="text-overline"> Add material to assembly </v-card-title>

            <v-card-text class="pb-0">
              <v-row class="my-1">
                <v-col cols="12" md="6" class="py-0">
                  <v-autocomplete
                    v-model="selectedMaterial"
                    label="Material"
                    :items="materials"
                    item-title="name"
                    return-object
                  />
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <v-select
                    v-model="materialUsetype"
                    label="Type"
                    :items="[
                      { title: 'Surface', value: 'SURFACE' },
                      { title: 'Linear', value: 'LINEAR' },
                      { title: 'Point', value: 'POINT' }
                    ]"
                  />
                </v-col>
              </v-row>

              <v-row v-if="selectedMaterial && materialUsetype" class="my-1">
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    v-if="materialUsetype == 'SURFACE'"
                    v-model.number="surfaceThickness"
                    label="Thickness"
                    type="number"
                    suffix="m"
                    :error-messages="v.surfaceThickness.$errors.map((e) => e.$message)"
                  />
                  <v-text-field
                    v-if="materialUsetype == 'LINEAR'"
                    v-model.number="linearProfilesection"
                    label="Profile Section"
                    type="number"
                    suffix="mm"
                    :error-messages="v.linearProfilesection.$errors.map((e) => e.$message)"
                  />
                  <v-text-field
                    v-if="materialUsetype == 'POINT'"
                    v-model.number="pointVolume"
                    label="Volume"
                    type="number"
                    suffix="m³/m²"
                    :error-messages="v.pointVolume.$errors.map((e) => e.$message)"
                  />
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    v-if="materialUsetype == 'LINEAR'"
                    v-model.number="linearLength2areaRatio"
                    label="Length to Area Ratio"
                    type="number"
                    suffix="m/m²"
                    :error-messages="v.linearLength2areaRatio.$errors.map((e) => e.$message)"
                  />
                  <v-text-field
                    v-if="materialUsetype == 'POINT'"
                    v-model.number="pointQuantity"
                    label="Quantity per area ratio"
                    type="number"
                    suffix="no./m²"
                    :error-messages="v.pointQuantity.$errors.map((e) => e.$message)"
                  />
                </v-col>
              </v-row>
              <v-row
                v-if="currentAssembly.is_retrofit && selectedMaterial && materialUsetype"
                class="my-1"
              >
                <v-col class="pt-0" col="12" md="6">
                  <v-checkbox
                    v-model="retrofit_new"
                    label="Material is new (unselect for retained)"
                    color="primary"
                    hide-details
                    class="mb-1"
                  />
                </v-col>
                <v-col class="pt-0" col="12" md="6">
                  <v-slider
                    v-if="!retrofit_new"
                    v-model="retrofit_retention"
                    label="Retention (%)"
                    min="0"
                    max="100"
                    :ticks="[0, 100]"
                    show-ticks="always"
                    step="1"
                    tick-size="0"
                    color="primary"
                  >
                    <template #tick-label="{ tick }">
                      <span class="text-grey-darken-1 text-caption">{{ tick.label }}</span>
                    </template>
                    <template #append>
                      <span class="ms-3" style="width: 30px">
                        {{ retrofit_retention }}
                      </span>
                    </template>
                  </v-slider>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions v-if="selectedMaterial && materialUsetype">
              <v-btn
                text="Go to manage and create materials"
                to="material"
                variant="plain"
                class="text-body-1 text-decoration-underline"
              />
              <v-spacer />
              <v-btn
                :text="itemid ? 'Update Material' : 'Add Material'"
                color="primary"
                @click="addMaterial"
              />
            </v-card-actions>
          </v-card>

          <v-data-table
            :headers="materialsHeaders"
            :items="currentAssembly.components"
            item-key="id"
            hide-default-footer
            class="mt-4"
            hover
          >
            <template #[`item.actions`]="{ item }">
              <v-container class="ma-0 pa-0 d-flex flex-no-wrap">
                <v-btn
                  icon="edit"
                  color="primary"
                  size="small"
                  density="comfortable"
                  variant="text"
                  @click="updateMaterial(item)"
                />
                <v-btn
                  icon="delete"
                  color="error"
                  size="small"
                  density="comfortable"
                  variant="text"
                  @click="removeMaterial(item.itemid)"
                />
              </v-container>
            </template>

            <template #[`item.name`]="{ item }">
              {{ item.material.name }}
            </template>

            <template #[`item.density`]="{ item }">
              {{ item.material.density }}
            </template>

            <template #[`item.eq_thickness`]="{ item }">
              {{ equivalentThickness(item) }}
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn text="Close" color="secondary" @click="showNewAssemblyDialog = false" />
          <v-btn text="Save" color="primary" @click="handleSaveAssembly" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useVuelidate } from '@vuelidate/core'
import { v4 as uuidv4 } from 'uuid'
import { required, helpers, minLength } from '@vuelidate/validators'
import { useMaterialStore } from '@/stores/material'
import { useAssemblyStore } from '@/stores/assembly'
import { useMessenger } from '@/composables/messenger'
import { MESSAGES } from '@/library/messages'
import Assembly, { assemblyTypes } from '@/library/models/Assembly'
import { useUser } from '@/composables/user'

const headerProps = {
  class: 'font-weight-bold'
}
const assemblyHeaders = [
  { key: 'actions', headerProps, sortable: false },
  { title: 'Name', key: 'name', headerProps },
  { title: 'Type', key: 'formattedType', headerProps },
  { title: 'Shortcode', key: 'shortcode', headerProps },
  { title: 'Materials', key: 'components', headerProps }
]

export default {
  name: 'AssemblyList',

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  emits: ['update:modelValue'],

  setup() {
    const { fetchAssemblies, saveAssembly, deleteAssembly } = useAssemblyStore()
    const { assemblies, loading } = storeToRefs(useAssemblyStore())
    const { fetchMaterials } = useMaterialStore()
    const { materials } = storeToRefs(useMaterialStore())
    const { addMessage } = useMessenger()

    const { user, getUser } = useUser()

    getUser()

    fetchMaterials()
    fetchAssemblies()

    const searchTerm = ref('')
    const showNewAssemblyDialog = ref(false)

    const externalResults = ref({
      currentAssembly: {
        shortcode: [],
        generalError: []
      }
    })

    const materialsHeaders = ref([
      { key: 'actions', sortable: false },
      { title: 'Name', key: 'name', headerProps },
      { title: 'Density (kg/m³)', key: 'density', headerProps },
      { title: 'Equivalent Thickness (m)', key: 'eq_thickness', headerProps },
      { title: 'Use Type', key: 'material_usetype', headerProps },
      { title: 'Thickness (m)', key: 'surface_thickness', headerProps },
      { title: 'Profile Section (mm/m²)', key: 'linear_profilesection', headerProps },
      {
        title: 'Length to Area Ratio (mm/m²)',
        key: 'linear_length2area_ratio',
        headerProps,
        minWidth: '150px'
      },
      { title: 'Volume (m³/m²)', key: 'point_volume', headerProps },
      { title: 'Quantity (no./m²)', key: 'point_quantity', headerProps }
    ])

    return {
      assemblies,
      materials,
      addMessage,
      saveAssembly,
      deleteAssembly,
      loading,
      assemblyTypes,
      searchTerm,
      assemblyHeaders,
      materialsHeaders,
      showNewAssemblyDialog,
      externalResults,
      user,
      v: useVuelidate({ $externalResults: externalResults })
    }
  },

  validations() {
    const rules = {
      currentAssembly: {
        name: { required },
        shortcode: { required },
        type: { required },
        components: {
          required,
          minLength: minLength(1)
        }
      },

      $validationGroups: {
        assembly: [
          'currentAssembly.name',
          'currentAssembly.shortcode',
          'currentAssembly.type',
          'currentAssembly.components'
        ],
        material: [
          'surfaceThickness',
          'linearProfilesection',
          'linearLength2areaRatio',
          'pointVolume',
          'pointQuantity'
        ]
      }
    }

    const greaterThanZero = {
      required,
      greaterThanZero: helpers.withMessage('Value must be greater than 0', (value) => value > 0)
    }

    if (this.materialUsetype == 'SURFACE') {
      rules.surfaceThickness = greaterThanZero
    }

    if (this.materialUsetype == 'LINEAR') {
      rules.linearProfilesection = greaterThanZero
      rules.linearLength2areaRatio = greaterThanZero
    }

    if (this.materialUsetype == 'POINT') {
      rules.pointVolume = greaterThanZero
      rules.pointQuantity = greaterThanZero
    }

    return rules
  },

  data() {
    return {
      currentAssembly: new Assembly(),
      selectedMaterial: null,
      materialUsetype: null,
      surfaceThickness: null,
      linearProfilesection: null,
      linearLength2areaRatio: null,
      pointVolume: null,
      pointQuantity: null,
      retrofit_new: true,
      retrofit_retention: 0,
      itemid: null
    }
  },

  computed: {
    show: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    },

    materialToAdd() {
      return {
        material: this.selectedMaterial,
        material_usetype: this.materialUsetype,
        surface_thickness: this.surfaceThickness,
        linear_profilesection: this.linearProfilesection,
        linear_length2area_ratio: this.linearLength2areaRatio,
        point_volume: this.pointVolume,
        point_quantity: this.pointQuantity,
        retrofit_new: this.retrofit_new,
        retrofit_retention: this.retrofit_retention,
        itemid: uuidv4()
      }
    },

    showActions() {
      return this.user.canUpdate('assembly') || this.user.canDelete('assembly')
    }
  },

  watch: {
    materialUsetype() {
      this.surfaceThickness = 0
      this.linearProfilesection = 0
      this.linearLength2areaRatio = 0
      this.pointVolume = 0
      this.pointQuantity = 0
      this.v.$reset()
    },

    showNewAssemblyDialog(val) {
      if (!val) {
        this.v.$reset()
        this.externalResults.currentAssembly = {
          shortcode: [],
          generalError: []
        }
      }
    },

    'currentAssembly.is_retrofit'(value) {
      value ? this.addRetentionToHeaders() : this.removeRetentionFromHeaders()
    },

    retrofit_new(value) {
      if (value) this.retrofit_retention = 0
    }
  },

  methods: {
    onAddAssembly() {
      this.resetForm()
      this.showNewAssemblyDialog = true
    },

    onDeleteAssembly(id, closeDialog) {
      closeDialog()
      this.deleteAssembly(id)
    },

    onEditAssembly(id) {
      this.loadAssembly(id)
      this.showNewAssemblyDialog = true
    },

    copyAssembly(assembly) {
      const name = assembly.name + ' (copy)'
      const shortcode = assembly.shortcode + '_COPY'
      const id = null
      this.currentAssembly = new Assembly({ ...assembly, id, name, shortcode })
      this.showNewAssemblyDialog = true
    },

    async handleSaveAssembly() {
      await this.v.$validate()
      if (this.v.$validationGroups.assembly.$invalid) return

      try {
        const payload = {
          ...this.currentAssembly,
          components: this.currentAssembly.components.map((material) => ({
            ...material,
            material: material.material.id
          }))
        }
        await this.saveAssembly(payload)
        this.resetForm(true)
        this.v.$reset()
        this.showNewAssemblyDialog = false
        this.addMessage({ text: MESSAGES.ASSEMBLY_SAVE_SUCCESS, color: 'success' })
      } catch (error) {
        if (error.response?.data?.shortcode) {
          this.externalResults.currentAssembly.shortcode = ['Shortcode already exists']
        } else {
          this.externalResults.currentAssembly.generalError = [MESSAGES.ASSEMBLY_SAVE_ERROR]
        }
      }
    },

    resetForm(force = false) {
      if (this.currentAssembly.id || force) {
        this.currentAssembly = new Assembly()
        this.selectedMaterial = null
      }
    },

    async addMaterial() {
      await this.v.$validate()
      if (this.v.$validationGroups.material.$invalid) return

      if (this.itemid) {
        const index = this.currentAssembly.components.findIndex(
          ({ itemid }) => itemid === this.itemid
        )
        this.currentAssembly.components[index] = this.materialToAdd
      } else {
        this.currentAssembly.components.push(this.materialToAdd)
      }
      this.resetMaterial()
    },

    updateMaterial(material) {
      const selectedMaterial = this.materials.find(({ id }) => id === material.material.id)
      this.selectedMaterial = selectedMaterial
      this.materialUsetype = material.material_usetype
      this.$nextTick(() => {
        this.surfaceThickness = material.surface_thickness
        this.linearProfilesection = material.linear_profilesection
        this.linearLength2areaRatio = material.linear_length2area_ratio
        this.pointVolume = material.point_volume
        this.pointQuantity = material.point_quantity
        this.itemid = material.itemid
        this.retrofit_new = material.retrofit_new
        this.retrofit_retention = material.retrofit_retention
      })
    },

    resetMaterial() {
      this.selectedMaterial = null
      this.materialUsetype = null
      this.surfaceThickness = 0
      this.linearProfilesection = 0
      this.linearLength2areaRatio = 0
      this.pointVolume = 0
      this.pointQuantity = 0
      this.itemid = null
      this.retrofit_new = true
      this.retrofit_retention = 0
      this.v.$reset()
    },

    removeMaterial(id) {
      const index = this.currentAssembly.components.findIndex(({ itemid }) => itemid === id)
      if (index === -1) return
      this.currentAssembly.components.splice(index, 1)
    },

    loadAssembly(id) {
      const currentAssembly = this.assemblies.find((assembly) => assembly.id === id)
      this.currentAssembly = new Assembly(currentAssembly)
      this.selectedMaterial = null
      currentAssembly.is_retrofit ? this.addRetentionToHeaders() : this.removeRetentionFromHeaders()
    },

    equivalentThickness(material) {
      if (material.material_usetype == 'LINEAR')
        return (
          (material.linear_profilesection / (1000 * 1000)) *
          material.linear_length2area_ratio
        ).toFixed(6)

      if (material.material_usetype == 'POINT')
        return (material.point_volume * material.point_quantity).toFixed(6)

      return material.surface_thickness.toFixed(6)
    },

    addRetentionToHeaders() {
      if (this.materialsHeaders.find(({ key }) => key === 'retrofit_retention')) return

      this.materialsHeaders.splice(6, 0, {
        title: 'Retention (%)',
        key: 'retrofit_retention',
        headerProps
      })
    },

    removeRetentionFromHeaders() {
      if (!this.materialsHeaders.find(({ key }) => key === 'retrofit_retention')) return

      this.materialsHeaders.splice(6, 1)
    }
  }
}
</script>
