<template>
  <v-expansion-panel expand-icon="keyboard_arrow_down" collapse-icon="keyboard_arrow_up">
    <template #title>
      <div class="w-100 d-flex align-center ga-3 me-3">
        <v-icon>domain_disabled</v-icon>
        <span class="text-overline font-weight-bold">Previous Works</span>
        <v-spacer />
        <div class="text-caption lh-sm d-flex flex-column ga-0 text-end">
          GIA: {{ selectedBuilding.prev_demo_GIA_som }}
        </div>
      </div>
    </template>

    <template #text>
      <v-row>
        <v-col class="pt-8">
          <v-text-field
            v-model.number="selectedBuilding.prev_demo_GIA_som"
            label="Previous Demolition GIA"
            :suffix="appConfigs.building.prev_demo_gia.um"
            type="number"
            :prepend-inner-icon="user.isReadonly('prev_demo_GIA') ? 'lock' : null"
            :disabled="locked || user.isReadonly('prev_demo_GIA')"
          />
        </v-col>
      </v-row>
    </template>
  </v-expansion-panel>
</template>

<script setup>
import { useLiveediting } from '@/composables/liveediting'
import User from '@/library/models/User'

defineProps({
  locked: {
    type: Boolean,
    default: false
  },
  appConfigs: {
    type: Object,
    required: true
  },
  user: {
    type: User,
    required: true
  }
})

const { selectedBuilding } = useLiveediting()
</script>
