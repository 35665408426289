import * as d3 from 'd3'

export default class ParallelCoordsChart {
  lineSelected = false
  activeBrushes = new Map()

  constructor(data) {
    this.data = data
    this.margin = {
      top: 100,
      right: -30,
      bottom: 40,
      left: 30
    }
    this.width = 1300 - this.margin.left - this.margin.right
    this.height = 430 - this.margin.top - this.margin.bottom
  }

  get dimensions() {
    return Object.keys(this.data[0]).filter((d) => d != 'Species')
  }

  get yScale() {
    const y = {}
    for (const i in this.dimensions) {
      const name = this.dimensions[i]

      if (name == 'building') {
        y[name] = d3
          .scalePoint()
          .domain(this.data.map((d) => d[name]))
          .range([this.height, 0])
      } else {
        y[name] = d3
          .scaleLinear()
          .domain(
            d3.extent(this.data, function (d) {
              return +d[name]
            })
          )
          .range([this.height, 0])
          .nice()
      }
    }

    return y
  }

  get xScale() {
    return d3.scalePoint().range([0, this.width]).padding(1).domain(this.dimensions)
  }

  get color() {
    return d3
      .scaleOrdinal()
      .domain(this.data.map((d) => d.building))
      .range(d3.quantize((t) => d3.interpolateTurbo(t * 0.8 + 0.1), this.data.length).reverse())
  }

  drawChart(elementId) {
    if (!this.data.length) return

    this.wrapper = d3.select(elementId)
    this.wrapper.html('')

    this.appendSVG()
    this.drawLines()
    this.drawAxis()
    this.appendBrushes()
    this.drawDownloadButton()
  }

  appendSVG() {
    this.svg = this.wrapper
      .append('svg')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .append('g')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`)
  }

  drawLines() {
    const path = (key) =>
      d3.line()(this.dimensions.map((p) => [this.xScale(p), this.yScale[p](key[p])]))

    this.lines = this.svg
      .selectAll('myPath')
      .data(this.data)
      .join('path')
      .attr('d', path)
      .attr('class', 'line')
      .style('fill', 'none')
      .style('stroke', (d) => this.color(d.building))
      .style('stroke-width', 2.5)
      .style('opacity', 0.5)
      .style('cursor', 'pointer')
      .on('mouseover', (event) => {
        if (this.lineSelected) return
        d3.selectAll('.line').style('opacity', 0.2)
        d3.select(event.currentTarget).style('opacity', 1)
      })
      .on('mouseout', (event) => {
        if (this.lineSelected) return
        d3.selectAll('.line').style('opacity', 0.5)
        d3.select(event.currentTarget).style('opacity', 0.5)
      })
      .on('click', ({ currentTarget }) => {
        d3.selectAll('.line').style('opacity', 0.2)
        d3.select(currentTarget).style('opacity', 1)
        this.lineSelected = true
      })

    this.wrapper.on('click', ({ target }) => {
      if (target.tagName == 'svg') {
        d3.selectAll('.line').style('opacity', 0.5)
        this.lineSelected = false
      }
    })
  }

  drawAxis() {
    this.axes = this.svg
      .selectAll('myAxis')
      .data(this.dimensions)
      .enter()
      .append('g')
      .attr('transform', (d) => 'translate(' + this.xScale(d) + ')')
      .each((key, index, groups) =>
        d3.select(groups[index]).call(d3.axisLeft().scale(this.yScale[key]))
      )
      .style('font-size', '10px')
      .style('color', (d, i) => (i == 0 ? '#333' : '#888'))
      .attr('class', (d, i) => (i == 0 ? 'text-overline' : ''))

    this.axes
      .append('text')
      .style('font-size', '13px')
      .attr('y', -22)
      .attr('dy', 0)
      .text((d) => (d == 'building' ? '' : d.split(':')[0]))
      .attr('fill', '#333')
      .append('tspan')
      .style('font-size', '11px')
      .style('fill', '#888')
      .attr('x', 0)
      .attr('y', -10)
      .attr('dy', 0)
      .text((d) => (d == 'building' ? '' : d.split(':')[1]))

    this.svg
      .selectAll('.text-overline text')
      .call(this.wrap, 110)
      .append('title')
      .text((d) => d)
  }

  appendBrushes() {
    this.axes.append('g').call(
      d3
        .brushY()
        .extent([
          [-20, 0],
          [20, this.height]
        ])
        .on('brush', ({ selection }, key) => {
          this.activeBrushes.set(key, selection)
          this.updateBrushing()
        })
        .on('end', ({ selection }, key) => {
          if (selection !== null) return
          this.activeBrushes.delete(key)
          this.updateBrushing()
        })
    )
  }

  drawDownloadButton() {
    this.wrapper
      .append('button')
      .text('Save chart as image')
      .attr('class', 'text-subtitle-2 text-primary align-self-start')
      .style('margin', `-20px 0 0 ${this.width - 220}px`)
      .style('width', '200px')
      .on('click', this.download)
  }

  updateBrushing() {
    if (this.activeBrushes === null) {
      return this.lines.classed('line-hidden', false)
    }

    this.lines.classed('line-hidden', (d) => {
      let key = 0
      let value_y = 0
      var active_domain_y = 0
      for (var i = 0; i < this.dimensions.length; i++) {
        key = this.dimensions[i]
        value_y = this.yScale[key](d[key])
        active_domain_y = this.activeBrushes.get(key)

        if (active_domain_y != null) {
          if (value_y < active_domain_y[0] || value_y > active_domain_y[1]) {
            return true
          }
        }
      }
      return false
    })
  }

  wrap(text, width) {
    text.each(function () {
      const text = d3.select(this)
      const label = truncateLabel(text.text(), 30)
      const words = label.split(/\s+/).reverse()
      const lineHeight = 1.1
      const y = 0
      const dy = parseFloat(text.attr('dy'))

      let word
      let line = []
      let lineNumber = 0
      let tspan = text
        .text(null)
        .append('tspan')
        .attr('x', -10)
        .attr('y', y)
        .attr('dy', dy + 'em')

      while ((word = words.pop())) {
        line.push(word)
        tspan.text(line.join(' '))
        if (tspan.node().getComputedTextLength() > width) {
          line.pop()
          tspan.text(line.join(' '))
          line = [word]
          tspan = text
            .append('tspan')
            .attr('x', -10)
            .attr('y', y)
            .attr('dy', `${++lineNumber * lineHeight + dy}em`)
            .text(word)
        }
      }
    })
  }

  download() {
    const svg = document.querySelector('svg')
    svg.style.backgroundColor = 'white'
    const data = new XMLSerializer().serializeToString(svg)
    const svgBlob = new Blob([data], {
      type: 'image/svg+xml;charset=utf-8'
    })

    const url = URL.createObjectURL(svgBlob)
    const img = new Image()

    img.addEventListener('load', () => {
      const bbox = svg.getBBox()
      const canvas = document.createElement('canvas')
      canvas.width = bbox.width * 1.8
      canvas.height = bbox.height * 2
      const context = canvas.getContext('2d')
      context.drawImage(img, 0, 0, canvas.width, canvas.height)
      URL.revokeObjectURL(url)
      const a = document.createElement('a')
      a.download = 'PreoptimaDesignComparison.png'
      document.body.appendChild(a)
      a.href = canvas.toDataURL()
      a.click()
      a.remove()
    })
    img.src = url
  }
}

function truncateLabel(text, length) {
  if (text.length > length) return `${text.slice(0, length - 3)}..`
  return text
}
