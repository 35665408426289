export const LCAStagesGroups = {
  /**
   * DO not change the order of the property below
   * Stages are sorted to evaluate up to which stage total carbon should be summed up
   * look at: BuildingManager.getCarbonByElement
   */

  A1A3: 'A1A3',
  A1A3_biogenic: 'A1A3_biogenic',
  A4: 'A4',
  A5_1: 'A5_1',
  A5_2: 'A5_2',
  A5_3: 'A5_3',
  B1_1: 'B1_1',
  B1_2: 'B1_2',
  B2: 'B2',
  B3: 'B3',
  B4: 'B4',
  C1: 'C1',
  C2: 'C2',
  C3: 'C3',
  C4: 'C4',
  D1: 'D1'
}

export const BuildingElements = {
  Structure: 'Structure',
  SecondarySystem: 'SecondarySystem',
  LateralSystem: 'LateralSystem',
  Foundation: 'Foundation',
  UndergroundStoreys: 'UndergroundStoreys',
  SubStructure: 'SubStructure',
  Core: 'Core',
  Site: 'Site',
  Wall: 'Wall',
  Roof: 'Roof',
  Window: 'Window',
  Beams: 'Beam',
  Floor: 'Floor',
  Columns: 'Column',
  FFE: 'FFE',
  EXTERNALWORKS: 'EXTERNALWORKS',
  CEILINGFINISH: 'CEILINGFINISH',
  FLOORFINISH: 'FLOORFINISH',

  HeatingSystem: 'HeatingSystem',
  CoolingSystem: 'CoolingSystem'
}

export const FootprintTypes = {
  L: 'L',
  C: 'C',
  H: 'H',
  BLOCK: 'BLOCK',
  TOWER: 'TOWER',
  COURT: 'COURT',

  CUSTOM: 'CUSTOM'
}

export const BuildingStatus = {
  DRAFT: 'D',
  CONFIRMED: 'C'
}
