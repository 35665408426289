export default class Dataset {
  id = null
  shortcode = null
  materials = []
  name = ''
  region = ''
  lca_methodology = ''
  active = true

  constructor(data) {
    if (data) {
      this.id = data.id
      this.shortcode = data.shortcode
      this.materials = data.materials.map((m) => new Material(m))
      this.name = data.name
      this.region = data.region
      this.lca_methodology = data.lca_methodology
      this.active = data.active
    }
  }
}

class Material {
  id
  A1A3 = 0
  B1_1 = 0
  biogenic_carbon = 0
  tr_distance = 0
  tr_method = ''
  waste_rate = 0
  mat_service_life = 0
  C2 = 0
  C3 = 0
  C4 = 0
  D1 = 0
  dataset
  material

  constructor(data) {
    if (data) {
      this.id = data.id
      this.A1A3 = data.A1A3 ?? 0
      this.B1_1 = data.B1_1 ?? 0
      this.biogenic_carbon = data.biogenic_carbon ?? 0
      this.tr_distance = data.tr_distance ?? 0
      this.tr_method = data.tr_method
      this.waste_rate = data.waste_rate ?? 0
      this.mat_service_life = data.mat_service_life ?? 0
      this.C2 = data.C2 ?? 0
      this.C3 = data.C3 ?? 0
      this.C4 = data.C4 ?? 0
      this.D1 = data.D1 ?? 0
      this.dataset = data.dataset
      this.material = data.material
    }
  }
}
