<template>
  <v-dialog v-model="show" max-width="650px">
    <v-card
      title="Error in refreshing LCA ficures"
      subtitle="The following materials are missing carbon data values"
    >
      <v-card-text>
        <h3 v-if="envelopeMaterials.length" class="text-overline">Envelope materials</h3>

        <ul class="mb-4">
          <li v-for="{ name, id } of envelopeMaterials" :key="id" class="text-subtitle-1">
            {{ name }}
          </li>
        </ul>

        <h3 v-if="structuralMaterials.length" class="text-overline">Structural materials</h3>

        <ul>
          <li v-for="{ name, id } of structuralMaterials" :key="id" class="text-subtitle-1">
            {{ name }}
          </li>
        </ul>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="secondary">Close</v-btn>
        <v-btn color="primary" @click="enterCarbonData">Enter Carbon Data</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { useDatasetStore } from '@/stores/dataset'
import { useMaterialStore } from '@/stores/material'
import { storeToRefs } from 'pinia'

const envelopeAssemblies = [
  'facade_system',
  'glazing_system',
  'roof_system',
  'skylight_system',
  'intwall_system',
  'ceiling_finish_system',
  'floor_finish_system'
]

export default {
  name: 'CarbonDataErrors',

  props: {
    carbonDataErrors: {
      type: Array,
      required: true
    },
    value: {
      type: Boolean
    }
  },

  emits: ['update:modelValue', 'enter-carbon-data'],

  setup() {
    const { materials } = storeToRefs(useMaterialStore())
    const { fetchStructMaterials } = useDatasetStore()
    const { structMaterials, currentMaterial, errorMaterials } = storeToRefs(useDatasetStore())

    fetchStructMaterials()

    return {
      materials,
      structMaterials,
      currentMaterial,
      errorMaterials
    }
  },

  computed: {
    show: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit('update:modelValue', value)
      }
    },

    envelopeErrors() {
      return this.errorMessages(
        this.carbonDataErrors.filter(({ label }) =>
          label.some((item) => envelopeAssemblies.includes(item))
        )
      )
    },

    structuralErrors() {
      return this.errorMessages(
        this.carbonDataErrors.filter(
          ({ label }) => !label.some((item) => envelopeAssemblies.includes(item))
        )
      )
    },

    envelopeMaterials() {
      return this.getShortcodes(this.envelopeErrors).map((shortcode) =>
        this.materials.find((material) => material.shortcode === shortcode)
      )
    },

    structuralMaterials() {
      return this.getShortcodes(this.structuralErrors).map((shortcode) =>
        this.structMaterials.find((material) => material.shortcode === shortcode)
      )
    }
  },

  methods: {
    errorMessages(values) {
      return values.map(({ value }) => value)
    },

    getShortcodes(errors) {
      const extractShortcodes = (value) => value.match(/(?<=\[).+?(?=\])/g)
      const shortcodes = errors.map(extractShortcodes).flat()
      return Array.from(new Set(shortcodes))
    },

    enterCarbonData() {
      this.errorMaterials = [...this.envelopeMaterials, ...this.structuralMaterials]
      this.currentMaterial = this.errorMaterials[0]
      this.$emit('enter-carbon-data')
    }
  }
}
</script>
