import { ref } from 'vue'

const show = ref(false)
const message = ref(null)
const progress = ref(0)
const progressInterval = ref(null)

export function useProgress() {
  progress.value = 0

  function setProgress(pct, msg = null, max = null) {
    if (progressInterval.value != null) {
      clearInterval(progressInterval.value)
    }
    message.value = msg
    progress.value = pct
    if (max) {
      progressInterval.value = setInterval(() => progress.value < max && progress.value++, 500)
    }
    show.value = true
  }

  function resetProgress() {
    progress.value = 100
    show.value = false
    message.value = null
    clearInterval(progressInterval.value)
  }

  return {
    show,
    message,
    progress,
    setProgress,
    resetProgress
  }
}
