const ratios = {
  MtoF: 3.28084,
  M2toF2: 10.76395,
  KNM2toPSF: 20.885434273039,
  KWHtoKBTU: 0.3169840068,
  MStoMPH: 2.2369362921
}

const toFixed = (value, digits = 1) => Number(value)?.toFixed(digits)
const convertMtoF = (value) => toFixed(value * ratios.MtoF)
const convertM2toF2 = (value) => toFixed(value * ratios.M2toF2, 0)
const convertKNM2toPSF = (value) => toFixed(value * ratios.KNM2toPSF)
const convertKWHtoKBTU = (value) => toFixed(value * ratios.KWHtoKBTU)
const convertMStoMPH = (value) => toFixed(value * ratios.MStoMPH)
const convertFtoM = (value) => toFixed(value / ratios.MtoF)

const project = {
  I: {
    windspeed: {
      um: 'mph',
      displayValue: convertMStoMPH,
      min: 20,
      max: 35,
      step: 1 / ratios.MStoMPH
    }
  },
  M: {
    windspeed: {
      um: 'm/s',
      displayValue: toFixed,
      min: 20,
      max: 35,
      step: 1
    }
  }
}

export const building = {
  I: {
    prev_demo_gia: {
      um: 'ft²'
    },
    floorarea: {
      um: 'ft²',
      displayValue: convertM2toF2
    },
    storeyHeight: {
      um: 'ft',
      min: 8.2,
      max: 19.7,
      displayValue: convertMtoF,
      step: 0.1 / ratios.MtoF
    },
    UGStoreyHeight: {
      um: 'ft',
      displayValue: convertMtoF,
      step: 0.1 / ratios.MtoF
    },
    gridSize: {
      um: 'ft',
      displayValue: convertMtoF,
      step: 0.5 / ratios.MtoF
    },
    floorLoading: {
      um: 'PSF',
      displayValue: convertKNM2toPSF,
      step: 1 / ratios.KNM2toPSF
    },
    eui: {
      um: 'kBtu/ft2·yr',
      displayValue: convertKWHtoKBTU,
      step: 10 / ratios.KWHtoKBTU
    },
    custom_geometry: {
      dimensions: {
        um: 'ft',
        min: 3,
        max: 984.2,
        metricValue: convertFtoM
      },
      ish: {
        min: convertMtoF(2.5),
        max: convertMtoF(6.0),
        metricValue: convertFtoM
      }
    }
  },
  M: {
    prev_demo_gia: {
      um: 'm²'
    },
    floorarea: {
      um: 'm²',
      displayValue: (v) => toFixed(v, 0)
    },
    storeyHeight: {
      um: 'm',
      min: 2.5,
      max: 6,
      displayValue: toFixed,
      step: 0.1
    },
    UGStoreyHeight: {
      um: 'm',
      displayValue: toFixed,
      step: 0.1
    },
    gridSize: {
      um: 'm',
      displayValue: toFixed,
      step: 0.5
    },
    floorLoading: {
      um: 'kN/m²',
      displayValue: toFixed,
      step: 0.5
    },
    eui: {
      um: 'kWh/m²y',
      displayValue: toFixed,
      step: 10
    },
    custom_geometry: {
      dimensions: {
        um: 'm',
        min: 1,
        max: 300,
        metricValue: toFixed
      },
      ish: {
        min: 2.5,
        max: 6.0,
        metricValue: toFixed
      }
    }
  }
}

export const structure = {
  types: [
    {
      title: 'Concrete',
      value: 'REINF_CONCRETE',
      target: 'concrete'
    },
    {
      title: 'Steel',
      value: 'STEEL',
      target: 'steel'
    },
    {
      title: 'Timber',
      value: 'TIMBER',
      target: 'timber'
    },
    {
      title: 'Hybrid',
      value: 'HYBRID',
      target: 'hybrid'
    }
  ],
  gridSize: {
    min: 3.5,
    max: 12,
    step: 0.5
  },
  floorSlabs: [
    {
      value: 'RIBBED',
      title: 'Ribbed'
    },
    {
      value: 'FLAT',
      title: 'Flat'
    }
  ],
  steelBeams: [
    { value: 'SOLIDSEC', title: 'UBS/ W-shapes' },
    { value: 'CELLULAR', title: 'Cellular' }
  ],
  lateralSystems: [
    {
      value: 'CONCRETE_SHEARWALL',
      title: 'Reinforced Concrete'
    },
    {
      value: 'STEEL_BRACED_FRAMES',
      title: 'Steel Braced Frames'
    }
  ],
  variableLoad: {
    min: 1.5,
    max: 7,
    step: 0.5
  },
  ratazionFactor: {
    min: 1.0,
    max: 1.3,
    step: 0.05
  }
}

export const subStructure = {
  types: [
    { value: 'GENFOUNDATIONS', title: 'General Foundation' },
    { value: 'SHALLOWFOUNDATION', title: 'Shallow Foundation' },
    { value: 'DEEPFOUNDATION', title: 'Deep Foundation' }
  ],
  soilTypes: [
    { value: 'CLAY', title: 'Clay' },
    { value: 'SAND', title: 'Sand' },
    { value: 'GRAVEL', title: 'Gravel' }
  ],
  storeys: {
    min: 0,
    max: 3,
    step: 1
  },
  height: {
    min: 3,
    max: 5,
    step: 0.1
  }
}

export const operationalEnergy = {
  decarbScenarios: [
    {
      value: 'UK_DECARBONISED_GRID',
      title: 'UK Decarbonised Grid',
      disabled: false
    },
    {
      value: 'US_DECARONISED_GRID',
      title: 'US Decarbonised Grid',
      disabled: false
    }
  ],
  nonDecarbScenarios: [
    {
      value: 'UK_NON_DECARBONISED_GRID',
      title: 'UK Non Decarbonised Grid',
      disabled: false
    },
    {
      value: 'US_MID_GRID',
      title: 'US Mid Grid',
      disabled: false
    }
  ]
}

export const getConfig = (som) => {
  const metric = som == 'M'
  return metric
    ? {
        project: project.M,
        building: building.M
      }
    : {
        project: project.I,
        building: building.I
      }
}
