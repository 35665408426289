import { UOMConv } from './utils'
import { useConfig } from '@/composables/config'

const { config } = useConfig()

const ConfigsManager = {
  _data: null,
  _bType: null,

  CURRENCY: {
    USD: { symbol: '$', label: 'USD - United States Dollar', code: 'USD' },
    GBP: { symbol: '£', label: 'GBP - Sterling', code: 'GBP' },
    EUR: { symbol: '€', label: 'EUR - Euro', code: 'EUR' }
  },

  get numOfStoreys() {
    return Object.assign({}, this.getUIConfigs().BUILDINGUSE.NUMOFSTOREYS)
  },
  get UGStoreys() {
    return Object.assign({}, this.getUIConfigs().BUILDINGUSE.UG_STOREYS)
  },

  get glazedArea() {
    return Object.assign({}, this.getUIConfigs().ENVELOPEWALL.GLAZEDAREA)
  },
  get skylightArea() {
    return Object.assign({}, this.getUIConfigs().ENVELOPEROOF.SKYLIGHTAREA)
  },
  get officeArea() {
    return Object.assign({}, this.getUIConfigs().BUILDINGUSE.OFFICEAREA)
  },
  get targetFloorArea() {
    return Object.assign({}, this.getUIConfigs().BUILDINGUSE.GFA)
  },
  get fsr() {
    return Object.assign({}, this.getUIConfigs().BUILDINGUSE.FSR)
  },
  get max_height() {
    return this.getConvertedSliderCfg(this.getUIConfigs().BUILDINGUSE.MAXHEIGHT)
  },

  get gridSizeD1() {
    return this.getConvertedSliderCfg(this.getUIConfigs().STRUCTURE.GRIDSIZED1)
  },
  get gridSizeD2() {
    return this.getConvertedSliderCfg(this.getUIConfigs().STRUCTURE.GRIDSIZED2)
  },

  get uValueWall() {
    return this.getConvertedSliderCfg(this.getUIConfigs().ENVELOPEWALL.UVALUEWALL)
  },
  get uValueWindows() {
    return this.getConvertedSliderCfg(this.getUIConfigs().ENVELOPEWALL.UVALUEWINDOWS)
  },

  get uValueRoof() {
    return this.getConvertedSliderCfg(this.getUIConfigs().ENVELOPEROOF.UVALUEROOF)
  },
  get uValueSkylight() {
    return this.getConvertedSliderCfg(this.getUIConfigs().ENVELOPEROOF.UVALUESKYLIGHT)
  },

  get windspeed() {
    return this.getConvertedSliderCfg(this.getUIConfigs().BUILDINGUSE.WINDSPEED)
  },

  get assessmentBoundaries() {
    return Object.assign({}, this.getUIConfigs().LCA.ASSMNTBOUNDARIES)
  },

  get heatingCapacity() {
    return Object.assign({}, this.getUIConfigs().MEP.HEATINGCAPACITY)
  },
  get coolingCapacity() {
    return Object.assign({}, this.getUIConfigs().MEP.COOLINGCAPACITY)
  },
  get lightingCapacity() {
    return Object.assign({}, this.getUIConfigs().MEP.LIGHTINGCAPACITY)
  },
  get ventilationCapacity() {
    return Object.assign({}, this.getUIConfigs().MEP.VENTILATIONCAPACITY)
  },

  get eui() {
    return Object.assign({}, this.getUIConfigs().OE.EUI)
  },
  get electricity_share() {
    return Object.assign({}, this.getUIConfigs().OE.ELECTSHARE)
  },

  get MAP_TOKEN() {
    return this.getConfigs('MAP').TOKEN
  },

  getSliderCfgIMPERIAL(cfg) {
    let target = UOMConv.conv(cfg.MINMAX, cfg.um, UOMConv.MEASSYS.IMPERIAL, true)

    cfg.MINMAX = cfg.MINMAX_I != null ? cfg.MINMAX_I : target.value
    cfg.DEFAULT =
      cfg.DEFAULT_I != null
        ? cfg.DEFAULT_I
        : UOMConv.conv(cfg.DEFAULT, cfg.um, UOMConv.MEASSYS.IMPERIAL, true).value
    cfg.STEP =
      cfg.STEP_I != null
        ? cfg.STEP_I
        : UOMConv.conv(cfg.STEP, cfg.um, UOMConv.MEASSYS.IMPERIAL, true).value

    let STEP_MAX = (cfg.MINMAX[1] - cfg.MINMAX[0]) / 4

    if (cfg.STEP > STEP_MAX) {
      cfg.STEP = STEP_MAX
      console.warn(
        `[getSliderCfgIMPERIAL] - Converted STEP value too high (${cfg.id}). Defaulted to ${STEP_MAX}`
      )
    }

    cfg.um = target.code
    return cfg
  },

  getConvertedSliderCfg(cfg) {
    let res = Object.assign({}, cfg)
    return res
  },

  loadConfigs(cfg) {
    this._data = cfg
    this.initDefaultBldgType()
    return this
  },

  getConfigs(filter = null) {
    let res = this._data
    if (filter != null) {
      res = res[filter]
    }

    return JSON.parse(JSON.stringify(res))
  },

  listShapeTypes(bType = null) {
    let res = JSON.parse(JSON.stringify(this.getUIConfigs(bType).SHAPES.OPTIONS))

    res.forEach((s) => {
      s.W = this.getConvertedSliderCfg(s.W)
      s.L = this.getConvertedSliderCfg(s.L)
    })

    return res
  },

  getShapeSettings(shape, bType = null) {
    return this.listShapeTypes(bType).find((x) => x.id == shape)
  },

  listStructureTypes(bType = null) {
    return this.getUIConfigs(bType).STRUCTURE.OPTIONS
  },

  listFloorSlabsTypes(bType = null) {
    return this.getUIConfigs(bType).STRUCTURE.FLOORSLABS
  },

  listLateralSysTypes(bType = null) {
    return this.getUIConfigs(bType).STRUCTURE.LATERALSYS
  },

  getStructureSettings(structure, bType = null) {
    return this.listStructureTypes(bType).find((x) => x.id == structure)
  },

  listWallTypes(bType = null) {
    return this.getUIConfigs(bType).ENVELOPEWALL.OPTIONS
  },

  getWallSettings(wall, bType = null) {
    let wallSettings = this.listWallTypes(bType).find((x) => x.id == wall)
    if (!wallSettings)
      wallSettings = {
        id: wall,
        label: wall,
        target: wall,
        color: '#eba434'
      }
    return wallSettings
  },

  listRoofTypes(bType = null) {
    return this.getUIConfigs(bType).ENVELOPEROOF.OPTIONS
  },

  getRoofSettings(roof, bType = null) {
    let roofSettings = this.listRoofTypes(bType).find((x) => x.id == roof)
    if (!roofSettings)
      roofSettings = {
        id: roof,
        label: roof,
        target: roof
      }
    return roofSettings
  },

  listCoolingSysTypes(bType = null) {
    return this.getUIConfigs(bType).MEP.COOLINGSYS
  },

  listHeatingSysTypes(bType = null) {
    return this.getUIConfigs(bType).MEP.HEATINGSYS
  },

  listLightingSysTypes(bType = null) {
    return this.getUIConfigs(bType).MEP.LIGHTINGSYS
  },

  listVentilationSysTypes(bType = null) {
    return this.getUIConfigs(bType).MEP.VENTILATIONSYS
  },

  listGridDecarbScenarios(bType = null) {
    return this.getUIConfigs(bType).OE.GRIDDECARBSCEN
  },

  listSteelBeamTypes() {
    return [
      { id: 'SOLIDSEC', label: 'UBS/ W-shapes', disabled: false },
      { id: 'CELLULAR', label: 'Cellular', disabled: false }
    ]
  },
  listFoundationTypes() {
    return [
      { id: 'GENFOUNDATIONS', label: 'General Foundation', disabled: false },
      { id: 'SHALLOWFOUNDATION', label: 'Shallow Foundation', disabled: false },
      { id: 'DEEPFOUNDATION', label: 'Deep Foundation', disabled: false },
      { id: 'MASSPAD', label: 'Mass Pads/Strips', disabled: true },
      { id: 'RAFT', label: 'Raft', disabled: true },
      { id: 'REINFPAD', label: 'Reinforced Pad Strips', disabled: true }
    ]
  },

  listSoilTypes() {
    return [
      { id: 'CLAY', label: 'Clay', disabled: false },
      { id: 'SAND', label: 'Sand', disabled: false },
      { id: 'GRAVEL', label: 'Gravel', disabled: false }
    ]
  },

  getBldgTypes() {
    return this._sortBTypes(this.getConfigs('UI')['BUILDINGTYPES'])
  },

  getUIConfigs(_bType = null) {
    _bType = _bType == null ? this._bType : _bType
    return this.getConfigs('UI')[_bType]
  },

  _sortBTypes(bTypes) {
    return Object.entries(bTypes).sort((x, y) =>
      x[1].disabled == y[1].disabled
        ? x[1].label.localeCompare(y[1].label)
        : !x[1].disabled
        ? -1
        : 1
    )
  },

  initDefaultBldgType() {
    let dfType = this.getBldgTypes()[0][0]
    this.setBldgType(dfType)
    return this
  },

  setBldgType(T) {
    this._bType = T
    return this
  }
}

const GetConfigs = {
  _reqId: 'X',

  run: function () {
    ConfigsManager.loadConfigs(config.value.UI)
    this._reqId = config.value.GUUID
    console.debug('CONFIGS LOADED', config.value.GUUID)
  }
}

export { ConfigsManager, GetConfigs }
