<template>
  <div :id="props.id" />
</template>

<script setup>
const emit = defineEmits(['widget-id'])
const props = defineProps({
  id: {
    type: String,
    default: 'recaptcha-container'
  }
})

// @ts-ignore
const grecaptcha = window.grecaptcha
const sitekey = process.env.VUE_APP_RECAPTCHA_SITE_KEY

if (grecaptcha) {
  grecaptcha.ready(() => {
    const id = grecaptcha.render(props.id, {
      sitekey
    })
    emit('widget-id', id)
  })
}
</script>
