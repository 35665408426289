<template>
  <v-layout class="h-screen">
    <v-row>
      <v-col cols="12" md="6" class="bg-light pt-16 px-4">
        <div class="form-signin mx-auto">
          <img class="logo" src="/img/preoptima_full_logo_noborder.png" alt="" height="50" />
          <router-view />
        </div>
      </v-col>
      <v-col cols="0" md="6" class="d-none d-md-block pa-0">
        <img src="/img/GreenBuilding.jpg" class="w-100 h-100" />
      </v-col>
    </v-row>
  </v-layout>
</template>

<style scoped>
.logo {
  margin-bottom: 40px;
}

@media screen and (min-height: 600px) {
  .logo {
    margin-bottom: 100px;
  }
}

img.w-100 {
  filter: opacity(30%) contrast(175%);
  object-fit: cover;
}

.form-signin {
  max-width: 400px;
  padding: auto 100px;
  text-align: left;
}
</style>
