// @ts-nocheck
import { router } from '@/router'
import axios from 'axios'
import rateLimit from 'axios-rate-limit'

const environment = process.env.NODE_ENV

export const baseURL =
  environment === 'development' ? 'http://localhost:8000/' : window.location.origin + '/'

const instance = axios.create({
  baseURL: baseURL + 'api/',
  headers: {
    'X-PO-SIGNATURE': 'e26f7a3f174df814f991d64eb223f977'
  }
})

rateLimit(instance, { maxRPS: 3 })

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token')
  if (token) config.headers.Authorization = `Token ${token}`

  if (config.url[config.url.length - 1] !== '/') {
    config.url += '/'
  }

  if (config.suffix !== undefined) {
    config.baseURL = baseURL + config.suffix
  }

  return config
})

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) router.push('/login')
    return Promise.reject(error)
  }
)

export default instance
