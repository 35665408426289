import * as d3 from 'd3'

export default class MeterChart {
  intervals = 9
  colorList = ['green', 'yellow', 'red', 'darkred']
  caretSVG = 'M 0,0 L50,0 25,45 0,0'

  constructor({ width, height }) {
    this.width = width
    this.height = height
  }

  get colors() {
    return d3
      .range(0, 1 + 1 / this.intervals, 1 / (this.intervals - 1))
      .map((d) => d3.piecewise(d3.interpolateRgb.gamma(2.2), this.colorList)(d))
  }

  get caretPosition() {
    return d3
      .scaleLinear()
      .domain([0, 100])
      .range([0, this.intervals - 0.5])
  }

  get segmentWidth() {
    return this.width / this.colors.length
  }

  draw(elementId, value) {
    const drawChart = () => {
      this.value = value
      this.wrapper = d3.select(elementId)
      this.width = parseInt(this.wrapper?.style('width'), 10) || this.width
      this.wrapper.html('')
      this.svg = this.drawSVG()
      this.appendSegments()
      this.appendLabels()
      this.drawCaret()
    }

    drawChart()
    window.addEventListener('resize', drawChart)
  }

  drawSVG() {
    return this.wrapper
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height + 45)
  }

  appendSegments() {
    this.svg
      .selectAll('rect')
      .data(this.colors)
      .enter()
      .append('rect')
      .attr('width', this.segmentWidth)
      .attr('height', this.height)
      .attr('fill', (d) => d)
      .attr('stroke', '#222222')
      .attr('stroke-width', '0.5px')
      .attr('y', 20)
      .attr('x', (d, i) => this.segmentWidth * i + 1)
  }

  appendLabels() {
    this.svg
      .selectAll('.label')
      .data(['A++', 'A+', 'A', 'B', 'C', 'D', 'E', 'F', 'G'])
      .enter()
      .append('text')
      .attr('class', 'label')
      .style('font-size', '13px')
      .attr('y', 55)
      .attr('x', (d, i) => this.segmentWidth * (i + 1) - this.segmentWidth / 2)
      .attr('text-anchor', 'middle')
      .text((d) => d)
      .attr('fill', '#333')
  }

  drawCaret() {
    this.svg
      .selectAll('path')
      .data([this.value])
      .enter()
      .append('path')
      .attr('d', this.caretSVG)
      .attr('fill', '#222222')
      .attr('stroke', '#222222')
      .attr(
        'transform',
        `translate(${
          this.segmentWidth * this.caretPosition(this.value) + this.segmentWidth / 3
        }, 4) scale(.3)`
      )
  }
}
