import { ref } from 'vue'

const progressMessages = [
  'Loading in carbon data',
  'Collecting structural loads',
  'Sizing the floor slabs',
  'Designing beams',
  'Choosing columns',
  'Designing the foundations',
  'Sizing the lateral system',
  'Finding the custom assemblies',
  'Performing an automated quantity take-off',
  'Calculating whole-life carbon',
  'Preparing results and insights',
  'Done!'
]
const intervalTime = 12000 / progressMessages.length

const index = ref(0)
const displayMsgs = ref([])
const pctComplete = ref(0)
const LCArunning = ref(false)
const messageInterval = ref(null)
const percentInterval = ref(null)
const QTOComplete = ref(false)
const LCAComplete = ref(false)
const progressPaused = ref(false)

export function useLCA() {
  function startLCAProgress(callback) {
    if (LCArunning.value) resetLCAProgress()

    progressPaused.value = false
    LCArunning.value = true
    messageInterval.value = setInterval(
      () => {
        if (index.value >= progressMessages.length) {
          resetLCAProgress()
          callback()
          return
        }

        if (index.value === 7) {
          if (!QTOComplete.value) {
            return (progressPaused.value = true)
          } else {
            progressPaused.value = false
          }
        }

        if (index.value === 10) {
          if (!LCAComplete.value) {
            return (progressPaused.value = true)
          } else {
            progressPaused.value = false
          }
        }

        displayMsgs.value.push(progressMessages[index.value++])
        if (displayMsgs.value.length > 1) {
          displayMsgs.value.splice(0, 1)
        }
      },
      index.value == progressMessages.length - 1 ? intervalTime * 2 : intervalTime
    )

    percentInterval.value = setInterval(() => {
      if (pctComplete.value >= 100 || progressPaused.value) return
      pctComplete.value++
    }, intervalTime / (100 / progressMessages.length))
  }

  function resetLCAProgress() {
    index.value = 0
    displayMsgs.value = []
    pctComplete.value = 0
    LCArunning.value = false
    clearInterval(messageInterval.value)
    clearInterval(percentInterval.value)
  }

  return {
    displayMsgs,
    pctComplete,
    LCArunning,
    startLCAProgress,
    resetLCAProgress,
    QTOComplete,
    LCAComplete
  }
}
