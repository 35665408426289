<template>
  <v-dialog v-model="show" max-width="600">
    <v-card min-height="350">
      <v-card-title>
        <v-tabs v-model="newDesignTab" color="primary" fixed-tabs>
          <v-tab :prepend-icon="isFreemium ? 'lock' : ''" value="upload"> Upload Design </v-tab>
          <v-tab value="simple"> Simple Geometry</v-tab>
        </v-tabs>
      </v-card-title>

      <v-card-text>
        <v-window v-model="newDesignTab">
          <v-window-item value="upload" class="py-4">
            <v-alert v-if="isFreemium" icon="lock" color="amber-lighten-1" class="mb-8">
              You are using a limited version of Preoptima CONCEPT.
              <router-link to="account">Upgrade</router-link> to a paid license to import your
              massing model for project-specific whole-life carbon assessments and additional design
              parameters.
              <p>To create a simple geometry, select "Simple Geometry" above</p>
            </v-alert>
            <p class="mb-4">Select an obj file to upload</p>
            <v-form ref="uploadform">
              <v-file-input
                v-model="objUpload"
                label="File input"
                accept=".obj"
                :rules="[(objUpload) => objUpload || 'Select an obj file']"
                validate-on="input"
                :disabled="isFreemium"
              />
            </v-form>
            <v-btn
              :href="importGuideUrl"
              target="_blank"
              append-icon="open_in_new"
              text="Guide to importing a massing model"
              variant="text"
              color="primary"
            />
          </v-window-item>

          <v-window-item value="simple" class="pa-2">
            <h3 class="text-body-1 font-weight-bold mb-3">Select a geometry shape</h3>
            <v-row class="">
              <v-col>
                <div
                  class="pa-3 rounded"
                  :class="{ 'bg-green-lighten-4': simpleGeometry.shape === 'rectangle' }"
                >
                  <v-img
                    src="img/rectangle-shape-blank.png"
                    class="cursor-pointer"
                    @click="selectShape('rectangle')"
                  />
                </div>
              </v-col>
              <v-col>
                <div
                  class="pa-3 rounded"
                  :class="{ 'bg-green-lighten-4': simpleGeometry.shape === 'l' }"
                >
                  <v-img
                    src="img/l-shape-blank.png"
                    class="cursor-pointer"
                    @click="selectShape('l')"
                  />
                </div>
              </v-col>
              <v-col>
                <div
                  class="pa-3 rounded"
                  :class="{ 'bg-green-lighten-4': simpleGeometry.shape === 'h' }"
                >
                  <v-img
                    src="img/h-shape-blank.png"
                    class="cursor-pointer"
                    @click="selectShape('h')"
                  />
                </div>
              </v-col>
              <v-col>
                <div
                  class="pa-3 rounded"
                  :class="{ 'bg-green-lighten-4': simpleGeometry.shape === 'c' }"
                >
                  <v-img
                    src="img/c-shape-blank.png"
                    class="cursor-pointer"
                    @click="selectShape('c')"
                  />
                </div>
              </v-col>
            </v-row>
            <p v-if="!simpleGeometry.shape && shapeError" class="text-error">
              You must select a shape
            </p>

            <v-form v-show="simpleGeometry.shape" ref="geometryform" class="mt-6">
              <h3 class="text-body-1 font-weight-bold mb-5">Add geometry dimensions</h3>

              <v-row>
                <v-col>
                  <v-text-field
                    v-for="key in dimensionsLabels[simpleGeometry.shape]"
                    :key="key"
                    v-model.number="simpleGeometry.dimensions[key.toLowerCase()]"
                    :label="key"
                    type="number"
                    :min="dimensionsConfig.min"
                    :max="dimensionsConfig.max"
                    :rules="dimensionRules"
                    :suffix="dimensionsConfig.um"
                  />
                </v-col>
                <v-col class="d-flex align-center">
                  <img :src="`img/${simpleGeometry.shape}-shape.png`" class="w-100 mb-4" />
                </v-col>
              </v-row>

              <div class="text-subtitle-2 text-grey-darken-2 mt-4">
                Interstorey Height ({{ dimensionsConfig.um }})
              </div>

              <v-slider
                v-model="simpleGeometry.interstorey_height"
                :min="ishConfig.min"
                :max="ishConfig.max"
                :step="0.1"
                color="primary"
                hide-details
                class="ms-3"
              >
                <template #append>
                  {{ simpleGeometry.interstorey_height.toFixed(1) }}
                </template>
              </v-slider>

              <div class="text-subtitle-2 text-grey-darken-2 mt-4">Number of Storeys</div>

              <v-slider
                v-model="simpleGeometry.num_storey"
                :min="1"
                :max="15"
                :step="1"
                color="primary"
                hide-details
                class="ms-3"
              >
                <template #append>
                  {{ simpleGeometry.num_storey }}
                </template>
              </v-slider>
            </v-form>
          </v-window-item>
        </v-window>
      </v-card-text>

      <v-card-actions>
        <v-window v-model="newDesignTab" class="w-100 text-end">
          <v-window-item value="upload">
            <v-btn text="Cancel" color="secondary" @click="show = false" />
            <v-btn text="Upload" color="primary" :disabled="isFreemium" @click="importCustomGeom" />
          </v-window-item>
          <v-window-item value="simple">
            <v-btn text="Cancel" color="secondary" @click="show = false" />
            <v-btn text="Save" color="primary" @click="createSimpleGeometry" />
          </v-window-item>
        </v-window>
      </v-card-actions>
    </v-card>
    <AnomaliesDialog v-model="anomaliesDialog" :anomalies="anomalies" @continue="onContinue" />
  </v-dialog>
</template>

<script setup>
import * as turf from '@turf/turf'
import { computed, ref, onMounted, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useLicenceStore } from '@/stores/licence'
import { MESSAGES, useMessenger } from '@/composables/messenger'
import { useProgress } from '@/composables/progress'
import { pApiClient } from '@/library/api'
import { useProjectStore } from '@/stores/project'
import { useBuildingStore } from '@/stores/building'
import { useUser } from '@/composables/user'
import { useDataViz } from '@/composables/dataViz'
import { router } from '@/router'
import { getConfig } from '@/library/appConfigs'
import AnomaliesDialog from '@/components/AnomaliesDialog.vue'

const dimensionsLabels = {
  rectangle: ['W', 'L'],
  l: ['W1', 'W2', 'L1', 'L2'],
  h: ['W1', 'W2', 'L1', 'L2', 'D', 'E'],
  c: ['W1', 'W2', 'W3', 'L1', 'L2', 'L3']
}

const importGuideUrl =
  'https://preoptima.notion.site/Guide-to-importing-a-massing-model-6ff384dca8294003a8f7a2d45eb7f99d'

const { addMessage } = useMessenger()
const { setProgress, resetProgress } = useProgress()
const { licence } = storeToRefs(useLicenceStore())
const { fetchLicence } = useLicenceStore()
const { currentProject } = storeToRefs(useProjectStore())
const { newDraftBuilding } = useBuildingStore()
const { user, getUser } = useUser()
const { showLiveBox } = useDataViz()

getUser()
fetchLicence()

const props = defineProps({
  value: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:modelValue', 'new-design'])

const anomaliesDialog = ref(false)
const newDesignTab = ref('simple')
const geometryform = ref()
const uploadform = ref()
const uploadData = ref(null)
const objUpload = ref(null)
const anomalies = ref([])
const shapeError = ref(false)
const simpleGeometry = ref({
  shape: '',
  interstorey_height: 3.4,
  num_storey: 2,
  dimensions: {
    w: null,
    l: null,
    w1: null,
    w2: null,
    l1: null,
    l2: null,
    d: null,
    e: null,
    w3: null,
    l3: null
  }
})

onMounted(() => {
  if (isFreemium.value) {
    newDesignTab.value = 'simple'
  }
})

const show = computed({
  get: () => props.value,
  set: (value) => emit('update:modelValue', value)
})
const isFreemium = computed(() => licence.value?.is_freemium)
const dimensionsConfig = computed(
  () => getConfig(currentProject.value.som).building.custom_geometry.dimensions
)
const ishConfig = computed(() => getConfig(currentProject.value.som).building.custom_geometry.ish)

const dimensionRules = computed(() => [
  (v) => !!v || 'This field is required',
  (v) =>
    v >= dimensionsConfig.value.min ||
    `Must be less than, or equal to, ${dimensionsConfig.value.min}`,
  (v) =>
    v <= dimensionsConfig.value.max ||
    `Must be less than, or equal to, ${dimensionsConfig.value.max}`
])

watch(
  currentProject,
  (value) => {
    simpleGeometry.value.interstorey_height = value.som === 'M' ? 3.4 : 11.2
  },
  { immediate: true }
)

const simpleGeometryPayload = computed(() => {
  const dimensions = Object.entries(simpleGeometry.value.dimensions).filter(
    ([, value]) => value !== null
  )

  const metricDimensions = {}
  for (const [key, value] of dimensions) {
    metricDimensions[key] = dimensionsConfig.value.metricValue(value)
  }

  const interstorey_height = ishConfig.value.metricValue(simpleGeometry.value.interstorey_height)

  return {
    num_storey: simpleGeometry.value.num_storey,
    shape: simpleGeometry.value.shape,
    interstorey_height,
    ...metricDimensions
  }
})

async function createSimpleGeometry() {
  if (!simpleGeometry.value.shape) {
    shapeError.value = true
    return
  }

  shapeError.value = false

  const { valid } = await geometryform.value.validate()
  if (!valid) return

  setProgress(42, MESSAGES.UI_IMPORT_PROCESSING)

  const site_abs_origin = turf.centroid(turf.polygon([currentProject.value.location_coords]))
    .geometry.coordinates

  try {
    const { data } = await pApiClient.post(
      `geometry/generate/${simpleGeometry.value.shape}/${site_abs_origin}`,
      simpleGeometryPayload.value
    )
    setProgress(90, MESSAGES.UI_IMPORT_RUNNING_LCA)
    draftBuilding(data)
  } catch (e) {
    resetProgress()
    addMessage({
      color: 'error',
      text: 'Something went wrong, please try again later.'
    })
  }
}

async function importCustomGeom() {
  uploadData.value = null
  anomalies.value = []

  const { valid } = await uploadform.value.validate()
  if (!valid) return

  setProgress(42, MESSAGES.UI_IMPORT_PROCESSING)

  const site_abs_origin = turf.centroid(turf.polygon([currentProject.value.location_coords]))
    .geometry.coordinates

  const formData = new FormData()
  const payload = Array.isArray(objUpload.value) ? objUpload.value[0] : objUpload.value
  formData.append('objfile', payload)

  try {
    const { data } = await pApiClient.post(`geometry/parseobj/${site_abs_origin}`, formData)

    setProgress(90, MESSAGES.UI_IMPORT_RUNNING_LCA)

    if (hasAnomalies(data)) return handleAnomalies(data)
    draftBuilding(data)
  } catch (e) {
    resetProgress()
    if (e.response) {
      const errors = []
      if (e.response.data.objfile) {
        errors.push(...e.response.data.objfile)
      }
      const errorlist = `<ul>${errors.map((e) => `<li>${e}</li>`).join('')}</ul>`
      addMessage({
        color: 'error',
        text: MESSAGES.UPLOAD_ERROR + errorlist
      })
    } else {
      addMessage({
        color: 'error',
        text: MESSAGES.UPLOAD_ERROR
      })
    }
  }
}

function draftBuilding(data) {
  const newBuilding = newDraftBuilding(data.blocks)
  newBuilding.setDefaultsFromUserPermissions(user.value.permissions?.building?.fields || [])
  setProgress(98, MESSAGES.UI_IMPORT_EDIT_SWITCHING)
  showLiveBox.value = false
  if (router.currentRoute.value.name === 'liveediting') {
    show.value = false
    resetProgress()
    resetAll()
    emit('new-design')
  } else {
    router.push({ name: 'liveediting', params: { projectid: currentProject.value.id } })
  }
}

function hasAnomalies(data) {
  return data.anomalies.length || data.blocks?.some(({ anomalies }) => anomalies.length)
}

function handleAnomalies(data) {
  resetProgress()

  uploadData.value = data

  const dataAnomalies = [
    ...data.anomalies.map((anomaly) => `Objfile: ${anomaly.msg}`),
    ...data.blocks.map((block, index) =>
      block.anomalies.map((anomaly) => `Block ${index + 1}: ${anomaly.msg}`)
    )
  ].flat()

  anomalies.value = Array.from(new Set(dataAnomalies))
  anomaliesDialog.value = true
}

function onContinue() {
  draftBuilding(uploadData.value)
}

function resetAll() {
  anomaliesDialog.value = false
  uploadData.value = null
  objUpload.value = null
  anomalies.value = []
  simpleGeometry.value.shape = ''
  simpleGeometry.value.interstorey_height = 2.5
  simpleGeometry.value.num_storey = 1
  resetDimensions()
}

function resetDimensions() {
  simpleGeometry.value.dimensions = {
    w: null,
    l: null,
    w1: null,
    w2: null,
    l1: null,
    l2: null,
    d: null,
    e: null,
    w3: null,
    l3: null
  }
}

async function selectShape(shape) {
  await geometryform.value.reset()
  resetDimensions()
  simpleGeometry.value.shape = shape
}
</script>
