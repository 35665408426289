import { ref } from 'vue'
import { useTheme as useVuetifyTheme } from 'vuetify'

const theme = ref('light')

export function useTheme() {
  const vuetifyTheme = useVuetifyTheme()
  theme.value = window.localStorage.getItem('theme') === 'dark' ? 'dark' : 'light'
  vuetifyTheme.global.name.value = theme.value

  function toggleTheme() {
    theme.value = theme.value === 'light' ? 'dark' : 'light'
    vuetifyTheme.global.name.value = theme.value
    window.localStorage.setItem('theme', theme.value)
    location.reload()
  }

  return {
    theme,
    toggleTheme
  }
}
