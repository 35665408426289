import * as turf from '@turf/turf'
import { mapLySrcRef, pMapClass } from './map'

const FIX_OVERLAPPING = false

const pLE = {
  BLOCK_COLOR: '#4bf291',
  SUB_TASK: {
    FULL: 'FULL',
    REFRESH_HEIGHT: 'HEIGHT',
    REFRESH_SOURCE: 'SOURCE'
  },
  COLORS_LIST: [
    '#00FFFF',
    '#A52A2A',
    '#008000',
    '#FFC0CB',
    '#7FFD4',
    '#0000FF',
    '#FF0000',
    '#800080',
    '#808080',
    '#7FFD4'
  ]
}

class pBlock {
  id = 0
  storeys = 1
  storeyHeight = 3
  level = 0

  _children = []
  _parent = null

  _base_height = 0

  color

  baseFeature

  data
  //feature;

  mapRefs = new mapLySrcRef('', '')

  removeParent() {
    if (this._parent != null) {
      this._parent.removeChild(this)
    }
  }

  setStoreys(s) {
    this.storeys = parseInt(s)
  }

  set parent(blockRef) {
    this._parent = blockRef
  }

  get parent() {
    return this._parent
  }

  get base_height() {
    this.refreshBaseHeight()

    return this._base_height
  }

  _getRandomColor() {
    return pLE.BLOCK_COLOR
    /*
        let colorNum = Math.floor(Math.random() * 10);
        return pLE.COLORS_LIST[colorNum];
        */
  }

  refreshBaseHeight() {
    if (this.parent == null) {
      this._base_height = 0
    } else {
      this._base_height = this.parent.height
    }
  }

  _childExists(blockRef) {
    return this._children.includes((x) => x.id == blockRef.id)
  }

  refreshLevel() {
    if (this.parent == null) {
      this.level = 0
    } else {
      this.level = parseInt(this.parent.maxLevel)
    }
  }
  /**
   *
   * @param {pBlock} blockRef
   * @returns
   */
  addChild(blockRef) {
    if (this._childExists(blockRef)) return

    this._children.push(blockRef)
    blockRef.parent = this

    blockRef.refreshBaseHeight()
    blockRef.refreshLevel()
  }

  setParent(parentRef) {
    if (parentRef == null) return
    parentRef.addChild(this)
  }

  removeChild(blockRef) {
    this._children = this._children.filter((x) => x.id != blockRef.id)
  }

  shiftChildrenUp() {
    for (let child of this._children) {
      if (this._parent != null) {
        this._parent.addChild(child)
      } else {
        child._parent = null
      }
    }
  }

  get children() {
    return this._children
  }

  constructor(storeys, storeyHeight, baseFeature, id, parentRef = null) {
    this.id = id
    this.setStoreys(storeys)
    this.storeyHeight = parseInt(storeyHeight)

    if (parentRef != null) {
      //let redFeat = turf.intersect(parentRef.baseFeature, baseFeature);
      if (FIX_OVERLAPPING) {
        for (let child of parentRef.children) {
          if (turf.booleanIntersects(baseFeature, child.baseFeature)) {
            this.addChild(child)

            parentRef.removeChild(child)
          }
        }
      }

      parentRef.addChild(this)
    }

    this.baseFeature = baseFeature

    this.color = this._getRandomColor()
  }

  clone(newID) {
    let res = new pBlock(this.storeys, this.storeyHeight, this.baseFeature, newID, this.parent)
    res.children = this.children

    res.layer = this.layer
    res.source = this.source
    return res
  }

  get name() {
    return `block-${this.id}`
  }

  get feature() {
    let res = turf.featureCollection([])
    res.features.push(
      turf.polygon(
        this.baseFeature.geometry.coordinates,
        {
          color: this.color,
          base_height: this.base_height,
          height: this.height,
          opacity: 0.6,
          ref_id: this.id,
          name: this.name
        },
        {
          id: this.id
        }
      )
    )

    //floor perimeter ?
    /* if(this.storeys > 1){
            let H = this.storeyHeight;
            let width = 0.5;            

            for(let i = 1; i <= this.storeys; i++){
                let id = this.id + i + 9000;
                
                res.features.push(
                        turf.polygon(this.baseFeature.geometry.coordinates, {
                                color: '#000000', 
                                base_height: H,
                                height: H + width,
                                opacity: 1,
                                ref_id: id,
                                name: this.name + '_storey_' + i
                            }, 
                            {
                                id: id
                            })
                    );
                H += this.storeyHeight;
            }
        } */

    return res
  }

  get height() {
    return this.storeys * this.storeyHeight + this._base_height
  }

  get maxLevel() {
    return this.level + this.storeys
  }
}

class pBldg {
  _id = 1
  storeyHeight = 3
  blocks = []

  history = []

  _scene

  _instance

  static getInstance() {
    if (pBldg._instance == null) pBldg._instance = new pBldg()

    return pBldg._instance
  }

  constructor(sAdapter) {
    this._scene = sAdapter
    pBldg._instance = this
  }

  getNewBlockID() {
    let res = ++this._id
    return res
  }

  getOverlappingBlocks(feat) {
    let res = []
    for (let b of this.blocks) {
      if (turf.booleanIntersects(feat, b.baseFeature)) {
        res.push(b)
      }
    }
    return res
  }

  reduceOverlappingPolygon(newFeat, overlappingBlocks) {
    let res = newFeat
    for (let b of overlappingBlocks) {
      res = turf.intersect(res, b.baseFeature)

      //this is a feature that is ovrlapping with 2 non overlapping features (2 towers?)
      if (res == null) return null
    }

    return res
  }

  sortBlocksByLevel(blocks) {
    return blocks.sort((x, y) => y.maxLevel - x.maxLevel)
  }

  getBlockRoots() {
    let roots = []
    for (let b of this.blocks) {
      if (b.parent == null) roots.push(b)
    }

    return roots
  }

  getSelectionStoreys(featSelection) {
    let blocks = this.getOverlappingBlocks(featSelection)
    if (blocks.length == 0) return 0

    let validArea = this.reduceOverlappingPolygon(featSelection, blocks)

    if (validArea == null) {
      console.error('Unable to count storeys - Your selection is overlapping disjoint blocks')
      return false
    }

    return this.sortBlocksByLevel(blocks)[0].maxLevel
  }

  _newBlock(storeys, baseFeature, storeyHeight = this.storeyHeight, parentRef = null) {
    let newBlock = new pBlock(storeys, storeyHeight, baseFeature, this.getNewBlockID(), parentRef)

    this.blocks.push(newBlock)

    return newBlock
  }

  newBlock(storeys, baseFeature, storeyHeight = this.storeyHeight, parentRef = null) {
    let newBlock = this._newBlock(storeys, baseFeature, storeyHeight, parentRef)
    this._scene.drawBlock(newBlock)

    return newBlock
  }

  /**
   *
   * @param {pBlock} root
   */
  addWholeTree(root) {
    this.addChildrenBlocks(root)
    this.drawAllBlocks()

    return root
  }

  addChildrenBlocks(b) {
    this.blocks.push(b)
    for (let child of b.children) {
      this.addChildrenBlocks(child)
    }
  }

  _deleteBlock(block, shiftUp = true) {
    if (block.parent != null) block.parent.removeChild(block)

    if (shiftUp) block.shiftChildrenUp()

    this.blocks = this.blocks.filter((b) => b.id != block.id)

    return block.parent
  }

  deleteAllBlocks() {
    for (let b of this.blocks) {
      this.deleteBlock(b, false, false)
    }
  }

  deleteBuilding() {
    for (let b of this.blocks) {
      this._scene.deleteBlock(b)
    }

    this.blocks = []
  }

  deleteBlock(block, propagate = true, shiftUp = true) {
    if (block.children.length > 0) {
      console.warn('deleteBlock - trying deleting block supporting other blocks...')
    }

    this._deleteBlock(block, shiftUp)
    this._scene.deleteBlock(block)

    if (propagate)
      this._propagate2Descendants(block, function (b) {
        b.refreshBaseHeight()
        b.refreshLevel()
        pBldg.getInstance()._scene.drawBlock(b, pLE.SUB_TASK.REFRESH_HEIGHT)
      })

    return block
  }

  _propagate2Descendants(root, func) {
    let Q = root.children.slice()
    while (Q.length > 0) {
      let i = Q.shift()

      func(i)

      for (let child of i.children) {
        Q.push(child)
      }
    }
  }

  _changeBlockStoreys(block, storeys) {
    block.setStoreys(storeys)

    block.refreshBaseHeight()
    block.refreshLevel()

    return block
  }

  changeBlockStoreys(block, storeys, propagate = false) {
    if (storeys < 1) {
      console.error('block storeys must be greater than 0')
      return false
    }

    this._changeBlockStoreys(block, storeys)
    this._scene.drawBlock(block, pLE.SUB_TASK.REFRESH_HEIGHT)

    if (propagate) {
      this._propagate2Descendants(block, function (b) {
        b.refreshBaseHeight()
        b.refreshLevel()
        pBldg.getInstance()._scene.drawBlock(b, pLE.SUB_TASK.REFRESH_HEIGHT)
      })
    }

    return block
  }

  _changeBlockFootprint(block, feat) {
    let allFeats = this._explodeMulti2Polygon(feat)

    let resBlocks = []

    if (allFeats.length > 1) {
      let color = block.color
      this.deleteBlock(block, false, false)

      for (let f of allFeats) {
        let redFeat = turf.intersect(block.baseFeature, f)

        let newBlock = this._newBlock(block.storeys, redFeat, block.storeyHeight, block.parent)
        newBlock.color = color

        for (let child of block.children) {
          if (turf.booleanIntersects(newBlock.baseFeature, child.baseFeature)) {
            newBlock.addChild(child)
          }
        }

        resBlocks.push(newBlock)
      }
    } else {
      block.baseFeature = feat
      resBlocks.push(block)
    }

    return resBlocks
  }

  changeBlockColor(block, color) {
    this._scene.changeBlockColor(block, color)
  }

  resetBlockColor(block) {
    this._scene.changeBlockColor(block, block.color)
  }

  resetAllBlocksColors() {
    for (let b of this.blocks) {
      this.resetBlockColor(b)
    }
  }

  changeBlockFootprint(block, feat, propagate = false) {
    if (block.parent != null) {
      feat = turf.intersect(block.parent.baseFeature, feat)
    }

    let resBlocks = this._changeBlockFootprint(block, feat)

    for (let newBlock of resBlocks) {
      this._scene.drawBlock(newBlock)
      if (propagate) {
        this._propagate2Descendants(newBlock, function (b) {
          let redFeat = turf.intersect(b.parent.baseFeature, b.baseFeature)
          if (redFeat == null) {
            console.warn('changeBlockFootprint - feature reduction leads to block deletion!')
            pBldg.getInstance().deleteBlock(b, true, true)
          } else {
            b.baseFeature = redFeat
            pBldg.getInstance()._scene.drawBlock(b)
          }
        })
      }
    }

    return resBlocks
  }

  _explodeMulti2Polygon(f) {
    let singlePolygon = []
    if (f.geometry.type == 'MultiPolygon') {
      singlePolygon = f.geometry.coordinates.map((c) => turf.polygon([c[0]]))
    } else {
      singlePolygon.push(f)
    }

    return singlePolygon
  }

  splitBlockH(block, storeys) {
    let topStoreys = block.storeys - storeys
    this._changeBlockStoreys(block, storeys)

    let newBlock = this._newBlock(topStoreys, block.baseFeature, block.storeyHeight, block)

    newBlock.color = block.color

    return [block, newBlock]
  }

  cutStoreys(redFeat, storeys, sortedBlocks) {
    // the selection is to lower the top levels
    for (let b of sortedBlocks) {
      //sorted desc levels
      if (storeys <= 0) break

      let diff = turf.difference(b.baseFeature, redFeat)

      if (b.storeys - storeys > 0) {
        let baseStoreys = b.storeys - storeys

        // some storeys to be consumed ..
        if (diff != null) {
          // the selection is partially cut the footprint
          //splitHorizontally
          let resBlocks = this.splitBlockH(b, baseStoreys)

          //adjust top block footprint
          this._changeBlockFootprint(resBlocks[1], diff)
        } else {
          // the selection is cutting the entire footprint
          //we simply need to lower the block
          this._changeBlockStoreys(b, baseStoreys)
        }
      } else {
        // all storeys of b are consumed

        if (diff == null) {
          // the new selection is entirely covering the underline block
          this.deleteBlock(b, false, false)
        } else {
          // update base polygon geometry with diff
          this._changeBlockFootprint(b, diff)
        }
      }

      storeys -= b.storeys
    }

    this.drawAllBlocks()
  }

  processBlockSelection(newFeat, storeys) {
    if (storeys == 0) return

    if (this.blocks.length == 0) {
      //THIS IS THE VERY FIRST BLOCK
      this.newBlock(storeys, newFeat)

      return true
    }

    let overlappingBlocks = this.getOverlappingBlocks(newFeat)
    if (overlappingBlocks.length == 0) {
      console.error('No Overlapping Area')
      return false
    }

    let redFeat = this.reduceOverlappingPolygon(newFeat, overlappingBlocks)
    if (redFeat == null) {
      console.error('The selected area might be overlapping 2 non-overlapping blocks')
      return false
    }

    let sortedBlocks = this.sortBlocksByLevel(overlappingBlocks)

    if (storeys > 0) {
      //the block is in addition on top
      this.newBlock(storeys, redFeat, this.storeyHeight, sortedBlocks[0])
    } else {
      this.cutStoreys(newFeat, Math.abs(storeys), sortedBlocks)
    }

    return true
  }

  drawAllBlocks() {
    let roots = this.getBlockRoots()
    for (let r of roots) {
      r.refreshBaseHeight()
      r.refreshLevel()
      this._scene.drawBlock(r)

      this.drawChildrenBlocks(r)
    }
  }

  drawBlockBranch(block) {
    while (block.parent != null) {
      block = block.parent
    }

    block.refreshBaseHeight()
    block.refreshLevel()
    this._scene.drawBlock(block)

    this.drawChildrenBlocks(block)
  }

  drawChildrenBlocks(block) {
    if (block.children.length > 0) {
      for (let child of block.children) {
        child.refreshBaseHeight()
        child.refreshLevel()
        pBldg.getInstance()._scene.drawBlock(child)

        pBldg.getInstance().drawChildrenBlocks(child)
      }
    } else {
      block.refreshBaseHeight()
      block.refreshLevel()
      pBldg.getInstance()._scene.drawBlock(block)
    }
  }
}

const pMapAdapter = {
  map() {
    return pMapClass.getInstance()
  },

  drawBlock(block, subTask = pLE.SUB_TASK.FULL) {
    switch (subTask) {
      case pLE.SUB_TASK.REFRESH_SOURCE:
        this.map().refreshSourceFeatures(block.mapRefs.sourceID, block.feature)
        break

      case pLE.SUB_TASK.FULL:
        block.mapRefs = this.map().drawExtrudedBlock(block.feature, block.id)
        this.map().goBackToPolygon(block.feature)
        break

      case pLE.SUB_TASK.REFRESH_HEIGHT:
        this.map().changeBlockHeight(block.mapRefs.layerID, block.height, block.base_height)
        break
    }
  },

  deleteBlock(block) {
    this.map().removeLayerSource(block.mapRefs)
  },

  changeBlockColor(block, color) {
    if (!block?.mapRefs) return
    this.map().changeBlockColor(block.mapRefs.layerID, color)
  }
}

export { pBldg, pMapAdapter, pBlock }
