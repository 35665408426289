export default class User {
  /** @type {number} */
  id

  /** @type {string} */
  username

  /** @type {string} */
  first_name

  /** @type {string} */
  last_name

  /** @type {string} */
  email

  /** @type {boolean} */
  is_active

  /** @type {boolean} */
  tc_acceptance

  /** @type {boolean} */
  dp_acceptance

  /** @type {boolean} */
  api

  /** @type {boolean} */
  web

  /** @type {boolean} */
  is_admin

  /** @type {number} */
  api_user_usage

  /** @type {number} */
  api_customer_usage

  /** @type {object} */
  customer

  /** @type {object} */
  permissions

  constructor(user) {
    if (user) {
      this.id = user.id
      this.username = user.username
      this.first_name = user.first_name
      this.last_name = user.last_name
      this.email = user.email
      this.is_active = user.is_active
      this.tc_acceptance = user.tc_acceptance
      this.dp_acceptance = user.dp_acceptance
      this.api = user.api
      this.web = user.web
      this.api_user_usage = user.api_user_usage
      this.api_customer_usage = user.api_customer_usage
      this.customer = user.customer
      this.is_admin = user.is_admin
      this.permissions = user.permissions
    }
  }

  get fullName() {
    return this.first_name + ' ' + this.last_name
  }

  get activeLicence() {
    if (!this.customer) return false
    if (!this.customer.licence_active) return false
    if (!this.customer.licence_enddate) return true
    if (new Date(this.customer.licence_enddate) < new Date()) return false
    return true
  }

  canDoAction(view, action) {
    if (!this.permissions) return false
    if (!this.permissions[view]) return false
    if (!this.permissions[view].policies) return false
    if (!this.permissions[view].policies.includes(action)) return false
    return true
  }

  canCreate(view) {
    return this.canDoAction(view, 'add')
  }

  canView(view) {
    return this.canDoAction(view, 'view')
  }

  canUpdate(view) {
    return this.canDoAction(view, 'change')
  }

  canDelete(view) {
    return this.canDoAction(view, 'delete')
  }

  isReadonly(fieldName) {
    if (!this.permissions) return false
    const field = this.permissions.building.fields.find(({ name }) => name == fieldName)
    if (!field) return false
    if (!field.readonly) return false
    return true
  }

  getOptions(fieldName) {
    if (!this.permissions) return []
    const field = this.permissions.building.fields.find(({ name }) => name == fieldName)
    if (!field) return []
    return field.options
  }

  limitItems(options, items) {
    return items.map((item) =>
      options.includes(item.value)
        ? item
        : {
            ...item,
            props: {
              appendIcon: 'lock',
              disabled: true
            }
          }
    )
  }
}
