<template>
  <form autocomplete="on" @submit.prevent="login">
    <div class="pb-4">
      <h1 class="h2 mb-3 fw-bold">Sign in</h1>
      <p v-if="getFlag('registration')" class="text-muted">
        Don't have an account?
        <router-link
          to="/register"
          class="ms-1 font-weight-bold text-decoration-underline text-body-1 text-primary"
        >
          Register
        </router-link>
      </p>
    </div>

    <v-alert
      v-if="isConfirmed"
      type="success"
      icon="verified"
      class="mb-6"
      density="default"
      variant="tonal"
      text=" Account confirmed. You can now sign in."
    />

    <v-alert
      v-if="error"
      type="error"
      :icon="error == 'recaptcha' ? 'robot_2' : 'error'"
      class="mb-6"
      density="default"
      variant="tonal"
    >
      <span v-html="error == 'recaptcha' ? 'Please verify that you are not a robot' : error" />
    </v-alert>

    <v-alert
      v-if="tcsAccepted"
      type="success"
      icon="verified"
      class="mb-6"
      density="default"
      variant="tonal"
    >
      <span v-html="'Terms and conditions accepted.<br />You can now continue to sign in.'" />
    </v-alert>

    <v-text-field
      id="email"
      v-model="username"
      name="email"
      type="email"
      label="Email"
      placeholder="Email"
      density="default"
    />

    <v-text-field
      id="current-password"
      v-model="password"
      name="password"
      label="Password"
      placeholder="Password"
      density="default"
      :type="showPassword ? 'text' : 'password'"
      :append-inner-icon="showPassword ? 'visibility' : 'visibility_off'"
      @click:append-inner="showPassword = !showPassword"
    />

    <div class="text-end font-weight-medium mb-6">
      <v-btn
        text="Forgot password?"
        variant="text"
        color="primary"
        class="font-weight-bold text-decoration-underline text-body-1"
        @click="passwordResetDialog = true"
      />
    </div>

    <ReCaptcha class="mb-6" />

    <v-btn
      text="Sign in"
      color="success"
      block
      :loading="loading"
      :disabled="loading"
      size="large"
      @click="login"
    />
  </form>

  <v-dialog v-model="tsAndCsDialog" max-width="800">
    <v-card>
      <v-card-title> Terms and Conditions and Data Protection Agreement </v-card-title>

      <v-card-text>
        <p>Please read the following documents carefully and accept to proceed with our service:</p>

        <div class="d-flex flex-column gap-2 align-start mb-8">
          <v-btn
            target="_blank"
            href="/docs/preoptima_tc.pdf"
            text="Preoptima Terms and Conditions (PDF)"
            variant="text"
            class="text-decoration-underline text-body-1 text-primary"
            density="compact"
          />
          <v-btn
            target="_blank"
            href="/docs/preoptima_dpa.pdf"
            text="Data Protection Agreement (PDF)"
            variant="text"
            class="text-decoration-underline text-body-1 text-primary"
            density="compact"
          />
        </div>

        <v-form ref="tsAndCsForm">
          <v-checkbox
            v-model="tcAcceptance"
            color="primary"
            label="I have read and accept the terms and conditions"
            density="compact"
            hide-details="auto"
            :rules="[(v) => !!v || 'You must accept the terms and conditions']"
          />

          <v-checkbox
            v-model="dpAcceptance"
            color="primary"
            label="I have read and accept the data protection agreement"
            density="compact"
            hide-details="auto"
            :rules="[(v) => !!v || 'You must accept the data protection agreement']"
          />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text="Don't accept" color="secondary" @click="tsAndCsDialog = false" />
        <v-btn text="Accept" color="primary" @click="acceptanceSave" />
      </v-card-actions>
    </v-card>
  </v-dialog>

  <PasswordReset v-model="passwordResetDialog" />
</template>
<script>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { router } from '@/router'
import { useUser } from '@/composables/user'
import ReCaptcha from '@/components/account/ReCaptcha.vue'
import PasswordReset from '@/components/account/PasswordReset.vue'
import { useFlagsmith } from '@/composables/flagsmith'
import { pApiClient } from '@/library/api'

export default {
  name: 'LoginView',

  components: {
    PasswordReset,
    ReCaptcha
  },

  setup() {
    const { setUser, acceptTcAndDp } = useUser()
    const { getFlag } = useFlagsmith()

    const isConfirmed = ref(false)
    const route = useRoute()
    if (route.query.success) isConfirmed.value = true
    const tsAndCsForm = ref()
    const tsAndCsDialog = ref(false)
    const tcsAccepted = ref(false)
    const passwordResetDialog = ref(false)

    return {
      acceptTcAndDp,
      setUser,
      isConfirmed,
      getFlag,
      tsAndCsForm,
      tsAndCsDialog,
      tcsAccepted,
      passwordResetDialog
    }
  },

  data() {
    return {
      username: '',
      password: '',
      loading: false,
      error: '',
      tcAcceptance: false,
      dpAcceptance: false,
      showCreateAccount: false,
      showPassword: false
    }
  },

  computed: {
    loginData() {
      return {
        username: this.username,
        password: this.password
      }
    }
  },

  watch: {
    $route: {
      immediate: true,
      handler() {
        this.showCreateAccount = this.$route.name === 'register'
      }
    }
  },

  methods: {
    async login() {
      if (!this.username || !this.password || this.loading) return

      this.loading = true
      this.error = ''

      // @ts-ignore
      const response = window.grecaptcha.getResponse()
      if (!response) {
        this.loading = false
        this.error = 'recaptcha'
        return
      }

      try {
        const payload = {
          ...this.loginData,
          recaptcha: response
        }
        const { data } = await pApiClient.post('/token-auth', payload, { suffix: 's/' })

        if (!data.user?.is_verified) {
          this.error =
            'Your account has not been verified. Please check your email for a comfirmation link, or reset your password to confirm your account.'
          // @ts-ignore
          window.grecaptcha.reset()
          return
        }

        if (data.token) this.setLocalStorage(data)
        this.setUser(data.user)

        if (data.user.tc_acceptance && data.user.dp_acceptance) {
          this.redirectUser(data.user)
        } else {
          this.tsAndCsDialog = true
        }
      } catch (error) {
        this.error =
          'There was a problem logging you in. <br /> Please check your username and password and try again.'
        // @ts-ignore
        window.grecaptcha.reset()
      } finally {
        this.loading = false
      }
    },

    redirectUser(user) {
      if (user.web) {
        const { redirect } = this.$route.query
        console.log(redirect)
        // @ts-ignore
        router.push(redirect ?? '/')
      } else {
        router.push('/account')
      }
    },

    setLocalStorage(data) {
      localStorage.setItem('token', data.token)
      localStorage.setItem('user', JSON.stringify(data.user))
    },

    async acceptanceSave() {
      const { valid } = await this.tsAndCsForm.validate()
      if (!valid) return

      if (this.tcAcceptance && this.dpAcceptance) {
        await this.acceptTcAndDp()
        this.tsAndCsDialog = false
        this.tcsAccepted = true
        // @ts-ignore
        window.grecaptcha.reset()
      }
    }
  }
}
</script>

<style scoped>
.logo {
  margin-bottom: 40px;
}

@media screen and (min-height: 600px) {
  .logo {
    margin-bottom: 100px;
  }
}

.form-signin {
  max-width: 400px;
  padding: auto 100px;
  text-align: left;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

img.w-100 {
  filter: opacity(30%);
  object-fit: cover;
}

.left-block {
  overflow-y: scroll;
}

.task-icon {
  font-size: 4rem;
  font-variation-settings: 'wght' 100;
}
.verified-icon {
  font-size: 2rem;
  font-variation-settings: 'wght' 100;
}
</style>
