// @ts-nocheck
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { pApiClient } from '@/library/api'
import { useMessenger } from '@/composables/messenger'
import Dataset from '@/library/models/Dataset'

const { addMessage } = useMessenger()

const regions = [
  { title: 'Global', value: 'GLOBAL' },
  { title: 'Europe', value: 'EUROPE' },
  { title: 'Canada', value: 'CANADA' },
  { title: 'United Kingdom', value: 'UK' },
  { title: 'United States', value: 'US' },
  { title: 'Other', value: 'OTHER' }
]
const methodologies = [
  { title: 'Preoptima', value: 'PREOPTIMA' },
  { title: 'RICSv2', value: 'RICSv2' }
]
const carbonDataFields = {
  name: 'Name',
  A1A3: 'A1A3 (kgCO2e/kg)',
  B1_1: 'B1-1 (kgCO2e/kg)',
  biogenic_carbon: 'Biogenic Carbon (kgCO2e/kg)',
  tr_distance: 'Transport Distance (mi)',
  tr_method: 'Transport Method',
  C2: 'C2 (kgCO2e/kg)',
  C3: 'C3 (kgCO2e/kg)',
  C4: 'C4 (kgCO2e/kg)',
  D1: 'D1 (kgCO2e/kg)',
  waste_rate: 'Waste Rate (%)',
  mat_service_life: 'Material Service Life (years)'
}

export const useDatasetStore = defineStore('dataset', () => {
  const datasets = ref([])
  const currentDataset = ref(null)
  const currentMaterial = ref(null)
  const newDataset = ref({
    shortcode: '',
    name: '',
    region: 'GLOBAL',
    lca_methodology: 'PREOPTIMA',
    active: true,
    materials: []
  })
  const isEditingDataset = ref(false)
  const structMaterials = ref([])
  const errorMaterials = ref([])
  const loading = ref(false)

  async function fetchDatasets(reload = false) {
    if (datasets.value.length && !reload) return

    loading.value = true
    try {
      const { data } = await pApiClient.get('/dataset')
      data.sort((a, b) => a.name.localeCompare(b.name))
      datasets.value = data
    } catch (err) {
      addMessage({ text: 'There was an error loading datasets', color: 'error' })
    } finally {
      loading.value = false
    }
  }

  function getDatasetByShortcode(shortcode) {
    currentDataset.value = datasets.value.find((dataset) => dataset.shortcode === shortcode)
  }

  async function fetchDataset(id) {
    loading.value = true
    try {
      const { data } = await pApiClient.get('/dataset/' + id)
      currentDataset.value = new Dataset(data)
    } catch (err) {
      addMessage({ text: 'There was an error loading dataset', color: 'error' })
    } finally {
      loading.value = false
    }
  }

  async function fetchDatasetsAndSelectFirst(reload = false) {
    await fetchDatasets(reload)
    if (datasets.value.length && !currentDataset.value) await fetchDataset(datasets.value[0].id)
  }

  async function saveDataset() {
    const method = isEditingDataset.value ? 'put' : 'post'
    const url = isEditingDataset.value ? '/dataset/' + currentDataset.value.id : '/dataset'
    const payload = {
      name: newDataset.value.name,
      region: newDataset.value.region,
      shortcode: newDataset.value.shortcode,
      lca_methodology: newDataset.value.lca_methodology,
      active: newDataset.value.active,
      materials: []
    }

    if (newDataset.value.materials.length) {
      payload['materials'] = newDataset.value.materials
    }

    if (isEditingDataset.value) delete payload['materials']

    const { data } = await pApiClient[method](url, payload)
    newDataset.value = {
      shortcode: '',
      name: '',
      region: 'GLOBAL',
      lca_methodology: 'PREOPTIMA',
      active: true
    }
    isEditingDataset.value = false
    await fetchDataset(data.id)
    await fetchDatasets(true)

    return data.id
  }

  async function updateCarbonData() {
    const exisitingItem = currentDataset.value.materials?.some(
      (item) => item.id == currentMaterial.value.id
    )
    const url = exisitingItem ? '/carbondata/' + currentMaterial.value.id : '/carbondata/'
    const method = exisitingItem ? 'put' : 'post'
    const payload = exisitingItem
      ? currentMaterial.value
      : {
          dataset: currentDataset.value.id,
          material: currentMaterial.value.id,
          ...currentMaterial.value
        }

    try {
      await pApiClient[method](url, payload)
      addMessage({ text: 'Carbon data updated', color: 'success' })
      fetchDataset(currentDataset.value.id)
    } catch (err) {
      addMessage({ text: 'There was an error updating carbon data', color: 'error' })
    }
  }

  async function deleteDataset() {
    try {
      await pApiClient.delete('/dataset/' + currentDataset.value.id)
      currentDataset.value = null
      await fetchDatasetsAndSelectFirst(true)
    } catch (err) {
      addMessage({ text: 'There was an error deleting dataset', color: 'error' })
    }
  }

  async function copyDataset() {
    newDataset.value = {
      shortcode: currentDataset.value.shortcode + '_copy',
      name: currentDataset.value.name + ' (copy)',
      region: currentDataset.value.region,
      lca_methodology: currentDataset.value.lca_methodology,
      active: currentDataset.value.active,
      materials: currentDataset.value.materials
    }
  }

  async function fetchStructMaterials(reload = false) {
    if (structMaterials.value.length && !reload) return

    try {
      const { data } = await pApiClient.get('/structmaterial')
      structMaterials.value = data
    } catch (err) {
      addMessage({ text: 'There was an error loading structural materials', color: 'error' })
    }
  }

  return {
    datasets,
    currentDataset,
    currentMaterial,
    regions,
    methodologies,
    newDataset,
    carbonDataFields,
    isEditingDataset,
    structMaterials,
    fetchDatasets,
    fetchDataset,
    saveDataset,
    updateCarbonData,
    deleteDataset,
    copyDataset,
    fetchStructMaterials,
    fetchDatasetsAndSelectFirst,
    getDatasetByShortcode,
    errorMaterials,
    loading
  }
})
