<template>
  <v-sheet class="pa-8" color="surface-v-5">
    <v-card flat color="transparent">
      <v-card-title class="d-flex gap-3 px-0">
        <v-spacer />
        <v-btn color="primary" prepend-icon="add" class="mb-4" @click="createProject">
          Create Project
        </v-btn>
      </v-card-title>

      <v-data-table
        :items="items"
        :headers="headers"
        no-data-text="No projects found"
        hover
        sort-asc-icon="arrow_drop_up"
        sort-desc-icon="arrow_drop_down"
        loading-text="Loading projects..."
        :loading="loading"
        :sort-by="[{ key: 'lastedit', order: 'desc' }]"
        class="bg-surface-v-5"
      >
        <template #[`item.actions`]="{ item }">
          <div class="d-flex flex-column flex-sm-row gap-1">
            <v-tooltip text="Open Project" location="top">
              <template #activator="{ props }">
                <v-btn
                  v-bind="props"
                  size="small"
                  variant="text"
                  density="comfortable"
                  icon="folder_open"
                  color="blue-grey"
                  @click="openProject(item.id)"
                />
              </template>
            </v-tooltip>

            <v-tooltip text="Compare Designs" location="top">
              <template #activator="{ props }">
                <v-btn
                  v-bind="props"
                  size="small"
                  variant="text"
                  density="comfortable"
                  icon="domain"
                  color="secondary"
                  @click="showBuildings(item.id)"
                />
              </template>
            </v-tooltip>

            <v-dialog width="500">
              <template #activator="{ props }">
                <v-btn
                  v-bind="props"
                  size="small"
                  variant="text"
                  density="comfortable"
                  icon="delete"
                  color="error"
                />
              </template>

              <template #default="{ isActive }">
                <v-card>
                  <v-card-text> Are you sure you want to delete this project? </v-card-text>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn text="Cancel" color="secondary" @click="isActive.value = false" />
                    <v-btn
                      text="Delete"
                      color="error"
                      @click="onDeleteProject(item.id, () => (isActive.value = false))"
                    />
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-sheet>
</template>

<script>
import { router } from '@/router'
import { storeToRefs } from 'pinia'
import { useProjectStore } from '@/stores/project'
import { pMapClass } from '@/library/map'
import { useLicenceStore } from '@/stores/licence'
import { useMessenger } from '@/composables/messenger'

export default {
  name: 'ProjectList',

  setup() {
    pMapClass.getInstance().destroy()
    const { projects, loading } = storeToRefs(useProjectStore())
    const { loadProjects, loadProject, newProject, deleteProject } = useProjectStore()
    const { checkProjectLimit } = useLicenceStore()
    const { addMessage } = useMessenger()

    loadProjects()

    return {
      projects,
      loading,
      newProject,
      loadProject,
      deleteProject,
      checkProjectLimit,
      addMessage
    }
  },

  computed: {
    headers() {
      const headerProps = { class: 'font-weight-bold' }
      const headers = [
        { key: 'actions', title: '', sortable: false },
        { key: 'name', title: 'Name', headerProps },
        { key: 'location_address', title: 'Location address', headerProps },
        { key: 'budget', title: 'Budget', headerProps },
        { key: 'carbon_price', title: 'Carbon price', headerProps },
        { key: 'lastedit', title: 'Last updated', headerProps }
      ]

      if (this.projects.some((p) => p.architect))
        headers.splice(2, 0, { key: 'architect', title: 'Architect', headerProps })
      if (this.projects.some((p) => p.client))
        headers.splice(2, 0, { key: 'client', title: 'Client', headerProps })

      return headers
    },

    items() {
      const formatPrice = (d) => {
        return new Intl.NumberFormat('en-GB', {
          style: 'decimal',
          minimumFractionDigits: 2
        }).format(d)
      }

      const formatDate = (d) => {
        return new Date(d).toLocaleString()
      }

      return this.projects.map((p) => {
        return {
          id: p.id,
          name: p.name,
          client: p.client,
          architect: p.architect,
          location_address: p.location_address,
          budget: formatPrice(p.budget),
          lastedit: formatDate(p.lastedit),
          carbon_price: formatPrice(p.carbon_price),
          designs_count: p.designs_count
        }
      })
    }
  },

  methods: {
    async createProject() {
      const projectLimitReached = await this.checkProjectLimit(this.projects.length)
      if (projectLimitReached) {
        this.addMessage({
          text: 'Reached maximum number of projects for the current license',
          color: 'error',
          action: {
            text: 'View your licence',
            to: '/account'
          }
        })
        return
      }
      this.newProject()
      router.push({ name: 'project', params: { projectid: 'new' } })
    },

    async openProject(id) {
      router.push({ name: 'project', params: { projectid: id } })
    },

    async onDeleteProject(id, closeDialog) {
      closeDialog()
      await this.deleteProject(id)
    },

    async showBuildings(id) {
      await this.loadProject(id)
      router.push({ name: 'compare', params: { projectid: id } })
    }
  }
}
</script>
