<template>
  <v-expansion-panel expand-icon="keyboard_arrow_down" collapse-icon="keyboard_arrow_up">
    <template #title>
      <div class="w-100 d-flex align-center ga-3 me-3">
        <v-icon> home </v-icon>
        <span class="text-overline font-weight-bold"> Structure </span>
        <v-spacer />
        <div class="text-caption lh-sm d-flex flex-column ga-0 text-end">
          <span>LatralSys: {{ lateralSystem }}</span>
          <span>Avg. grid size: {{ avgGridSize }}</span>
        </div>
      </div>
    </template>

    <template #text>
      <v-row class="mt-2">
        <v-col>
          <v-select
            id="structural_system"
            v-model="selectedBuildingBlock.structural_system"
            label="Structure type"
            :items="structureTypes"
            :disabled="locked || user.isReadonly('blocks.structural_system')"
          />

          <div class="text-subtitle-2 text-grey-darken-2 d-flex align-center ga-2">
            <v-icon v-if="user.isReadonly('blocks.d1_grid_size')" icon="lock" size="small" />
            Avg. grid size - d1 ({{ config.gridSize.um }})
          </div>
          <v-slider
            v-model.number="selectedBuildingBlock.d1_grid_size"
            :min="3.5"
            :max="12"
            :step="config.gridSize.step"
            :disabled="locked || user.isReadonly('blocks.d1_grid_size')"
            color="primary"
          >
            <template #append>
              {{ config.gridSize.displayValue(selectedBuildingBlock.d1_grid_size) }}
            </template>
          </v-slider>

          <div class="text-subtitle-2 text-grey-darken-2 d-flex align-center ga-2">
            <v-icon v-if="user.isReadonly('blocks.d1_grid_size')" icon="lock" size="small" />
            Floor loading
          </div>
          <v-slider
            v-model.number="selectedBuildingBlock.variable_load"
            :min="structure.variableLoad.min"
            :max="structure.variableLoad.max"
            :step="config.floorLoading.step"
            :disabled="locked || user.isReadonly('blocks.variable_load')"
            color="primary"
            hide-details
          >
            <template #append>
              {{ config.floorLoading.displayValue(selectedBuildingBlock.variable_load) }}
            </template>
          </v-slider>
          <div class="text-subtitle-2 text-grey d-flex align-center justify-space-between mb-8">
            <span>
              {{ config.floorLoading.displayValue(structure.variableLoad.min) }}
              {{ config.floorLoading.um }}
            </span>

            <span>
              {{ config.floorLoading.displayValue(structure.variableLoad.max) }}
              {{ config.floorLoading.um }}
            </span>
          </div>

          <v-select
            v-if="selectedBuildingBlock.structural_system == 'REINF_CONCRETE'"
            v-model="selectedBuildingBlock.floorslabs"
            label="Floor slabs"
            :prepend-inner-icon="user.isReadonly('blocks.floorslabs') ? 'lock' : ''"
            :items="flootslabTypes"
            :disabled="locked || user.isReadonly('blocks.floorslabs')"
          />

          <v-select
            v-if="showBeamSection"
            v-model="selectedBuildingBlock.steel_beams"
            label="Beam section"
            :items="steelBeamTypes"
            :prepend-inner-icon="user.isReadonly('blocks.steel_beams') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('blocks.steel_beams')"
            hide-details
            class="mb-0"
          />
        </v-col>

        <v-col>
          <v-select
            v-model="selectedBuilding.lateral_system_name"
            label="Lateral System"
            :items="structure.lateralSystems"
            :disabled="locked || user.isReadonly('blocks.lateral_system_name')"
          />

          <div class="text-subtitle-2 text-grey-darken-2 d-flex align-center ga-2">
            <v-icon v-if="user.isReadonly('blocks.d2_grid_size')" icon="lock" size="small" />
            Avg. grid size - d2 ({{ config.gridSize.um }})
          </div>
          <v-slider
            v-model.number="selectedBuildingBlock.d2_grid_size"
            :min="3.5"
            :max="12"
            :step="config.gridSize.step"
            :disabled="locked || user.isReadonly('blocks.d2_grid_size')"
            color="primary"
            class="mb-0"
          >
            <template #append>
              {{ config.gridSize.displayValue(selectedBuildingBlock.d2_grid_size) }}
            </template>
          </v-slider>

          <div class="text-subtitle-2 text-grey-darken-2 d-flex align-center ga-2">
            <v-icon v-if="user.isReadonly('blocks.d1_grid_size')" icon="lock" size="small" />
            Structural sections
          </div>
          <v-slider
            v-model.number="selectedBuildingBlock.ratazion_factor"
            :min="structure.ratazionFactor.min"
            :max="structure.ratazionFactor.max"
            :step="structure.ratazionFactor.step"
            :disabled="locked || user.isReadonly('blocks.ratazion_factor')"
            color="primary"
            hide-details
          >
            <template #append>
              {{ selectedBuildingBlock.ratazion_factor }}
            </template>
          </v-slider>
          <div class="text-subtitle-2 text-grey d-flex align-center justify-space-between mb-8">
            <span>Fully optimized (1.0)</span>
            <span class="text-right">Fully rationalized (1.3)</span>
          </div>

          <div class="text-right">
            <v-btn
              v-if="!locked && selectedBuilding.isMultiblock"
              text="Apply to all blocks"
              color="secondary"
              class="align-self-end"
              @click="applyToAll"
            />
          </div>
        </v-col>
      </v-row>
    </template>
  </v-expansion-panel>
</template>

<script>
import { useLiveediting } from '@/composables/liveediting'
import { structure } from '@/library/appConfigs'
import User from '@/library/models/User'

export default {
  props: {
    locked: {
      type: Boolean,
      default: false
    },
    appConfigs: {
      type: Object,
      required: true
    },
    user: {
      type: User,
      required: true
    }
  },
  setup() {
    const { selectedBuilding, selectedBuildingBlock } = useLiveediting()

    return {
      selectedBuilding,
      selectedBuildingBlock,
      structure
    }
  },

  computed: {
    config() {
      return this.appConfigs.building
    },

    lateralSystem() {
      return this.selectedBuilding.lateral_system_name
        ? this.structure.lateralSystems.find(
            ({ value }) => value == this.selectedBuilding.lateral_system_name
          ).title
        : ''
    },

    structureTypes() {
      const options = this.user.getOptions('blocks.structural_system')
      return options.length ? this.user.limitItems(options, structure.types) : structure.types
    },

    flootslabTypes() {
      const options = this.user.getOptions('blocks.floorslabs')
      return options.length
        ? this.user.limitItems(options, structure.floorSlabs)
        : structure.floorSlabs
    },

    steelBeamTypes() {
      const options = this.user.getOptions('blocks.steel_beams')
      return options.length
        ? this.user.limitItems(options, structure.steelBeams)
        : structure.steelBeams
    },

    avgGridSize() {
      const d1 = this.config.gridSize.displayValue(this.selectedBuildingBlock.d1_grid_size)
      const d2 = this.config.gridSize.displayValue(this.selectedBuildingBlock.d2_grid_size)
      return `${d1} x ${d2}`
    },

    showBeamSection() {
      const { structural_system } = this.selectedBuildingBlock
      return structural_system == 'STEEL' || structural_system == 'HYBRID'
    }
  },

  methods: {
    applyToAll() {
      const selBlock = this.selectedBuildingBlock
      this.selectedBuilding.blocks.forEach((block) => {
        if (block.id != selBlock.id) {
          block.structural_system = selBlock.structural_system
          block.d1_grid_size = selBlock.d1_grid_size
          block.d2_grid_size = selBlock.d2_grid_size
          block.variable_load = selBlock.variable_load
          block.ratazion_factor = selBlock.ratazion_factor
          block.floorslabs = selBlock.floorslabs
          block.steel_beams = selBlock.steel_beams
        }
      })
    }
  }
}
</script>
