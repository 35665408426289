import * as d3 from 'd3'

export default class DonutChart {
  constructor({ data, scale = 1, donutWidth = 0.33 }) {
    this.data = data
    this.scale = scale
    this.height = 500 * scale
    this.width = this.height
    this.padding = 20 * scale
    this.radius = this.height / 2
    this.donutWidth = this.height * donutWidth
  }

  get totalValue() {
    return this.data.reduce((a, b) => a + (b.value || 0), 0)
  }

  get color() {
    return d3
      .scaleOrdinal()
      .domain(this.data.map((d) => d.name))
      .range(d3.quantize((t) => d3.interpolateRainbow(t * 0.8 + 0.1), this.data.length).reverse())
  }

  get arc() {
    return d3
      .arc()
      .innerRadius(this.radius - this.donutWidth)
      .outerRadius(this.radius)
  }

  get pie() {
    return d3
      .pie()
      .value((d) => d.value)
      .sort(null)
  }

  get legend() {
    return this.data.map(({ name }) => ({
      color: this.color(name),
      name
    }))
  }

  drawChart(elementId) {
    this.wrapper = d3.select(elementId)
    this.wrapper.html('')

    const svg = this.drawSVG()
    this.drawTooltip()
    const self = this

    svg
      .selectAll('path')
      .data(this.pie(this.data))
      .enter()
      .append('path')
      .attr('d', this.arc)
      .attr('fill', (d) => this.color(d.data.name))
      .attr('stroke', 'white')
      .attr('stroke-width', 0.5)
      .attr('transform', 'translate(0, 0)')
      .on('mouseover', (event, data) => this.onMouseOver(event, data, self))
      .on('mousemove', (event) => this.onMouseMove(event, self))
      .on('mouseout', (event) => this.onMouseOut(event, self))
  }

  drawSVG() {
    return this.wrapper
      .append('svg')
      .attr('width', this.width + this.padding * 2)
      .attr('height', this.height + this.padding * 2)
      .append('g')
      .attr(
        'transform',
        'translate(' +
          (this.width + this.padding * 2) / 2 +
          ',' +
          (this.height + this.padding * 2) / 2 +
          ')'
      )
  }

  onMouseOver({ target }, { data }, self) {
    d3.select(target).transition().duration(50).attr('filter', 'brightness(1.1)')
    const label = data.name
    const value = Intl.NumberFormat('en-US', { style: 'decimal', maximumFractionDigits: 0 }).format(
      data.value
    )
    const pct = Math.round((data.value / self.totalValue) * 100)

    self.tooltip.transition().duration(50).style('opacity', 1)
    self.tooltip.html(`<span style="font-weight: bold">${label}</span> <br /> ${value} (${pct}%)`)
  }

  onMouseMove(event, self) {
    const x = event.offsetX
    const y = event.offsetY
    const tooltipWidth = self.tooltip.node().getBoundingClientRect().width
    const tooltipHeight = self.tooltip.node().getBoundingClientRect().height

    if (x > self.width / 2 && y > self.height / 2) {
      self.tooltip.style('left', x - tooltipWidth + 'px').style('top', y - tooltipHeight + 'px')
    } else if (x < self.width / 2 && y > self.height / 2) {
      self.tooltip.style('left', x + 'px').style('top', y - tooltipHeight + 'px')
    } else if (x < self.width / 2 && y < self.height / 2) {
      self.tooltip.style('left', x + 'px').style('top', y + 'px')
    } else if (x > self.width / 2 && y < self.height / 2) {
      self.tooltip.style('left', x - tooltipWidth + 'px').style('top', y + 'px')
    }
  }

  onMouseOut(event, self) {
    d3.select(event.target).transition().duration(50).attr('filter', 'brightness(1)')
    self.tooltip.transition().duration(50).style('opacity', 0)
  }

  drawTooltip() {
    this.tooltip = this.wrapper.append('div').attr('class', 'tooltip-donut').style('opacity', 0)
  }
}
