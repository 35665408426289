export default class Assembly {
  id = null
  components = []
  name = null
  type = null
  shortcode = null
  is_retrofit = false

  constructor(data) {
    if (data) {
      this.id = data.id
      this.name = data.name
      this.type = data.type
      this.shortcode = data.shortcode
      this.components = data.components
      this.is_retrofit = data.is_retrofit
    }
  }

  get formattedType() {
    return assemblyTypes.find(({ value }) => value === this.type).title
  }
}

export const assemblyTypes = [
  { value: 'EXTWALL', title: 'External Wall' },
  { value: 'GLAZING', title: 'Glazing' },
  { value: 'ROOF', title: 'Roof' },
  { value: 'INTWALL', title: 'Internal Partition' },
  { value: 'FLOORFINISH', title: 'Floor Finish' },
  { value: 'CEILINGFINISH', title: 'Ceiling Finish' },
  { value: 'INTDOOR', title: 'Internal Door' },
  { value: 'EXTDOOR', title: 'External Door' }
]
