export class DesignConstraints {
  variable_load
  intwall_system
  intdoor_system
  extdoor_system
  ceiling_finish_system
  floor_finish_system

  constructor() {
    this.d1_grid_size = 12
    this.d2_grid_size = 12
    this.SkylightArea = 0.0
    this.structural_system = 'REINF_CONCRETE'
    this.facade_system = [{ system: 'TRADITIONAL', coverage_perc: 70 }]
    this.roof_system = 'EPDMROOF'
    this.floorslabs = 'RIBBED'
    this.amount_extdoors = 0
    this.steel_beams = 'SOLIDSEC'
    this.ratazion_factor = 1.15
    this.int_wall_to_floor_ratio = 0.2
    this.glazing_system = [{ system: 'DGU_ALU', coverage_perc: 30 }]
    this.skylight_system = 'DGU_ALU'
    this.is_retrofit = false
    this.retrofit_parameters = structuredClone(retrofitDefaultParams)
  }
}

export const retrofitDefaultParams = {
  columns_retention_perc: 0,
  beams_retention_perc: 0,
  floors_retention_perc: 0,
  columns_strengthening_perc: 100,
  beams_strengthening_perc: 100,
  floors_strengthening_perc: 100
}
