import { roundTo } from '@/library/utils'
import { DesignConstraints, retrofitDefaultParams } from './DesignConstraints'
import { BldgGeomDetails } from '@/library/models/BldgGeomDetails'

const useTypes = {
  OFFICE: 3.0,
  RESIDENTIAL: 2.5,
  EDUCATIONAL: 3.0,
  RETAIL: 4.0,
  HEALTHCARE: 3.0,
  MULTICARPARK: 2.5
}

export class BuildingBlock {
  _prj
  id
  parent
  label
  footprintType
  footprint_lnglat_coords
  length
  length_B
  width
  FSR
  num_storey
  interstorey_height = 2.8
  height
  GFA
  footprint_area
  SurfaceArea
  facade_SurfaceArea
  Volume
  SA_V_ratio
  roof_area = 0
  structural_system
  facade_system
  roof_system
  floor_finish_system
  ceiling_finish_system
  glazing_system
  skylight_system
  int_wall_to_floor_ratio
  intwall_system = 'CMU_GYPSUM'
  intdoor_system
  extdoor_system
  d1_grid_size
  d2_grid_size
  variable_load = 3
  ratazion_factor
  floorslabs
  steel_beams
  SkylightArea
  useType = 'RESIDENTIAL'
  amount_extdoors
  is_retrofit = false
  retrofit_parameters = retrofitDefaultParams
  beam_depth
  beam_width
  beam_reinf_ratio
  steel_beam_shape
  col_depth
  col_width
  col_reinf_ratio
  steel_col_shape
  floor_depth
  slab_reinf_ratio
  beam_instances
  beam_sec_instances
  col_instances

  constructor(prj, data = {}) {
    this._prj = prj
    this.type = 'RESIDENTIAL'
    Object.assign(this, data)
    this.variable_load = data.variable_load
    if (this.label == null) this.label = ''
    if (this.amount_extdoors === 0 && !this.parent) this.amount_extdoors = 4
  }

  get beamDimensions() {
    return `${this.beam_width} x ${this.beam_depth} mm`
  }

  get beamReinforcingRatio() {
    if (this.structural_system === 'REINF_CONCRETE') return this.beam_reinf_ratio
    if (this.structural_system === 'STEEL') return this.steel_beam_shape
    return null
  }

  get columnDimensions() {
    return `${this.col_width} x ${this.col_depth} mm`
  }

  get columnReinforcingRatio() {
    if (this.structural_system === 'REINF_CONCRETE') return this.col_reinf_ratio
    if (this.structural_system === 'STEEL') return this.steel_col_shape
    return null
  }

  get floorReinforcingRatio() {
    if (this.structural_system === 'REINF_CONCRETE') return this.slab_reinf_ratio
    return null
  }

  get type() {
    return this.useType
  }

  set type(value) {
    this.useType = value
    this.variable_load = useTypes[value]
  }

  get isRoot() {
    return !this.parent
  }

  get SkylightArea_perc() {
    return roundTo(this.SkylightArea * 100, 1)
  }

  set SkylightArea_perc(v) {
    this.SkylightArea = v / 100
  }

  get WWR_perc() {
    return (
      this.glazing_system.reduce((sum, { coverage_perc }) => sum + coverage_perc, 0) /
      this.glazing_system.length
    )
  }

  get geom_details() {
    const geom = new BldgGeomDetails()

    geom.footprintType = this.footprintType
    geom.footprint_lnglat_coords = this.footprint_lnglat_coords
    geom.length = this.length
    geom.width = this.width
    geom.length_B = this.length_B
    geom.FSR = this.FSR
    geom.GFA = this.GFA
    geom.num_storey = this.num_storey
    geom.interstorey_height = this.interstorey_height
    geom.height = this.height

    return geom
  }

  set geom_details(g) {
    this.footprint_lnglat_coords = g.footprint_lnglat_coords
    this.num_storey = g.num_storey
    this.interstorey_height = g.interstorey_height
    this.height = g.height
    this.footprintType = g.footprintType
    this.length = g.length
    this.width = g.width
    this.length_B = g.length_B
    this.FSR = g.FSR
    this.GFA = g.GFA
    this.footprint_area = g.footprint_area
    this.SurfaceArea = g.SurfaceArea
    this.facade_SurfaceArea = g.facade_SurfaceArea
    this.Volume = g.Volume
    this.SA_V_ratio = g.SA_V_ratio
  }

  get design_constraints() {
    const design_c = new DesignConstraints()

    design_c.d1_grid_size = this.d1_grid_size
    design_c.d2_grid_size = this.d2_grid_size
    design_c.variable_load = this.variable_load
    design_c.steel_beams = this.steel_beams
    design_c.ratazion_factor = this.ratazion_factor
    design_c.int_wall_to_floor_ratio = this.int_wall_to_floor_ratio
    design_c.intwall_system = this.intwall_system
    design_c.intdoor_system = this.intdoor_system
    design_c.extdoor_system = this.extdoor_system
    design_c.floorslabs = this.floorslabs
    design_c.amount_extdoors = this.amount_extdoors
    design_c.structural_system = this.structural_system
    design_c.facade_system = this.facade_system
    design_c.roof_system = this.roof_system
    design_c.glazing_system = this.glazing_system
    design_c.skylight_system = this.skylight_system
    design_c.floor_finish_system = this.floor_finish_system
    design_c.ceiling_finish_system = this.ceiling_finish_system
    design_c.SkylightArea = this.SkylightArea
    design_c.is_retrofit = this.is_retrofit
    design_c.retrofit_parameters =
      this.retrofit_parameters ?? structuredClone(retrofitDefaultParams)

    return design_c
  }

  set design_constraints(d) {
    this.d1_grid_size = d.d1_grid_size
    this.d2_grid_size = d.d2_grid_size
    this.variable_load = d.variable_load
    this.steel_beams = d.steel_beams
    this.ratazion_factor = d.ratazion_factor
    this.int_wall_to_floor_ratio = d.int_wall_to_floor_ratio
    this.intwall_system = d.intwall_system
    this.intdoor_system = d.intdoor_system
    this.extdoor_system = d.extdoor_system
    this.floorslabs = d.floorslabs
    this.amount_extdoors = d.amount_extdoors
    this.structural_system = d.structural_system
    this.facade_system = d.facade_system
    this.roof_system = d.roof_system
    this.glazing_system = d.glazing_system
    this.skylight_system = d.skylight_system
    this.floor_finish_system = d.floor_finish_system
    this.ceiling_finish_system = d.ceiling_finish_system
    this.SkylightArea = d.SkylightArea
    this.is_retrofit = d.is_retrofit
    this.retrofit_parameters = d.retrofit_parameters ?? structuredClone(retrofitDefaultParams)
  }

  updateCalcAttributes(d) {
    this.SA_V_ratio = d.SA_V_ratio
    this.SurfaceArea = d.SurfaceArea
    this.Volume = d.Volume
    this.height = d.height
    this.GFA = d.GFA
    this.facade_SurfaceArea = d.facade_SurfaceArea
    this.footprint_area = d.footprint_area
    return this
  }

  serializeToLCA() {
    return {
      id: this.id,
      parent: this.parent,
      label: this.label,
      type: this.type,
      geom_details: this.geom_details,
      design_constraints: this.design_constraints
    }
  }

  resetRetrofitParameters() {
    this.is_retrofit = false
    this.retrofit_parameters = structuredClone(retrofitDefaultParams)
  }
}
