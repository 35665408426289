import { createRouter, createWebHashHistory } from 'vue-router'
import ProjectPane from '@/components/panes/ProjectPane.vue'
import LiveeditingPane from '@/components/panes/LiveeditingPane.vue'
import ComparePane from '@/components/panes/ComparePane.vue'
import DataPane from '@/components/panes/DataPane.vue'
import DetailedResultsPane from '@/components/panes/DetailedResultsPane.vue'
import ProjectLeftSideBar from '@/views/project/ProjectLeftSideBar.vue'
import ProjectList from '@/views/project/ProjectList.vue'
import LoginLayout from '@/views/layouts/LoginLayout.vue'
import AppLayout from '@/views/layouts/AppLayout.vue'
import SignIn from '@/components/account/SignIn.vue'
import CreateAccount from '@/components/account/CreateAccount.vue'
import NewPassword from '@/components/account/NewPassword.vue'
import ConfirmAccount from '@/components/account/ConfirmAccount.vue'
import { pApiClient } from './library/api'
import { useConfig } from './composables/config'
import mixpanel from 'mixpanel-browser'

export const pROUTES = {
  ProjectList: 'prj-list',
  GenerativeDesign: 'gd',
  MaterialsList: 'material',
  AssemblyList: 'assembly',
  DatasetList: 'dataset'
}

export const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/login',
      component: LoginLayout,
      children: [{ path: '', component: SignIn, name: 'login' }]
    },
    {
      path: '/register',
      component: LoginLayout,
      children: [{ path: '', component: CreateAccount, name: 'register' }]
    },
    {
      path: '/new-password/:token',
      alias: ['/new-user/:token'],
      component: LoginLayout,
      children: [{ path: '', component: NewPassword, name: 'new-password' }]
    },
    {
      path: '/confirm/:token',
      component: LoginLayout,
      children: [{ path: '', component: ConfirmAccount, name: 'confirm' }]
    },
    {
      path: '/account',
      name: 'account',
      beforeEnter: isAuthenticated,
      component: () => import('@/views/account/UserSettings.vue')
    },
    {
      path: '/pricing/:step?',
      name: 'pricing',
      beforeEnter: isAuthenticated,
      component: () => import('@/views/account/PricingTable.vue')
    },
    {
      path: '/theme',
      name: 'theme',
      component: () => import('@/views/account/SwitchTheme.vue')
    },
    {
      path: '/bldg',
      component: AppLayout,
      beforeEnter: isAuthenticated,
      children: [
        {
          path: '/manage-users',
          name: 'manage-users',
          beforeEnter: isAuthenticated,
          component: () => import('@/views/account/ManageUsers.vue')
        },
        {
          path: '/',
          name: pROUTES.ProjectList,
          components: {
            default: ProjectList
          }
        },
        {
          path: '/assemblies',
          alias: '/assembly',
          redirect: '/?dialog=assemblies'
        },
        {
          path: '/materials',
          alias: '/material',
          redirect: '/?dialog=materials'
        },
        {
          path: '/datasets',
          alias: '/dataset',
          redirect: '/?dialog=datasets'
        },
        {
          path: '/project/:projectid',
          name: 'project',
          components: {
            default: ProjectPane,
            lsb: ProjectLeftSideBar
          }
        },
        {
          path: '/compare/:projectid',
          name: 'compare',
          components: {
            default: ComparePane,
            lsb: ProjectLeftSideBar
          }
        },
        {
          path: '/liveediting/:projectid',
          name: 'liveediting',
          components: {
            default: LiveeditingPane,
            lsb: ProjectLeftSideBar
          }
        },
        {
          path: '/data/:projectid',
          name: 'data',
          components: {
            default: DataPane,
            lsb: ProjectLeftSideBar
          }
        },
        {
          path: '/detailedresults/:projectid',
          name: 'detailedresults',
          components: {
            default: DetailedResultsPane,
            lsb: ProjectLeftSideBar
          }
        }
      ]
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/'
    }
  ]
})

async function isAuthenticated(to, _from, next) {
  try {
    await pApiClient.get('/ping', { suffix: 's/' })
    if (to.path == '/account') return next()

    const localuser = JSON.parse(localStorage.getItem('user'))
    if (localuser) {
      const { data: user } = await pApiClient.get('/user/' + localuser.id, {
        suffix: 's/'
      })

      mixpanel.identify(user.id)
      mixpanel.people.set_once({ $name: user.first_name, $email: user.email })
      mixpanel.people.set_once({ customer: user.customer.name })

      if (to.path == '/pricing') return next()
      if (!user.customer.license_ok) return next('/account')
    }
    await useConfig().getConfig()
    next()
  } catch (err) {
    next({ name: 'login', query: { redirect: to.path } })
  }
}

router.beforeEach(async (to) => {
  mixpanel.track(to.name, { path: to.path })
})
