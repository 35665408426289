<template>
  <v-expansion-panel
    expand-icon="keyboard_arrow_down"
    collapse-icon="keyboard_arrow_up"
    :color="hasFullCoverage && !hasDuplicateFacades ? '' : 'red-lighten-4'"
  >
    <template #title>
      <div class="w-100 d-flex align-center ga-3 me-3">
        <v-icon> house_siding </v-icon>
        <span class="text-overline font-weight-bold"> Facade </span>
        <v-spacer />
        <div class="text-caption lh-sm d-flex flex-column ga-0 text-end">
          <span>
            Wall:
            {{ totalCoverage - selectedBuildingBlock.WWR_perc }}%
          </span>
          <span>
            Glazing:
            {{ selectedBuildingBlock.WWR_perc }}%
          </span>
        </div>
      </div>
    </template>

    <template #text>
      <v-spacer class="mt-8" />

      <v-row v-for="(item, index) in selectedBuildingBlock.facade_system" :key="index">
        <v-col class="pt-0">
          <v-select
            v-model="item.system"
            label="Wall type"
            :items="facadeAssemblies"
            :prepend-inner-icon="user.isReadonly('blocks.facade_system') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('blocks.facade_system')"
            hide-details="auto"
            :error-messages="getErrors(index)"
          >
            <template v-if="index >= 1" #prepend>
              <v-icon density="compact" size="small" @click="removeFacadeSelection(index)">
                close
              </v-icon>
            </template>
          </v-select>
        </v-col>

        <v-col class="pt-0">
          <v-slider
            v-model.number="item.coverage_perc"
            :min="0"
            :max="100"
            :step="1"
            :disabled="locked || isFreemium"
            color="primary"
            class="ma-0"
            hide-details
          >
            <template #prepend>
              <span class="text-subtitle-2 text-grey"> Area (%) </span>
            </template>
            <template #append>
              <v-text-field
                v-model.number="item.coverage_perc"
                density="compact"
                style="width: 60px"
                type="number"
                max="100"
                min="0"
                variant="outlined"
                hide-details
                hide-spin-buttons
                @blur="item.coverage_perc = Math.min(Math.max(item.coverage_perc, 0), 100)"
              />
            </template>
          </v-slider>
        </v-col>
      </v-row>

      <v-row v-if="selectedBuildingBlock.facade_system.length < 3 && !isFreemium" class="my-0 py-0">
        <v-col class="py-0 mb-2">
          <v-btn
            text="Additional wall type"
            prepend-icon="add"
            color="primary"
            variant="text"
            density="compact"
            :disabled="locked || user.isReadonly('blocks.facade_system')"
            @click="addFacadeSelection"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col class="py-0">
          <v-select
            v-model="selectedBuildingBlock.glazing_system[0].system"
            label="Glazing type"
            :items="glazingAssemblies"
            :prepend-inner-icon="user.isReadonly('blocks.glazing_system') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('blocks.glazing_system')"
          />
        </v-col>
        <v-col class="py-0">
          <v-slider
            v-model.number="selectedBuildingBlock.glazing_system[0].coverage_perc"
            :min="config.glazedArea.MINMAX[0]"
            :max="config.glazedArea.MINMAX[1]"
            :step="1"
            :disabled="locked || isFreemium"
            color="primary"
            class="ma-0"
          >
            <template #prepend>
              <span class="text-subtitle-2 text-grey"> Area (%) </span>
            </template>
            <template #append>
              <v-text-field
                v-model.number="selectedBuildingBlock.glazing_system[0].coverage_perc"
                density="compact"
                style="width: 60px"
                type="number"
                max="90"
                min="0"
                variant="outlined"
                hide-details
                hide-spin-buttons
                @blur="
                  selectedBuildingBlock.glazing_system[0].coverage_perc = Math.min(
                    Math.max(selectedBuildingBlock.glazing_system[0].coverage_perc, 0),
                    90
                  )
                "
              />
            </template>
          </v-slider>
        </v-col>
      </v-row>

      <v-row class="py-0 my-0">
        <v-col class="d-flex align-start ga-8 py-0">
          <v-alert v-if="totalCoverage !== 100" color="error" variant="tonal" density="compact">
            Total area of all wall and glazing types must add up to 100%
          </v-alert>
          <v-spacer v-else />
          <span
            class="text-subtitle-1 me-2 font-weight-bold"
            :class="totalCoverage == 100 ? 'text-success' : 'text-error'"
          >
            Total area: {{ totalCoverage }}
          </span>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="pb-0">
          <v-select
            v-model="selectedBuildingBlock.extdoor_system"
            label="External door type"
            :items="doorAssemblies"
            :prepend-inner-icon="user.isReadonly('blocks.extdoor_system') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('blocks.extdoor_system')"
            no-data-text="No assemblies found"
            clearable
          />
        </v-col>
        <v-col class="pb-0">
          <v-text-field
            v-model.number="selectedBuildingBlock.amount_extdoors"
            type="number"
            label="External door count"
            :prepend-inner-icon="user.isReadonly('blocks.amount_extdoors') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('blocks.amount_extdoors')"
          />
        </v-col>
      </v-row>

      <v-row v-if="!locked && selectedBuilding.isMultiblock" class="mb-0">
        <v-col class="text-right">
          <v-btn text="Apply to all blocks" color="secondary" @click="applyToAll" />
        </v-col>
      </v-row>
    </template>
  </v-expansion-panel>
</template>

<script>
import { useLiveediting } from '@/composables/liveediting'
import User from '@/library/models/User'
import { useAssemblyStore } from '@/stores/assembly'
import { useLicenceStore } from '@/stores/licence'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

export default {
  props: {
    locked: {
      type: Boolean,
      default: false
    },
    user: {
      type: User,
      required: true
    },
    hasFullCoverage: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { assemblies } = storeToRefs(useAssemblyStore())
    const { filteredAssemblies } = useAssemblyStore()
    const { selectedBuildingBlock, selectedBlock, selectedBuilding, getConfig, bldgLE } =
      useLiveediting()
    const config = getConfig()
    const { licence } = storeToRefs(useLicenceStore())
    const isFreemium = computed(() => licence.value?.is_freemium)

    return {
      assemblies,
      filteredAssemblies,
      selectedBuildingBlock,
      selectedBlock,
      selectedBuilding,
      config,
      bldgLE,
      isFreemium
    }
  },

  computed: {
    formattedAssemblies() {
      const assemblies = this.filteredAssemblies(this.selectedBuildingBlock.is_retrofit)
      return assemblies.map(({ shortcode, name, type }) => {
        return {
          value: shortcode,
          title: name,
          type
        }
      })
    },

    facadeAssemblies() {
      const assemblies = this.formattedAssemblies.filter(({ type }) => type === 'EXTWALL')
      const options = this.user.getOptions('blocks.facade_system')
      return options.length ? this.user.limitItems(options, assemblies) : assemblies
    },

    glazingAssemblies() {
      const assemblies = this.formattedAssemblies.filter(({ type }) => type === 'GLAZING')
      const options = this.user.getOptions('blocks.glazing_system')
      return options.length ? this.user.limitItems(options, assemblies) : assemblies
    },

    doorAssemblies() {
      const assemblies = this.formattedAssemblies.filter(({ type }) => type === 'EXTDOOR')
      const options = this.user.getOptions('blocks.extdoor_system')
      return options.length ? this.user.limitItems(options, assemblies) : assemblies
    },

    totalCoverage() {
      if (!this.selectedBuildingBlock.facade_system) return 0

      const assemblies = [
        ...this.selectedBuildingBlock.facade_system,
        ...this.selectedBuildingBlock.glazing_system
      ]
      return assemblies.reduce((sum, { coverage_perc }) => sum + coverage_perc, 0)
    },

    hasDuplicateFacades() {
      if (!this.selectedBuildingBlock.facade_system) return false
      const unique = new Set(
        this.selectedBuildingBlock.facade_system.map((facade) => facade.system)
      )
      return unique.size !== this.selectedBuildingBlock.facade_system.length
    }
  },

  methods: {
    getErrors(index) {
      const otherFacades = this.selectedBuildingBlock.facade_system.filter((_, i) => i != index)

      return otherFacades.some(
        (facade) => facade.system === this.selectedBuildingBlock.facade_system[index].system
      )
        ? ['Facades must be unique']
        : []
    },

    addFacadeSelection() {
      this.selectedBuildingBlock.facade_system.push({
        system: 'TRADITIONAL',
        coverage_perc: 0
      })
    },

    removeFacadeSelection(index) {
      this.selectedBuildingBlock.facade_system.splice(index, 1)
    },

    applyToAll() {
      const selBlock = this.selectedBuildingBlock
      const color = this.selectedBlock.color

      this.selectedBuilding.blocks.forEach((block) => {
        if (block.id != selBlock.id) {
          block.facade_system = selBlock.facade_system
          block.glazing_system = selBlock.glazing_system
          block.extdoor_system = selBlock.extdoor_system
          block.amount_extdoors = selBlock.amount_extdoors
          let mapblock = this.bldgLE.blocks.find(({ id }) => id == block.id)
          mapblock.color = color
          this.bldgLE.changeBlockColor(mapblock, color)
        }
      })
    }
  }
}
</script>
