<template>
  <v-expansion-panel expand-icon="keyboard_arrow_down" collapse-icon="keyboard_arrow_up">
    <template #title>
      <div class="w-100 d-flex align-center ga-3 me-3">
        <v-icon> roofing </v-icon>
        <span class="text-overline font-weight-bold">Roof</span>
        <v-spacer />
        <div class="text-caption lh-sm d-flex flex-column ga-0 text-end">
          <span> {{ selectedRoofAssembly?.name || 'None' }} </span>
          <span> {{ selectedSkylightAssembly?.name || 'None' }} </span>
        </div>
      </div>
    </template>

    <template #text>
      <v-row class="mt-2">
        <v-col>
          <v-select
            id="roof_system"
            v-model="selectedBuildingBlock.roof_system"
            label="Roof type"
            :items="roofAssemblies"
            :prepend-inner-icon="user.isReadonly('blocks.roof_system') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('blocks.roof_system')"
          />
        </v-col>
        <v-col>
          <v-select
            id="skylight_system"
            v-model="selectedBuildingBlock.skylight_system"
            label="Skylight type"
            :items="glazingAssemblies"
            :prepend-inner-icon="user.isReadonly('blocks.skylight_system') ? 'lock' : ''"
            :disabled="locked || user.isReadonly('blocks.skylight_system')"
          />

          <div class="text-subtitle-2 text-grey-darken-2 d-flex align-center ga-2">
            <v-icon v-if="user.isReadonly('blocks.SkylightArea')" icon="lock" size="small" />
            Skylight area (%)
          </div>
          <v-slider
            v-model.number="selectedBuildingBlock.SkylightArea_perc"
            :min="0"
            :max="10"
            :step="0.5"
            :disabled="locked || user.isReadonly('blocks.SkylightArea')"
            color="primary"
            hide-details
          >
            <template #append>
              {{ selectedBuildingBlock.SkylightArea_perc }}
            </template>
          </v-slider>
        </v-col>
      </v-row>
      <v-row v-if="!locked && selectedBuilding.isMultiblock">
        <v-col class="text-end pb-4">
          <v-btn
            text="Apply to all blocks"
            color="secondary"
            class="align-self-end"
            @click="applyToAll"
          />
        </v-col>
      </v-row>
    </template>
  </v-expansion-panel>
</template>

<script>
import { useLiveediting } from '@/composables/liveediting'
import User from '@/library/models/User'
import { useAssemblyStore } from '@/stores/assembly'
import { storeToRefs } from 'pinia'

export default {
  props: {
    locked: {
      type: Boolean,
      default: false
    },
    user: {
      type: User,
      required: true
    }
  },
  setup() {
    const { assemblies } = storeToRefs(useAssemblyStore())
    const { filteredAssemblies } = useAssemblyStore()
    const { selectedBuilding, selectedBuildingBlock, getConfig } = useLiveediting()
    const config = getConfig()

    return {
      selectedBuilding,
      selectedBuildingBlock,
      config,
      assemblies,
      filteredAssemblies
    }
  },

  computed: {
    formattedAssemblies() {
      const assemblies = this.filteredAssemblies(this.selectedBuildingBlock.is_retrofit)
      return assemblies.map(({ shortcode, name, type }) => {
        return {
          value: shortcode,
          title: name,
          type
        }
      })
    },

    roofAssemblies() {
      const assemblies = this.formattedAssemblies.filter(({ type }) => type === 'ROOF')
      const options = this.user.getOptions('blocks.roof_system')
      return options.length ? this.user.limitItems(options, assemblies) : assemblies
    },

    glazingAssemblies() {
      const assemblies = this.formattedAssemblies.filter(({ type }) => type === 'GLAZING')
      const options = this.user.getOptions('blocks.skylight_system')
      return options.length ? this.user.limitItems(options, assemblies) : assemblies
    },

    selectedRoofAssembly() {
      return this.assemblies.find(
        ({ shortcode }) => shortcode == this.selectedBuildingBlock.roof_system
      )
    },

    selectedSkylightAssembly() {
      return this.assemblies.find(
        ({ shortcode }) => shortcode == this.selectedBuildingBlock.skylight_system
      )
    }
  },

  methods: {
    applyToAll() {
      let selBlock = this.selectedBuildingBlock

      this.selectedBuilding.blocks.forEach((block) => {
        if (block.id != selBlock.id) {
          block.roof_system = selBlock.roof_system
          block.SkylightArea = selBlock.SkylightArea
          block.skylight_system = selBlock.skylight_system
        }
      })
    }
  }
}
</script>
